
import CIcon from '@coreui/icons-react';
import DOMPurify from 'dompurify';
import { CButton } from '@coreui/react';
import PropTypes from 'prop-types';
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { escapeRegExp, useOutsideHandling } from '../../../../utils';
import { SECTION_OPTION_SEGMENT } from '../../../../constants/segment';
import { CInput } from '../../../migration/CInput';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from "@dnd-kit/utilities";
import CTooltip from '../../../migration/CTooltip';
const Dropdown = ({ children, isOpen, target, onClose, fieldFull }) => (
     <div className="select-wrapper">
          {target}
          {fieldFull ? isOpen ? <Menu1>{children}</Menu1> : null : isOpen ? <Menu>{children}</Menu> : null}
          {isOpen ? <Blanket onClick={onClose} /> : null}
     </div>
);

const Menu1 = (props) => {
     return (
          <div
               style={{
                    // backgroundColor: 'white',
                    borderRadius: 4,
                    marginTop: 8,
                    position: 'absolute',
                    zIndex: 10,
                    cursor: 'default',
               }}
               {...props}
          />
     );
};
const Menu = (props) => {
     return (
          <div
               style={{
                    // backgroundColor: 'white',
                    borderRadius: 4,
                    marginTop: 8,
                    position: 'absolute',
                    zIndex: 10,
                    width: '100%',
                    cursor: 'default',
               }}
               {...props}
          />
     );
};

const Blanket = (props) => (
     <div
          style={{
               bottom: 0,
               left: 0,
               top: 0,
               right: 0,
               position: 'absolute',
               zIndex: 2,
          }}
          {...props}
     />
);

const ChildAccordion = (props) => {
     const { openTypeVariable, handleOpenTypeVariable, variableOptionsFound, section, childSection, handleClick, variableFilter, valueField } = props
     return (
          <>
               <li
                    className="d-flex align-items-center justify-content-between border-bottom border-top type-variable child"
                    onClick={() => handleOpenTypeVariable(section)}
               >
                    <strong className="mr-1">{childSection}</strong>
                    {openTypeVariable[section] ? (
                         <strong style={{ pointerEvents: 'none' }}>
                              {variableOptionsFound.length}
                         </strong>
                    ) : (
                         <CIcon
                              style={{ pointerEvents: 'none' }}
                              icon="iconArrowDownStrong"
                         />
                    )}
               </li>
               {openTypeVariable[section] && (

                    variableOptionsFound.length > 0 ? (
                         <>
                              {variableOptionsFound.map((option, i) => {
                                   const activeValue = valueField === option.label;
                                   const re = new RegExp(escapeRegExp(variableFilter), 'gi');
                                   let newVariable = option.label;
                                   if (variableFilter) {
                                        newVariable = newVariable.replace(re, (variable) => `<strong>${variable}</strong>`);
                                   }
                                   const sanitizedNewVariable = DOMPurify.sanitize(`${newVariable}`);
                                   return (
                                        <Fragment key={i}>
                                             <li
                                                  className={`border-bottom d-flex align-items-center ${activeValue
                                                       ? 'active-variable'
                                                       : ''
                                                       } ${option.isCustom
                                                            ? 'custom'
                                                            : ''
                                                       }`}
                                                  key={option.label}
                                                  onClick={() =>
                                                       handleClick(option)
                                                  }
                                             >
                                                  <span
                                                       className="text-break"
                                                       dangerouslySetInnerHTML={{
                                                            __html: sanitizedNewVariable,
                                                       }}
                                                       style={{ paddingLeft: '13px' }}
                                                  ></span>
                                             </li>
                                        </Fragment>
                                   );
                              })}
                         </>
                    ) : (
                         <>
                              {/* <li className="no-options border-bottom d-flex align-items-center">
                                   No options found.
                              </li> */}
                         </>
                    )
               )}

          </>
     );
}

function SortableItem({ index, item, onRemove, isDragging }) {
     const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: item.value });

     const style =
          transform ? {
               transform: CSS.Translate.toString(transform),
               transition: transition,
               pointerEvents: 'none',
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'space-between',
               padding: '0px',
          } : {
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'space-between',
               padding: '0px',
          };

     return (
          <Fragment key={index}>
               {item && item.value && (
                    <li ref={setNodeRef} style={style} {...attributes}>
                         <span {...listeners}
                              style={{
                                   padding: '3.5px 21px 3.5px 6px',
                              }}
                         >
                              {item.description ? (
                                   <CTooltip
                                        content={item.description}
                                   >
                                        <span>{item.label}</span>
                                   </CTooltip>
                              ) : (
                                   <>{item.label}</>
                              )}

                         </span> {/* Attach drag listeners only to the label */}
                         <CButton
                              onClick={(e) => {
                                   e.stopPropagation(); // Prevent the click event from bubbling
                                   onRemove(index); // Call the onRemove handler
                              }}
                              onMouseDown={(e) => e.stopPropagation()} // Prevent the button from starting a drag

                              className="icon-remove-multi"
                         >
                              <CIcon
                                   icon="iconRemoveMultiSelect"
                                   width={10}
                                   height={10}
                              />
                         </CButton>
                    </li>
               )}
          </Fragment>
     );
}
const SelectOption = (props) => {
     const {
          id,
          isBlur,
          placeholder,
          options,
          onChange,
          value,
          isDisabled,
          className,
          classNameWrapper,
          selectInputType,
          displayPlaceholder,
          fieldFull,
          isMulti,
          error,
          dataVariable = true,
          setShowPopupCreateVariables,
          setStorageType,
          setVariableKeyId,
          valueFrom,
          groupValue,
          isDragging
     } = props;

     const wrapperRef = useRef(null);
     const filterInputRef = useRef(null);
     const valueInputRef = useRef(null);
     const [isOpen, setIsOpen] = useState(false);
     const [initValue, setInitValue] = useState(value);
     let inputValue;

     const listSection = Array.from(new Set(options.map(item => {
          if (item.sectionParent) {
               return item.sectionParent
          } else if (item?.section) {
               return item.section
          } else {
               return 'Select All'
          }
     }))) || []

     const allSection = Array.from(new Set(options.flatMap(item => {
          return [item.section, item.sectionParent]
     }))) || [];

     listSection.sort((a, b) => {
          if (a === SECTION_OPTION_SEGMENT.CUSTOM_PROPERTIES && b !== SECTION_OPTION_SEGMENT.CUSTOM_PROPERTIES) {
               return 1;
          }
          if (b === SECTION_OPTION_SEGMENT.CUSTOM_PROPERTIES && a !== SECTION_OPTION_SEGMENT.CUSTOM_PROPERTIES) {
               return -1;
          }

          return 0;
     });

     if (isMulti) {
          inputValue = initValue && initValue.label && !displayPlaceholder ? initValue.label : placeholder;
     } else {
          inputValue = initValue && initValue.label && !displayPlaceholder ? initValue.label : placeholder;
     }
     const [variableFilter, setVariableFilter] = useState(''); // Current text in the insert data layer variable box filter
     let variableFilterLowerCase = variableFilter.trim().toLowerCase();
     let variableOptions = options.filter((option) => option.label !== '' && option.label.toLowerCase().includes(variableFilterLowerCase));
     const [openTypeVariable, setOpenTypeVariable] = useState({});
     const [showScroll, setShowScroll] = useState(false);
     // Automatic variable options which are displayed in insert data layer variable box

     useEffect(() => {
          setVariableFilter('')
     }, [groupValue]) // eslint-disable-line

     const valueField = value && value.label ? value.label : '';
     useEffect(() => {
          if (wrapperRef.current) {
               if (wrapperRef.current.scrollHeight > wrapperRef.current.clientHeight) {
                    setShowScroll(true);
               } else {
                    setShowScroll(false);
               }
          }
     }, [openTypeVariable]);
     useEffect(() => {
          if (value) {
               setInitValue(value);
          }
     }, [value]);

     // const clickOutside = useCallback(() => {
     //      setIsOpen(false);
     //      setOpenTypeVariable({});
     //      setShowScroll(false);
     //      if (dataVariable) {
     //           setVariableOptions([]);
     //      }
     //      setVariableFilter('');
     // }, [dataVariable]);

     const clickOutside = useCallback(() => {
          setIsOpen(false);
          setOpenTypeVariable({});
          setShowScroll(false);
     }, []);

     useOutsideHandling(wrapperRef, clickOutside, 'mouseup');

     useEffect(() => {
          if (!isMulti) {
               if (isOpen && filterInputRef.current) {
                    setTimeout(() => {
                         filterInputRef.current.focus();
                    }, 0);
               }
               if (!isOpen && valueInputRef.current) valueInputRef.current.focus();
          }
     }, [isMulti, isOpen]);

     const toggleOpen = (e) => {
          const disableInput = e ? !e.target.classList.contains('disable-select') : true;
          if (disableInput)
               setTimeout(() => {
                    setIsOpen(!isOpen);
                    if (valueInputRef.current) valueInputRef.current.readOnly = false;
               }, 1);
          if (!dataVariable) {
               // eslint-disable-next-line
               setOpenTypeVariable((prevState) => ({ ...prevState, ['Data Layer Variable']: !prevState['Data Layer Variable'] }));
          }
     };

     const onSelectChange = (e) => {
          if (isMulti) {
               let newValue = [];
               if (value && value.length > 0) {
                    newValue = [...value, e];
               } else {
                    newValue = [e];
               }
               const { onChange, hasChange } = props;

               onChange(newValue);
               if (hasChange) {
                    hasChange();
               }
               // if (toggleMulti) {
               //      toggleOpen();
               // }
          } else {
               valueInputRef.current.focus();
               // toggleOpen();
               onChange(e);
          }
     };
     const handleOpenTypeVariable = (id) => {
          if (id === 'Select All') {
               onChange(options);
          } else {
               setOpenTypeVariable((prevState) => ({ ...prevState, [id]: !prevState[id] }));
          }
     };
     const handleSearch = (e) => {
          setVariableFilter(e);
          let searchObject = {};

          allSection.forEach((item) => {
               searchObject = { ...searchObject, [item]: true };
          });

          if (Object.keys(searchObject).length > 0) {
               setOpenTypeVariable(searchObject);
          }
     };

     const handleRemoveVariable = (i) => {
          const newValue = [...value];
          newValue.splice(i, 1);
          const removeItem = value?.length > 0 ? value[i] : {};
          const { onChange, hasChange } = props;
          onChange(newValue, removeItem);
          if (hasChange) {
               hasChange();
          }
     };
     const handleCheckShowData = (val, option) => {
          const checkVal = val.find((item) => {
               if (item && item.label && item.label === option.label) {
                    return true;
               }
               return false;
          });
          return checkVal;
     };
     const handleCheckLength = (val, variableOptions) => {
          let length = 0;
          variableOptions.forEach((el) => {
               const checkVal = val.find((item) => {
                    if (item && item.label && item.label === el.label) {
                         return true;
                    }
                    return false;
               });
               if (!checkVal) {
                    length = length + 1;
               }
          });
          return length;
     };
     const handleClick = (option) => {
          if (option.isCustom) {
               setShowPopupCreateVariables(true);
               setStorageType(valueFrom);
               setVariableKeyId(id);
          } else {
               onSelectChange(option);
          }
     };

     const noOptionsFound = listSection.every((section) => {
          const variableOptionsFound = variableOptions.filter((item) => item.section === section);
          const parentOption = variableOptions.filter((item) => item.sectionParent === section);
          const childSection = Array.from(new Set(parentOption.map(item => item.section))) || [];

          return variableFilter && variableOptionsFound.length === 0 && childSection.length === 0;
     });

     return (
          <div
               className={`select-insert-variable ${classNameWrapper} ${isBlur ? ' select-react-customize blur' : ' select-react-customize'}${!value || !value.value ? ' not-selected' : ''
                    }`}
               ref={wrapperRef}
          >
               <Dropdown
                    fieldFull={fieldFull}
                    className={className}
                    isOpen={isOpen}
                    onClose={toggleOpen}
                    target={
                         selectInputType ? (
                              <CInput
                                   onClick={() => toggleOpen()}
                                   readOnly
                                   value={inputValue}
                                   disabled={isDisabled}
                                   className={`${isDisabled ? 'disable-select' : ''}`}
                              />
                         ) : (
                              <>
                                   {isMulti ? (
                                        <>
                                             <div
                                                  className={`stretch-container select-multiple-variable-field form-control ${isDisabled ? 'disabled' : ''
                                                       } ${isDisabled ? 'disable-select' : ''} `}
                                                  onClick={toggleOpen}
                                             >
                                                  {/* {value && value.length > 0 ? (
                                                       <ul>
                                                            {value.map((v, i) => {
                                                                 return (
                                                                      <Fragment key={i}>
                                                                           {v && v.value && (
                                                                                <li>
                                                                                     {v.label}{' '}
                                                                                     <CButton
                                                                                          onClick={() => handleRemoveVariable(i)}
                                                                                          className="icon-remove-multi"
                                                                                     >
                                                                                          <CIcon
                                                                                               icon="iconRemoveMultiSelect"
                                                                                               width={10}
                                                                                               height={10}
                                                                                          />
                                                                                     </CButton>{' '}
                                                                                </li>
                                                                           )}
                                                                      </Fragment>
                                                                 );
                                                            })}
                                                       </ul>
                                                  ) : (
                                                       <div className="place-holder-select">{placeholder}</div>
                                                  )} */}
                                                  {value && value.length > 0 ? (
                                                       <ul>
                                                            {value.map((item, index) => (
                                                                 <SortableItem index={index} key={item.value} item={item} onRemove={handleRemoveVariable} isDragging={isDragging} />
                                                            ))}
                                                       </ul>
                                                  ) : (
                                                       <div className="place-holder-select">{'Select items'}</div>
                                                  )}
                                             </div>
                                        </>
                                   ) : (
                                        <input
                                             type="text"
                                             className={`stretch-container form-control ${isDisabled ? 'disable-select' : ''} `}
                                             onClick={toggleOpen}
                                             ref={valueInputRef}
                                             value={inputValue ? inputValue.replace('<br>', '') : inputValue}
                                             readOnly
                                        ></input>
                                   )}
                              </>
                         )
                    }
               >
                    <div className={`insert-variable-dropdown`}>
                         <ul
                              ref={wrapperRef}
                              className={`${options.length === 0 ? 'value-no-options-available no-options' : ''} ${showScroll ? 'have-scroll w-100' : ''}`}
                         >
                              <li className="variable-filter">
                                   <CInput
                                        innerRef={filterInputRef}
                                        type="text"
                                        value={variableFilter}
                                        onChange={(e) => handleSearch(e.target.value)}
                                        placeholder="Select, or type to search"
                                   />
                              </li>
                              {noOptionsFound ? (
                                   <li className="no-options border-bottom d-flex align-items-center justify-content-center text-center">No options</li>
                              ) : (
                                   listSection && listSection.map((section, i) => {
                                        const variableOptionsFound = variableOptions.filter((item) => item.section === section);
                                        const parentOption = variableOptions.filter((item) => item.sectionParent === section);
                                        const childSection = Array.from(new Set(parentOption.map(item => item.section))) || [];
                                        if (variableFilter && variableOptionsFound.length === 0 && childSection.length === 0) return null;
                                        return (
                                             <Fragment key={i}>
                                                  <li
                                                       className="d-flex align-items-center justify-content-between border-bottom border-top type-variable"
                                                       onClick={() => handleOpenTypeVariable(section)}

                                                  >
                                                       <strong className="mr-1">{section}</strong>
                                                       {openTypeVariable[section] ? (
                                                            <strong style={{ pointerEvents: 'none' }}>
                                                                 {isMulti ? (
                                                                      childSection.length > 0 ? (
                                                                           <>{childSection.length}</>
                                                                      ) : (
                                                                           <>{handleCheckLength(value, variableOptionsFound)}</>
                                                                      )
                                                                 ) : (
                                                                      childSection.length > 0 ? (
                                                                           <>{variableOptionsFound.length + childSection.length}</>
                                                                      ) : (
                                                                           <>{variableOptionsFound.length || childSection.length}</>
                                                                      )
                                                                 )}
                                                            </strong>
                                                       ) : (
                                                            section !== 'Select All' ?
                                                                 <CIcon style={{ pointerEvents: 'none' }} icon="iconArrowDownStrong" /> : ``
                                                       )}
                                                  </li>
                                                  {openTypeVariable[section] && (
                                                       <>
                                                            {childSection.length > 0 && (
                                                                 <>
                                                                      {childSection.map((childSection) => {
                                                                           const childVariableOptionsFound = variableOptions.filter((item) => item.section === childSection && item.sectionParent === section).filter((item) => !initValue.some((el) => el.value === item.value))
                                                                           const props = {
                                                                                openTypeVariable, handleOpenTypeVariable,
                                                                                variableOptionsFound: childVariableOptionsFound,
                                                                                section: `${section}_${childSection}`,
                                                                                childSection: childSection,
                                                                                valueField,
                                                                                variableFilter,
                                                                                handleClick
                                                                           }
                                                                           return <ChildAccordion {...props} />
                                                                      })}
                                                                 </>
                                                            )}
                                                            {variableOptionsFound.length > 0 && (
                                                                 <>
                                                                      {variableOptionsFound.map((option, i) => {
                                                                           const activeValue = valueField === option.label;
                                                                           const re = new RegExp(escapeRegExp(variableFilter), 'gi');
                                                                           let newVariable = option.label;
                                                                           if (variableFilter) {
                                                                                newVariable = newVariable.replace(re, (variable) => `<strong>${variable}</strong>`);
                                                                           }
                                                                           let checkShowValue = false;
                                                                           if (isMulti) {
                                                                                checkShowValue = handleCheckShowData(value, option);
                                                                           }
                                                                           const sanitizedNewVariable = DOMPurify.sanitize(`${newVariable}`);
                                                                           return (
                                                                                <Fragment key={i}>
                                                                                     {!checkShowValue ? (
                                                                                          <li
                                                                                               className={`border-bottom d-flex align-items-center ${activeValue ? 'active-variable' : ''
                                                                                                    } ${option.isCustom ? 'custom' : ''}`}
                                                                                               key={option.label}
                                                                                               onClick={() => handleClick(option)}
                                                                                          >
                                                                                               <span
                                                                                                    className="text-break"
                                                                                                    dangerouslySetInnerHTML={{
                                                                                                         __html: sanitizedNewVariable,
                                                                                                    }}
                                                                                               ></span>
                                                                                          </li>
                                                                                     ) : (
                                                                                          ''
                                                                                     )}
                                                                                </Fragment>
                                                                           );
                                                                      })}
                                                                 </>
                                                            )}

                                                            {/* {variableOptionsFound.length === 0 && childSection.length === 0 &&  (
                                                                 <li className="no-options border-bottom d-flex align-items-center">
                                                                      No options found.
                                                                 </li>
                                                            )} */}
                                                       </>
                                                  )}
                                             </Fragment>
                                        );
                                   })
                              )}
                         </ul>
                    </div>
                    {/* <ul>
                         <li className='d-flex align-items-center justify-content-between border-bottom type-variable' onClick={() => handleOpenTypeVariable('Data Layer Variable')}>
                              <strong>
                                   Data Layer Variable
                              </strong>
                              {openTypeVariable['Data Layer Variable'] ?
                                   <strong>
                                        {options.length}
                                   </strong>
                                   :
                                   <CIcon name='iconArrowDownStrong' />
                              }

                         </li>
                    </ul> */}
                    {/* <Select
                         id={id}
                         autoFocus
                         backspaceRemovesValue={false}
                         components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                         controlShouldRenderValue={false}
                         hideSelectedOptions={false}
                         isClearable={false}
                         menuIsOpen
                         onChange={(e) => onSelectChange(e)}
                         options={options}
                         filterOption={finalFilterOption}
                         placeholder="Type here to filter the list…"
                         styles={styles}
                         tabSelectsValue={false}
                         value={initValue}
                         className={className}
                         classNamePrefix={classNamePrefix}
                         menuShouldScrollIntoView
                    /> */}
               </Dropdown>
               {error && typeof error === 'string' ? <p style={{ color: '#e55353', fontSize: '80%' }}>{error}</p> : null}
          </div>
     );
};
SelectOption.propTypes = {
     id: PropTypes.string,
     isBlur: PropTypes.bool,
     placeholder: PropTypes.string,
     options: PropTypes.array,
     onChange: PropTypes.func,
     // value: PropTypes.object,
     isDisabled: PropTypes.bool,
     className: PropTypes.string,
     classNamePrefix: PropTypes.string,
     classNameWrapper: PropTypes.string,
     selectInputType: PropTypes.bool,
     displayPlaceholder: PropTypes.bool,
     filterOption: PropTypes.func,
     isMulti: PropTypes.bool,
};

export default SelectOption;
