import { SUBSCRIBER_PATH, SUBSCRIBER_MY_ACCOUNT_PATH, NEW_NAVIGATION_PATH } from '../../constants';

const defaultSubscriberNav = [
     {
          _tag: 'CSidebarNavItem',
          name: 'Dashboard',
          to: '/',
          icon: 'cil-speedometer',
          badge: {
               color: 'info',
               // text: 'NEW',
          },
     },
     {
          _tag: 'CSidebarNavTitle',
          _children: ['INTEGRATIONS'],
     },
     {
          _tag: 'CSidebarNavItem',
          name: 'Website',
          to: SUBSCRIBER_PATH.WEBSITE,
          icon: 'cil-code',
     },
     {
          _tag: 'CSidebarNavItem',
          name: 'Listeners',
          to: SUBSCRIBER_PATH.LISTENERS,
          icon: 'cil-layers',
     },
     // {
     //      _tag: 'CSidebarNavItem',
     //      name: 'Tag Managers',
     //      to: SUBSCRIBER_PATH.TAG_MANAGERS,
     //      icon: 'cil-tags'
     // },
     // {
     //      _tag: 'CSidebarNavItem',
     //      name: 'Browser Add-ons',
     //      to: SUBSCRIBER_PATH.BROWSER_ADDONS,
     //      icon: 'cil-browser'
     // },
     {
          _tag: 'CSidebarNavTitle',
          _children: ['GLOBAL SETTINGS'],
     },
     {
          _tag: 'CSidebarNavItem',
          name: 'Custom Data Layer Variables',
          to: SUBSCRIBER_PATH.DATA_LAYER_VARIABLES,
          icon: 'cil-puzzle',
     },
     {
          _tag: 'CSidebarNavItem',
          name: 'Browser Storage Variables',
          to: SUBSCRIBER_PATH.BROWSER_STORAGE_VARIABLES,
          icon: 'cil-puzzle',
     },
];

const subscriberMyAccountNav = [
     {
          _tag: 'CSidebarNavTitle',
          _children: ['ACCOUNTS'],
     },
     {
          _tag: 'CSidebarNavItem',
          name: 'My Accounts',
          to: SUBSCRIBER_MY_ACCOUNT_PATH.MY_ACCOUNTS,
          icon: 'cil-user',
     },
     {
          _tag: 'CSidebarNavTitle',
          _children: ['LEARNING'],
     },
     {
          _tag: 'CSidebarNavItem',
          name: 'Masterclass',
          to: SUBSCRIBER_MY_ACCOUNT_PATH.MASTERCLASS,
          icon: 'cil-user',
     },
     {
          _tag: 'CSidebarNavItem',
          name: 'Certification',
          to: SUBSCRIBER_MY_ACCOUNT_PATH.CERTIFICATION,
          icon: 'cil-bell',
     },
     {
          _tag: 'CSidebarNavItem',
          name: 'Office Hours',
          className: 'new',
          to: SUBSCRIBER_MY_ACCOUNT_PATH.OFFICE_HOURS,
          icon: 'cil-dollar',
     },
     {
          _tag: 'CSidebarNavItem',
          name: 'Become a Partner',
          className: 'new',
          to: SUBSCRIBER_MY_ACCOUNT_PATH.BECOME_A_PARTNER,
          icon: 'cil-account-logout',
     },
     {
          _tag: 'CSidebarNavTitle',
          _children: ['SUPPORT'],
     },
     {
          _tag: 'CSidebarNavItem',
          id: 'support-tickets-nav',
          name: 'My Tickets',
          to: SUBSCRIBER_MY_ACCOUNT_PATH.SUPPORT_TICKETS,
          icon: 'icon-lifeRingSidebar',
     },
     {
          _tag: 'CSidebarNavTitle',
          _children: ['MY DETAILS'],
     },
     {
          _tag: 'CSidebarNavItem',
          name: 'My Profile',
          to: SUBSCRIBER_MY_ACCOUNT_PATH.PROFILE,
          icon: 'cil-user',
     },
     {
          _tag: 'CSidebarNavItem',
          name: 'Email Notifications',
          to: SUBSCRIBER_MY_ACCOUNT_PATH.EMAIL_NOTIFICATIONS,
          icon: 'cil-bell',
     },
     {
          _tag: 'CSidebarNavItem',
          name: 'Payment Methods',
          to: SUBSCRIBER_MY_ACCOUNT_PATH.PAYMENT_METHODS,
          icon: 'cil-dollar',
     },
];

const subscriberNavKey = {
     INSIGHTS: 'Insights',
     GOALS: 'Goals',
     LISTENERS: 'Sources',
     DESTINATIONS: 'Destinations',
     MONITORING: 'Health',
     CONSENT_PRIVACY: 'Consent',
     DASHBOARD: 'Dashboard',
};

const defaultSubscriberMonitoringNav = [
     {
          _tag: 'CSidebarNavItem',
          name: 'Dashboard',
          to: SUBSCRIBER_PATH.MONITORING_DASHBOARD,
     },
     {
          _tag: 'CSidebarNavItem',
          name: 'Monitoring Notifications',
          to: SUBSCRIBER_PATH.MONITORING_NOTIFICATIONS_V2,
          icon: 'iconBell',
     },
     {
          _tag: 'CSidebarNavDropdownCustom',
          name: 'Automatic Monitoring',
          _children: [
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Conversions',
                    icon: 'cil-layers',
                    to: SUBSCRIBER_PATH.AUTOMATIC_MONITORING_CONVERSIONS,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Listeners',
                    icon: 'icon-hubspotTinted',
                    to: SUBSCRIBER_PATH.AUTOMATIC_MONITORING_LISTENERS,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Destinations',
                    icon: 'icon-driftTinted',
                    to: SUBSCRIBER_PATH.AUTOMATIC_MONITORING_DESTINATIONS,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Form Categories',
                    icon: 'icon-driftTinted',
                    to: SUBSCRIBER_PATH.AUTOMATIC_MONITORING_FORMCATEGORIES,
               },
          ],
     },
     {
          _tag: 'CSidebarNavItem',
          name: 'Custom Monitoring',
          to: SUBSCRIBER_PATH.CUSTOM_MONITORING_RULES,
          exact: false,
     },
];

let reportNav = []; //eslint-disable-line

if (localStorage.getItem('llShowData') || window.location.search.includes('show-data=1')) {
     // eslint-disable-next-line no-unused-vars
     reportNav = [
          {
               _tag: 'CSidebarNavItem',
               name: 'User Explorer',
               to: SUBSCRIBER_PATH.INSIGHTS_USER_EXPLORER,
          },
          {
               _tag: 'CSidebarNavItem',
               name: 'Event Explorer',
               to: SUBSCRIBER_PATH.INSIGHTS_EVENT_EXPLORER,
          },
          {
               _tag: 'CSidebarNavItem',
               name: 'Page View Explorer',
               to: SUBSCRIBER_PATH.INSIGHTS_PAGE_VIEW_EXPLORER,
          },
          {
               _tag: 'CSidebarNavItem',
               name: 'Session Explorer',
               to: SUBSCRIBER_PATH.INSIGHTS_SESSION_EXPLORER,
          },
          {
               _tag: 'CSidebarNavItem',
               name: 'Person Explorer',
               to: SUBSCRIBER_PATH.INSIGHTS_PERSON_EXPLORER,
          },
          {
               _tag: 'CSidebarNavItem',
               name: 'Conversion Explorer',
               to: SUBSCRIBER_PATH.INSIGHTS_CONVERSION_EXPLORER,
          },
          {
               _tag: 'CSidebarNavItem',
               name: 'Conversion Name Explorer',
               to: SUBSCRIBER_PATH.INSIGHTS_CONVERSION_NAME_EXPLORER,
          },
          {
               _tag: 'CSidebarNavItem',
               name: 'User Source Session Explorer',
               to: SUBSCRIBER_PATH.INSIGHTS_USER_SOURCE_SESSION_EXPLORER,
          },
          {
               _tag: 'CSidebarNavItem',
               name: 'User Source User Explorer',
               to: SUBSCRIBER_PATH.INSIGHTS_USER_SOURCE_USER_EXPLORER,
          },
          {
               _tag: 'CSidebarNavItem',
               name: 'Entrance And Exit Explorer',
               to: SUBSCRIBER_PATH.INSIGHTS_ENTRANCE_AND_EXIT_EXPLORER,
          },
     ]; //eslint-disable-line
}

const defaultSubscriberAnalyticsNav = [
     {
          _tag: 'CSidebarNavItem',
          name: 'Overview',
          // to: SUBSCRIBER_PATH.INSIGHTS_DATA_LAYER,
          to: '/:secondId',
          badge: {
               color: 'info',
          },
     },
     {
          _tag: 'CSidebarNavItem',
          name: 'Dashboard',
          // to: SUBSCRIBER_PATH.INSIGHTS_DATA_LAYER,
          to: '/:secondId/dashboard',
          badge: {
               color: 'info',
          },
     },
     // ...reportNav,

     {
          _tag: 'CSidebarNavDropdownCustom',
          name: 'Attributes',
          to: SUBSCRIBER_PATH.INSIGHTS_ATTRIBUTES,
          _children: [
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Consent',
                    to: SUBSCRIBER_PATH.INSIGHTS_CONSENT,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Geography',
                    to: SUBSCRIBER_PATH.INSIGHTS_GEOGRAPHY,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Devices',
                    to: SUBSCRIBER_PATH.INSIGHTS_DEVICES,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Browsers',
                    to: SUBSCRIBER_PATH.INSIGHTS_BROWSERS,
               },
          ],
     },
     {
          _tag: 'CSidebarNavDropdownCustom',
          name: 'Acquisition',
          to: SUBSCRIBER_PATH.INSIGHTS_ACQUISITION,
          showTooltip: false,
          _children: [
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Channels',
                    to: SUBSCRIBER_PATH.INSIGHTS_CHANNEL,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Sources',
                    to: SUBSCRIBER_PATH.INSIGHTS_SOURCE,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'UTMs',
                    to: SUBSCRIBER_PATH.INSIGHTS_UTMS,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Referrals',
                    to: SUBSCRIBER_PATH.INSIGHTS_REFERRALS,
               },
          ],
     },
     {
          _tag: 'CSidebarNavDropdownCustom',
          name: 'Engagement',
          to: SUBSCRIBER_PATH.INSIGHTS_ENGAGEMENT,
          showTooltip: false,
          _children: [
               {
                    _tag: 'CSidebarNavItem',
                    name: 'New vs Returning',
                    to: SUBSCRIBER_PATH.INSIGHTS_NEW_RETURNING,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Session Count',
                    to: SUBSCRIBER_PATH.INSIGHTS_SESSION_COUNT,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Session Duration',
                    to: SUBSCRIBER_PATH.INSIGHTS_SESSION_DURATION,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Conversion Count',
                    to: SUBSCRIBER_PATH.INSIGHTS_CONVERSION_COUNT,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'People and User Count',
                    to: SUBSCRIBER_PATH.INSIGHTS_PEOPLE_COUNT,
               },
          ],
     },
     {
          _tag: 'CSidebarNavDropdownCustom',
          name: 'Content',
          to: SUBSCRIBER_PATH.INSIGHTS_CONTENT,
          showTooltip: false,
          _children: [
               {
                    _tag: 'CSidebarNavItem',
                    name: 'All Pages',
                    to: SUBSCRIBER_PATH.INSIGHTS_ALL_PAGES,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Landing Pages',
                    to: SUBSCRIBER_PATH.INSIGHTS_LANDING_PAGES,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Exit Pages',
                    to: SUBSCRIBER_PATH.INSIGHTS_EXIT_PAGES,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Entrance And Exit Pages',
                    to: SUBSCRIBER_PATH.INSIGHTS_ENTRANCE_EXIT_PAGES,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Scroll Depth',
                    to: SUBSCRIBER_PATH.INSIGHTS_SCROLL_DEPTH,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Visible Time',
                    to: SUBSCRIBER_PATH.INSIGHTS_VISIBLE_TIME,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Page Flow',
                    to: SUBSCRIBER_PATH.INSIGHTS_PAGE_FLOW,
               },
          ],
     },
     {
          _tag: 'CSidebarNavDropdownCustom',
          name: 'Conversions',
          to: SUBSCRIBER_PATH.INSIGHTS_CONVERSIONS,
          showTooltip: false,
          _children: [
               {
                    _tag: 'CSidebarNavItem',
                    name: 'By Conversion',
                    to: SUBSCRIBER_PATH.INSIGHTS_BY_CONVERSION,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'By Property',
                    to: SUBSCRIBER_PATH.INSIGHTS_BY_PROPERTY,
               },
               // {
               //      _tag: 'CSidebarNavItem',
               //      name: 'All Conversions',
               //      to: SUBSCRIBER_PATH.INSIGHTS_ALL_CONVERSIONS,
               // },
               // {
               //      _tag: 'CSidebarNavItem',
               //      name: 'By URL',
               //      to: SUBSCRIBER_PATH.INSIGHTS_BY_URL,
               // },
               // {
               //      _tag: 'CSidebarNavItem',
               //      name: 'By Source',
               //      to: SUBSCRIBER_PATH.INSIGHTS_BY_SOURCE,
               // },
               // {
               //      _tag: 'CSidebarNavItem',
               //      name: 'By UTM',
               //      to: SUBSCRIBER_PATH.INSIGHTS_BY_UTM,
               // },
               // {
               //      _tag: 'CSidebarNavItem',
               //      name: 'By Landing Page',
               //      to: SUBSCRIBER_PATH.INSIGHTS_BY_LANDING_PAGE,
               // },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'By Page Journey',
                    to: SUBSCRIBER_PATH.INSIGHTS_BY_PAGE_JOURNEY,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Attribution',
                    to: SUBSCRIBER_PATH.INSIGHTS_ATTRIBUTION,
               },
          ],
     },
     {
          _tag: 'CSidebarNavDropdownCustom',
          name: 'eCommerce',
          to: SUBSCRIBER_PATH.INSIGHTS_ECOMMERCE,
          _children: [
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Purchase Flow',
                    to: SUBSCRIBER_PATH.INSIGHTS_ECOMMERCE_PURCHASE_FLOW,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Items',
                    to: SUBSCRIBER_PATH.INSIGHTS_ECOMMERCE_ITEMS,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Purchase Activity',
                    to: SUBSCRIBER_PATH.INSIGHTS_ECOMMERCE_PURCHASE_ACTIVITY,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Purchase Transaction',
                    to: SUBSCRIBER_PATH.INSIGHTS_ECOMMERCE_PURCHASE_TRANSACTIONS,
               },
               // {
               //      _tag: 'CSidebarNavItem',
               //      name: 'Overview',
               //      to: SUBSCRIBER_PATH.INSIGHTS_ECOMMERCE_OVERVIEW,
               // },
               // {
               //      _tag: 'CSidebarNavItem',
               //      name: 'Transactions',
               //      to: SUBSCRIBER_PATH.INSIGHTS_TRANSACTIONS,
               // },
               // {
               //      _tag: 'CSidebarNavItem',
               //      name: 'By Source',
               //      to: SUBSCRIBER_PATH.INSIGHTS_ECOMMERCE_BY_SOURCE,
               // },
               // {
               //      _tag: 'CSidebarNavItem',
               //      name: 'By UTM',
               //      to: SUBSCRIBER_PATH.INSIGHTS_ECOMMERCE_BY_UTM,
               // },
               // {
               //      _tag: 'CSidebarNavItem',
               //      name: 'Product Performance',
               //      to: SUBSCRIBER_PATH.INSIGHTS_ECOMMERCE_PRODUCT_PERFORMANCE,
               // },
               // {
               //      _tag: 'CSidebarNavItem',
               //      name: 'Purchase Funnel',
               //      to: SUBSCRIBER_PATH.INSIGHTS_PURCHASE_FUNNEL,
               // },
          ],
     },
     // {
     //      _tag: 'CSidebarNavDropdown',
     //      name: 'Funnels & Paths',
     //      to: SUBSCRIBER_PATH.INSIGHTS_FUNNELS_PATHS,
     // },
     {
          _tag: 'CSidebarNavDropdownCustom',
          name: 'Explore Data',
          to: SUBSCRIBER_PATH.INSIGHTS_EXPLORE_DATA,
          showTooltip: false,
          _children: [
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Events',
                    to: SUBSCRIBER_PATH.INSIGHTS_EVENTS,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Conversion Events',
                    to: SUBSCRIBER_PATH.INSIGHTS_CONVERSION_EVENTS,
               },
               // {
               //      _tag: 'CSidebarNavItem',
               //      name: 'Pages',
               //      to: SUBSCRIBER_PATH.INSIGHTS_PAGES,
               // },
               // {
               //      _tag: 'CSidebarNavItem',
               //      name: 'Conversions',
               //      to: SUBSCRIBER_PATH.INSIGHTS_EXPLORE_CONVERSIONS,
               // },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Sessions',
                    to: SUBSCRIBER_PATH.INSIGHTS_SESSIONS,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Session Replays',
                    to: SUBSCRIBER_PATH.INSIGHTS_SESSION_REPLAYS,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Users',
                    to: SUBSCRIBER_PATH.INSIGHTS_USERS,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'People',
                    to: SUBSCRIBER_PATH.INSIGHTS_PEOPLE,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Revealed Companies',
                    to: SUBSCRIBER_PATH.INSIGHTS_REVEALED_COMPANIES,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Page View Explorer',
                    to: SUBSCRIBER_PATH.INSIGHTS_PAGE_VIEW_EXPLORER,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Form Explorer',
                    to: SUBSCRIBER_PATH.INSIGHTS_FORM_CATEGORY,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Form Submission Event',
                    to: SUBSCRIBER_PATH.INSIGHTS_FORM_SUBMISSION_EVENTS,
               },
          ],
     },
     {
          _tag: 'CSidebarNavDropdownCustom',
          name: 'Custom Reports',
          to: SUBSCRIBER_PATH.INSIGHTS_CUSTOM_REPORTS,
     },
     {
          _tag: 'CSidebarNavDropdownCustom',
          name: 'Data Settings',
          icon: 'cil-settings',
          className: 'item-dropdown-toggle sidebar-nav-dropdown-last',
          _children: [
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Metrics & Dimensions',
                    to: SUBSCRIBER_PATH.INSIGHTS_METRICS_AND_DIMENSIONS,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Channels',
                    to: SUBSCRIBER_PATH.INSIGHTS_CHANNELS,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Segments',
                    to: SUBSCRIBER_PATH.SEGMENT,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Custom Data Sets',
                    to: SUBSCRIBER_PATH.INSIGHTS_CUSTOM_DATA_SETS,
                    exact: false,
               },
          ],
     },
     {
          _tag: 'CSidebarNavItem',
          name: 'Save Reports',
          to: SUBSCRIBER_PATH.SAVE_REPORTS,
     },
];

// const defaultSubscriberGoalsNav = [
//      {
//           _tag: 'CSidebarNavItem',
//           name: 'Manage Goal Types',
//           icon: 'icon-plus',
//           className: 'custom-btn-add',
//      },
//      {
//           _tag: 'CSidebarNavItem',
//           name: 'Web Conversions',
//           icon: 'iconCircleStartSolid',
//      },
//      {
//           _tag: 'CSidebarNavItem',
//           name: 'eCommerce',
//           icon: 'iconDollarSign',
//      },
//      {
//           _tag: 'CSidebarNavItem',
//           name: 'Triggers',
//           icon: 'cil-layers',
//      },
//      {
//           _tag: 'CSidebarNavItem',
//           name: 'Web Funnels',
//           icon: 'iconFilterList',
//      },
//      {
//           _tag: 'CSidebarNavItem',
//           name: 'Form Categories',
//           icon: 'iconObjectColumn',
//      },
//      {
//           _tag: 'CSidebarNavItem',
//           name: 'Sales Pipeline',
//           icon: 'iconArrowRightLong',
//      },
//      {
//           _tag: 'CSidebarNavItem',
//           name: 'Cohorts',
//           icon: 'iconUserSolid',
//      },
//      {
//           _tag: 'CSidebarNavItem',
//           name: 'Target Accounts',
//           icon: 'iconBuildingsSolid',
//      },
//      {
//           _tag: 'CSidebarNavItem',
//           name: 'Competitors',
//           icon: 'iconCalendarExclamation',
//      },
// ];

if (localStorage.getItem('llShowTool') || window.location.search.includes('show-tool=1')) {
     if (!localStorage.getItem('llShowTool')) {
          localStorage.setItem('llShowTool', '1');
     }

     defaultSubscriberAnalyticsNav.push({
          _tag: 'CSidebarNavItem',
          name: 'Report Tool',
          to: SUBSCRIBER_PATH.INSIGHTS_REPORT_TOOL,
          exact: true,
     });
}

const defaultSubscriberNavGroup = [
     {
          name: 'Reporting Platform',
          key: 'reportingPlatform',
          icon: 'iconReportingPlatform',
     },
     {
          name: 'Data Platform',
          key: 'dataPlatform',
          icon: 'iconDataPlatform',
     },
     {
          name: 'Privacy Platform',
          key: 'privacyPlatform',
          icon: 'iconPrivacyPlatform',
     },
];

const defaultSubscriberNewNavigation = {
     reportingPlatform: [
          {
               name: 'ONE View',
               key: 'oneView',
               icon: 'iconNavONEView',
               _children: [
                    {
                         name: 'Executive Overview',
                         _children: [
                              {
                                   name: 'Performance Overview',
                                   to: NEW_NAVIGATION_PATH.ONEVIEW_PERFORMANCE_OVERVIEW,
                              },
                              {
                                   name: 'ROI Overview',
                                   to: NEW_NAVIGATION_PATH.ONEVIEW_ROI_OVERVIEW,
                              },
                              {
                                   name: 'B2B Overview',
                                   _children: [
                                        {
                                             name: 'Marketing Influence',
                                             to: NEW_NAVIGATION_PATH.ONEVIEW_B2B_MARKETING_INFLUENCE,
                                        }
                                   ]
                              },
                              {
                                   name: 'eCommerce Overview',
                                   to: NEW_NAVIGATION_PATH.ONEVIEW_ECOMMERCE_OVERVIEW,
                              },
                              {
                                   name: 'FinanceLink Overview',
                                   to: NEW_NAVIGATION_PATH.ONEVIEW_FINANCELINK_OVERVIEW,
                              },
                              {
                                   name: 'HealthLink Overview',
                                   to: NEW_NAVIGATION_PATH.ONEVIEW_HEALTHLINK_OVERVIEW,
                              },
                         ],
                    },
                    {
                         name: 'Funnels',
                         to: NEW_NAVIGATION_PATH.ONEVIEW_FUNNELS,
                    },
                    {
                         name: 'Pipelines',
                         _children: [
                              {
                                   name: 'Activity',
                                   to: NEW_NAVIGATION_PATH.ONEVIEW_PIPELINES_ACTIVITY,
                              },
                              {
                                   name: 'Snapshot',
                                   to: NEW_NAVIGATION_PATH.ONEVIEW_PIPELINES_SNAPSHOT,
                              },
                              {
                                   name: 'Forecast',
                                   to: NEW_NAVIGATION_PATH.ONEVIEW_PIPELINES_FORECAST,
                              },
                         ],
                    },
                    {
                         name: 'All Conversions',
                         _children: [
                              {
                                   name: 'Overview',
                                   to: NEW_NAVIGATION_PATH.ONEVIEW_CONVERSIONS_OVERVIEW,
                              },
                              {
                                   name: 'Pacing',
                                   to: NEW_NAVIGATION_PATH.ONEVIEW_CONVERSIONS_PACING,
                              },
                              {
                                   name: 'Trend',
                                   to: NEW_NAVIGATION_PATH.ONEVIEW_CONVERSIONS_TREND,
                              },
                              {
                                   name: 'By Conversion Action',
                                   to: NEW_NAVIGATION_PATH.ONEVIEW_CONVERSIONS_ACTION,
                              },
                         ],
                    },
                    {
                         name: 'Attribution',
                         _children: [
                              {
                                   name: 'Overview',
                                   to: NEW_NAVIGATION_PATH.ONEVIEW_ATTRIBUTION_OVERVIEW,
                              },
                              {
                                   name: 'Channel',
                                   to: NEW_NAVIGATION_PATH.ONEVIEW_ATTRIBUTION_CHANNEL,
                              },
                              {
                                   name: 'Content Engagement',
                                   to: NEW_NAVIGATION_PATH.ONEVIEW_ATTRIBUTION_ENGAGEMENT,
                              },
                              {
                                   name: 'Path',
                                   to: NEW_NAVIGATION_PATH.ONEVIEW_ATTRIBUTION_PATH,
                              },
                              {
                                   name: 'Intent & Content Type',
                                   to: NEW_NAVIGATION_PATH.ONEVIEW_ATTRIBUTION_INTENT,
                              },
                              {
                                   name: 'Self Reported',
                                   to: NEW_NAVIGATION_PATH.ONEVIEW_ATTRIBUTION_SELF_REPORTED,
                              },
                         ],
                    },
               ],
          },
          {
               name: 'Performance',
               key: 'performance',
               icon: 'iconNavPerformance',
               _children: [
                    {
                         name: 'Web Performance',
                         _children: [
                              {
                                   name: 'Overview',
                                   to: NEW_NAVIGATION_PATH.PERFORMANCE_OVERVIEW,
                              },
                              {
                                   name: 'Attributes',
                                   _children: [
                                        {
                                             name: 'Consent',
                                             to: NEW_NAVIGATION_PATH.PERFORMANCE_ATTRIBUTES_CONSENT,
                                        },
                                        {
                                             name: 'Geography',
                                             to: NEW_NAVIGATION_PATH.PERFORMANCE_ATTRIBUTES_GEOGRAPHY,
                                        },
                                        {
                                             name: 'Devices',
                                             to: NEW_NAVIGATION_PATH.PERFORMANCE_ATTRIBUTES_DEVICES,
                                        },
                                        {
                                             name: 'Browsers',
                                             to: NEW_NAVIGATION_PATH.PERFORMANCE_ATTRIBUTES_BROWSERS,
                                        },
                                   ],
                              },
                              {
                                   name: 'Acquisition',
                                   _children: [
                                        {
                                             name: 'Channels',
                                             to: NEW_NAVIGATION_PATH.PERFORMANCE_ACQUISITION_CHANNELS,
                                        },
                                        {
                                             name: 'Sources',
                                             to: NEW_NAVIGATION_PATH.PERFORMANCE_ACQUISITION_SOURCES,
                                        },
                                        {
                                             name: 'UTMs',
                                             to: NEW_NAVIGATION_PATH.PERFORMANCE_ACQUISITION_UTMS,
                                        },
                                        {
                                             name: 'Referrals',
                                             to: NEW_NAVIGATION_PATH.PERFORMANCE_ACQUISITION_REFERRALS,
                                        },
                                   ],
                              },
                              {
                                   name: 'Pacing',
                                   to: NEW_NAVIGATION_PATH.PERFORMANCE_PACING,
                              },
                              {
                                   name: 'Trend',
                                   to: NEW_NAVIGATION_PATH.PERFORMANCE_TREND,
                              },
                              {
                                   name: 'Engagement',
                                   _children: [
                                        {
                                             name: 'Overview',
                                             to: NEW_NAVIGATION_PATH.PERFORMANCE_ENGAGEMENT_OVERVIEW,
                                        },
                                        {
                                             name: 'By Property',
                                             to: NEW_NAVIGATION_PATH.PERFORMANCE_ENGAGEMENT_PROPERTY,
                                        },
                                   ],
                              },
                              {
                                   name: 'Content',
                                   _children: [
                                        {
                                             name: 'All Pages',
                                             to: NEW_NAVIGATION_PATH.PERFORMANCE_CONTENT_ALL_PAGES,
                                        },
                                        {
                                             name: 'Entrance & Exits',
                                             to: NEW_NAVIGATION_PATH.PERFORMANCE_CONTENT_ENTRANCE,
                                        },
                                        {
                                             name: 'Visible',
                                             to: NEW_NAVIGATION_PATH.PERFORMANCE_CONTENT_VISIBLE,
                                        },
                                        {
                                             name: 'Page Flow',
                                             to: NEW_NAVIGATION_PATH.PERFORMANCE_CONTENT_PAGE_FLOW,
                                        },
                                   ],
                              },
                              {
                                   name: 'Web Conversions',
                                   _children: [
                                        {
                                             name: 'Overview',
                                             to: NEW_NAVIGATION_PATH.PERFORMANCE_WEB_OVERVIEW,
                                        },
                                        {
                                             name: 'By Conversion Action',
                                             to: NEW_NAVIGATION_PATH.PERFORMANCE_WEB_ACTION,
                                        },
                                        {
                                             name: 'By Property',
                                             to: NEW_NAVIGATION_PATH.PERFORMANCE_WEB_PROPERTY,
                                        },
                                        {
                                             name: 'Attribution',
                                             to: NEW_NAVIGATION_PATH.PERFORMANCE_WEB_ATTRIBUTION,
                                        },
                                   ],
                              },
                         ],
                    },
                    {
                         name: 'eCommerce Performance',
                         _children: [
                              {
                                   name: 'Purchase Flow',
                                   to: NEW_NAVIGATION_PATH.PERFORMANCE_PURCHASE_FLOW,
                              },
                              {
                                   name: 'Items',
                                   to: NEW_NAVIGATION_PATH.PERFORMANCE_ITEMS,
                              },
                              {
                                   name: 'Purchase Activity',
                                   to: NEW_NAVIGATION_PATH.PERFORMANCE_PURCHASE_ACTIVITY,
                              },
                              {
                                   name: 'Purchase Transactions',
                                   to: NEW_NAVIGATION_PATH.PERFORMANCE_PURCHASE_TRANSACTIONS,
                              },
                         ],
                    },
                    {
                         name: 'Sales Performance',
                         _children: [
                              {
                                   name: 'Sales Objects',
                                   _children: [
                                        {
                                             name: 'Reporting',
                                             to: NEW_NAVIGATION_PATH.PERFORMANCE_SALES_REPORTING,
                                        },
                                   ],
                              },
                              {
                                   name: 'Sales Conversions',
                                   _children: [
                                        {
                                             name: 'Overview',
                                             to: NEW_NAVIGATION_PATH.PERFORMANCE_SALES_OVERVIEW,
                                        },
                                        {
                                             name: 'By Conversion Action',
                                             to: NEW_NAVIGATION_PATH.PERFORMANCE_SALES_ACTION,
                                        },
                                        {
                                             name: 'By Property',
                                             to: NEW_NAVIGATION_PATH.PERFORMANCE_SALES_PROPERTY,
                                        },
                                        {
                                             name: 'Attribution',
                                             to: NEW_NAVIGATION_PATH.PERFORMANCE_SALES_ATTRIBUTION,
                                        },
                                   ],
                              },
                         ],
                    },
                    {
                         name: 'Paid Performance',
                         to: NEW_NAVIGATION_PATH.PERFORMANCE_PAID,
                    },
                    {
                         name: 'ABM Performance',
                         _children: [
                              {
                                   name: 'Companies Overview',
                                   to: NEW_NAVIGATION_PATH.PERFORMANCE_COMPANIES_OVERVIEW,
                              },
                              {
                                   name: 'Target Account Overview',
                                   to: NEW_NAVIGATION_PATH.PERFORMANCE_TARGET_ACCOUNT,
                              },
                              {
                                   name: 'Companies by Property',
                                   to: NEW_NAVIGATION_PATH.PERFORMANCE_COMPANIES_PROPERTY,
                              },
                              {
                                   name: 'Explore Companies',
                                   to: NEW_NAVIGATION_PATH.PERFORMANCE_EXPLORE_COMPANIES,
                              },
                              {
                                   name: 'Buying Group',
                                   to: NEW_NAVIGATION_PATH.PERFORMANCE_BUYING_GROUP,
                              },
                         ],
                    },
               ],
          },
          {
               name: 'Journeys',
               key: 'journeyExploration',
               icon: 'iconNavJourneys',
               _children: [
                    {
                         name: 'Events',
                         to: NEW_NAVIGATION_PATH.JOURNEY_EVENTS,
                    },
                    {
                         name: 'Conversion Events',
                         to: NEW_NAVIGATION_PATH.JOURNEY_CONVERSION_EVENTS,
                    },
                    {
                         name: 'Sessions',
                         to: NEW_NAVIGATION_PATH.JOURNEY_SESSIONS,
                    },
                    {
                         name: 'Session Replays',
                         to: NEW_NAVIGATION_PATH.JOURNEY_SESSION_REPLAYS,
                    },
                    {
                         name: 'Users',
                         to: NEW_NAVIGATION_PATH.JOURNEY_USERS,
                    },
                    {
                         name: 'People',
                         to: NEW_NAVIGATION_PATH.JOURNEY_PEOPLE,
                    },
                    {
                         name: 'Reveal Companies',
                         to: NEW_NAVIGATION_PATH.JOURNEY_REVEAL_COMPANIES,
                    },
                    {
                         name: 'Page View Explorer',
                         to: NEW_NAVIGATION_PATH.JOURNEY_PAGE_VIEW,
                    },
                    {
                         name: 'Form Explorer',
                         to: NEW_NAVIGATION_PATH.JOURNEY_FORM_EXPLORER,
                    },
                    {
                         name: 'Form Submission Event',
                         to: NEW_NAVIGATION_PATH.JOURNEY_FORM_SUBMISSION,
                    },
               ],
          },
          {
               name: 'Custom',
               key: 'customReport',
               icon: 'iconNavCustom',
               _children: [
                    {
                         name: 'Dashboards',
                         to: NEW_NAVIGATION_PATH.CUSTOM_DASHBOARDS,
                    },
                    {
                         name: 'Save Reports',
                         to: NEW_NAVIGATION_PATH.CUSTOM_SAVE_REPORTS,
                    },
                    {
                         name: 'Tables',
                         to: NEW_NAVIGATION_PATH.CUSTOM_REPORTS,
                    },
                    {
                         name: 'Pacing & Trends',
                         to: NEW_NAVIGATION_PATH.CUSTOM_PACING_TRENDS,
                    },
                    {
                         name: 'Attribution',
                         to: NEW_NAVIGATION_PATH.CUSTOM_ATTRIBUTION,
                    },
                    {
                         name: 'Collections',
                         to: NEW_NAVIGATION_PATH.CUSTOM_COLLECTIONS,
                    },
                    {
                         name: 'Scheduled',
                         to: NEW_NAVIGATION_PATH.CUSTOM_SCHEDULED,
                    },
               ],
          },
          {
               name: 'Notifications',
               key: 'notifications',
               icon: 'iconNavNotifications',
               to: NEW_NAVIGATION_PATH.NOTIFICATIONS,
          },
          {
               name: 'Revenue',
               key: 'revenue',
               icon: 'iconNavRevenue',
               to: NEW_NAVIGATION_PATH.REVENUE,
          },
          {
               name: 'Data Settings',
               key: 'dataSettings',
               icon: 'iconNavDataSettings',
               _children: [
                    {
                         name: 'Metrics & Dimensions',
                         to: NEW_NAVIGATION_PATH.DATA_METRICS,
                    },
                    {
                         name: 'Channels',
                         to: NEW_NAVIGATION_PATH.DATA_CHANNELS,
                    },
                    {
                         name: 'Segments',
                         to: NEW_NAVIGATION_PATH.DATA_SEGMENTS,
                    },
                    {
                         name: 'Custom Data Sets',
                         to: NEW_NAVIGATION_PATH.DATA_SETS,
                    },
               ],
          },
     ],

     dataPlatform: [
          {
               name: 'Objectives',
               key: 'objectives',
               icon: 'iconNavObjectives',
               _children: [],
          },
          {
               name: 'Listeners',
               key: 'listeners',
               icon: 'iconNavListeners',
               _children: [],
          },
          {
               name: 'Destinations',
               key: 'destinations',
               icon: 'iconNavDestinations',
               _children: [],
          },
          {
               name: 'Data Rules',
               key: 'dataRules',
               icon: 'iconNavDataRules',
               _children: [
                    {
                         name: 'Data Layer Values',
                         to: NEW_NAVIGATION_PATH.RULES_DATA_LAYER_VALUES,
                    },
                    {
                         name: 'Browser Storage',
                         to: NEW_NAVIGATION_PATH.RULES_BROWSER_STORAGE,
                    },
                    {
                         name: 'Populate Form Fields',
                         to: NEW_NAVIGATION_PATH.RULES_POPULATE_FORM_FIELDS,
                    },
                    {
                         name: 'Other Data Transformations',
                         to: NEW_NAVIGATION_PATH.RULES_DATA_TRANSFORMATIONS,
                    },
               ],
          },
          {
               name: 'Monitoring',
               key: 'monitoring',
               icon: 'iconNavMonitoring',
               _children: [
                    {
                         name: 'Dashboard',
                         to: NEW_NAVIGATION_PATH.MONITORING_DASHBOARD,
                    },
                    {
                         name: 'Monitoring Notifications',
                         to: NEW_NAVIGATION_PATH.MONITORING_NOTIFICATIONS,
                    },
                    {
                         name: 'Automatic Monitoring',
                         _children: [
                              {
                                   name: 'Conversions',
                                   to: NEW_NAVIGATION_PATH.MONITORING_AUTOMATIC_CONVERSIONS,
                              },
                              {
                                   name: 'Listeners',
                                   to: NEW_NAVIGATION_PATH.MONITORING_AUTOMATIC_LISTENERS,
                              },
                              {
                                   name: 'Destinations',
                                   to: NEW_NAVIGATION_PATH.MONITORING_AUTOMATIC_DESTINATIONS,
                              },
                              {
                                   name: 'Form Categories',
                                   to: NEW_NAVIGATION_PATH.MONITORING_AUTOMATIC_FORM_CATEGORIES,
                              },
                         ],
                    },
                    {
                         name: 'Custom Monitoring',
                         to: NEW_NAVIGATION_PATH.MONITORING_CUSTOM_MONITORING,
                    },
               ],
          },
     ],

     privacyPlatform: [
          {
               name: 'Consent',
               key: 'consent',
               icon: 'iconNavConsent',
               _children: [
                    {
                         name: 'Consent Setting',
                         to: NEW_NAVIGATION_PATH.CONSENT_SETTING,
                    },
                    {
                         name: 'Data Categories',
                         to: NEW_NAVIGATION_PATH.CONSENT_DATA_CATEGORIES,
                    },
                    {
                         name: 'Consent Logs',
                         to: NEW_NAVIGATION_PATH.CONSENT_LOGS,
                    },
                    {
                         name: 'Data Requests',
                         to: NEW_NAVIGATION_PATH.CONSENT_DATA_REQUESTS,
                    },
               ],
          },
          {
               name: 'Reveal',
               key: 'reveal',
               icon: 'iconNavReveal',
               _children: [
                    {
                         name: 'Revealed People',
                         to: NEW_NAVIGATION_PATH.REVEAL_PEOPLE,
                    },
                    {
                         name: 'Revealed Companies',
                         to: NEW_NAVIGATION_PATH.REVEAL_COMPANIES,
                    },
               ],
          },
          {
               name: 'Healthcare',
               key: 'healthcare',
               icon: 'iconNavHealthcare',
               to: NEW_NAVIGATION_PATH.HEALTHCARE,
          },
     ],
};

export {
     defaultSubscriberNav,
     subscriberMyAccountNav,
     subscriberNavKey,
     defaultSubscriberMonitoringNav,
     defaultSubscriberAnalyticsNav,
     defaultSubscriberNavGroup,
     defaultSubscriberNewNavigation,
};
