import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CButton, CAlert } from '@coreui/react';
import { toast } from 'react-toastify';
import CenterSpinner from '../../../../../../general/Loadings/CenterSpinner.js';
import { API_SALESFORCE_RULE, API_SALESFORCE_SELECT_FIELDS, TYPE_SHOW_UNSAVE_CHANGE, WRITE_RULE_SECTION } from '../../../../../../../constants/index.js';
import { setRuleHaveEditting } from '../../../../../../../actions/common.js';
import { callSalesforceApi } from '../../../../../../../apiCaller.js';
import { SFSetProgressHistoricalSync } from '../../../../../../../actions/external.js';

export const ObjectDetailContext = React.createContext({});

const DataSourceConnection = React.lazy(() => import(`./steps/DataSourceConnection.js`));
const SystemFields = React.lazy(() => import(`./steps/SystemFields.js`));
const PreviewObject = React.lazy(() => import(`./steps/PreviewObject.js`))
const SetUpFields = React.lazy(() => import(`./steps/SetUpFields.js`))
const PipelineFields = React.lazy(() => import(`./steps/PipelineFields.js`));
const ValueFields = React.lazy(() => import(`./steps/ValueFields.js`));
const RelationshipFields = React.lazy(() => import(`./steps/RelationshipFields.js`));
const TargetFields = React.lazy(() => import(`./steps/TargetFields.js`));
const OtherFields = React.lazy(() => import(`./steps/OtherFields.js`));
const CustomFields = React.lazy(() => import(`./steps/CustomFields.js`));
// const Relationships = React.lazy(() => import(`./steps/Relationships.js`));
const NameDescription = React.lazy(() => import(`./steps/NameDescription.js`));
const HistoricalSync = React.lazy(() => import(`./steps/HistoricalSync.js`));

const ObjectImportDetail = ({ id }) => {
     const dispatch = useDispatch();
     const [isLoading, setIsLoading] = useState(false);
     const [activeStep, setActiveStep] = useState(1);
     const [stepsData, setStepsData] = useState({});
     const [currentData, setCurrentData] = useState({});
     const [initStepsData, setInitStepsData] = useState({});
     const [listObjectsField, setListObjectsField] = useState([]);
     const [listFields, setListFields] = useState([]);
     const [isLoadingGetNewField, setIsLoadingGetNewField] = useState(false);
     const [stepsMappingField, setStepsMappingField] = useState({});
     const [ruleSetup, setRuleSetup] = useState(false);
     const [logError, setLogError] = useState(false);
     const [selectExistingState, setSelectExistingState] = useState({});

     const setSelectExistingFor = (id, value) => {
          setSelectExistingState((prev) => ({
              ...prev,
              [id]: value,
          }));
     };

     const fetchDataSelectFields = (object, getNew = false) => {
          let endPoint = `${API_SALESFORCE_SELECT_FIELDS}?ruleId=${id}`;
          if (getNew) {
               endPoint = `${endPoint}&getNew=true`;
          }

          return callSalesforceApi(endPoint, 'GET').then((response) => {
               if (response && response.status === 200) {
                    const { data } = response.data;

                    if (data) {
                         const addDefaultField = (fields) => {
                              fields.unshift({
                                   value: '',
                                   label: 'Select a field',
                                   type: 'default',
                                   section: '',
                              });
                         };
                    
                         const addGetNewFields = (fields) => {
                              if (!fields.some((option) => option.value === 'getNewFields')) {
                                   fields.push({
                                        name: 'getNewFields',
                                        value: 'getNewFields',
                                        newFields: true,
                                        type: 'default',
                                        section: '',
                                        label: (
                                             <CButton className="option-create-categories text-nowrap d-flex align-items-baseline" color="link" onClick={handleGetNewField}>
                                                  <i className="fal fa-plus"></i>
                                                  <span className='text-primary'>Get new fields</span>
                                             </CButton>
                                        ),
                                   });
                              }
                         };

                         // Generate select field options
                         const selectFieldsOptions = data.map((field) => ({
                              value: field.name,
                              label: field.name,
                              type: field.type,
                              custom: field.custom,
                              labelField: field.label,
                              section: WRITE_RULE_SECTION[field.type] === 'default' ? '' : WRITE_RULE_SECTION[field.type] ?? 'Textarea'
                         }));

                         const dataOptions = data.map((field) => ({
                              ...field,
                              labelField: field.label,
                         })).sort((a, b) => a.labelField.localeCompare(b.labelField));

                         function filterDupplicateData(records) {
                              const labelCount = records.reduce((acc, label) => {
                                   acc[label.labelField] = (acc[label.labelField] || 0) + 1;
                                   return acc;
                              }, {});

                              return records
                                   .map((_item, index) => {
                                        const fieldName = records[index];

                                        if (_item.labelField && labelCount[_item.labelField] > 1) {
                                             return {
                                                  ...fieldName,
                                                  labelField: `${fieldName.labelField} (${fieldName.value || fieldName.name})`
                                             };
                                        }
                              
                                        return fieldName;
                                   });
                         }

                         // Add default option to both lists
                         addDefaultField(dataOptions);
                         addDefaultField(selectFieldsOptions);

                         // Add "getNewFields" option to both lists
                         addGetNewFields(dataOptions);
                         addGetNewFields(selectFieldsOptions);
                         
                         // Update state
                         setListFields(filterDupplicateData(dataOptions));
                         setListObjectsField(filterDupplicateData(selectFieldsOptions));
                    }
               } else {
                    toast.error('Get object info failed!');
               }
          });
     };

     const customFilter = useCallback((option, searchText) => {
          if (
               option.data.labelField?.toLowerCase().includes(searchText.toLowerCase()) ||
               option.data.type === 'default'
          ) {
               return true;
          }

          return false;
     }, []);

     const handleGetNewField = () => {
          setIsLoadingGetNewField(true);

          const object = stepsData.object;
          fetchDataSelectFields(object, true)
               .then(() => fetchDataGetNew())
               .finally(() => setIsLoadingGetNewField(false));
     };

     const handleRuleLogError = useCallback(() => {
          if (!id) return;

          return callSalesforceApi(`${API_SALESFORCE_RULE}/log-error/${id}`, 'POST').then((response) => {
               if (response && response.status === 200) {
                    setLogError(false);
               }
          });
     }, [id]);

     const fetchMappingField = () => {
          if (!id) return;

          return callSalesforceApi(`${API_SALESFORCE_RULE}/get-mapping-field/${id}`, 'GET').then((response) => {
               if (response && response.status === 200) {
                    const { data } = response.data;

                    if (data) {
                         setStepsMappingField(data);
                    }
               } else {
                    if (response && response.status === 400 && response.data.isSetupRule) {
                         setRuleSetup(response.data.isSetupRule);
                    } else {
                         toast.error('Get data failed!');
                    }
               }
          });
     };
     
     const fetchData = () => {
          if (!id) return;

          setIsLoading(true);
          fetchMappingField();
          callSalesforceApi(`${API_SALESFORCE_RULE}/${id}`, 'GET').then((response) => {
               if (response && response.status === 200) {
                    const { data } = response.data;
                    const rule = data;

                    if (data) {
                         dispatch(SFSetProgressHistoricalSync(data.jobActive));
                         setLogError(data.isErrorSupported);
                         setStepsData(data);
                         setCurrentData(data);
                         setInitStepsData(data);
                         const object = rule.object;
                         fetchDataSelectFields(object).finally(() => setIsLoading(false));
                    }
               } else {
                    if (response && response.status === 400 && response.data.isSetupRule) {
                         setRuleSetup(response.data.isSetupRule);
                    } else {
                         toast.error('Get data failed!');
                    }

               }
          });
     };

     useEffect(fetchData, []); // eslint-disable-line react-hooks/exhaustive-deps

     const fetchDataGetNew = () => {
          if (!id) return;

          callSalesforceApi(`${API_SALESFORCE_RULE}/${id}`, 'GET').then((response) => {
               if (response && response.status === 200) {
                    const { data } = response.data;

                    if (data) {
                         setStepsData(data);
                         setInitStepsData(data);
                         setCurrentData(data);
                    }
               }
          });
     };

     const handleHasChange = () => {
          dispatch(setRuleHaveEditting({ show: true, type: TYPE_SHOW_UNSAVE_CHANGE.EDIT_SIMPLE }));
     };

     const mappingComponents = [
          { key: 'systemFieldsMapping', component: SystemFields },
          { key: 'pipelineFieldsMapping', component: PipelineFields },
          { key: 'valueFieldsMapping', component: ValueFields },
          { key: 'relationshipFieldsMapping', component: RelationshipFields },
          { key: 'targetFieldsMapping', component: TargetFields },
          { key: 'otherFieldsMapping', component: OtherFields },
          { key: 'setUpFieldsMapping', component: SetUpFields },
          { key: 'customFieldsMapping', component: CustomFields },
          // { key: 'relationships', component: Relationships },
     ];

     const hasData = (obj) => obj !== undefined && obj.length > 0;

     const componentsWithData = mappingComponents.reduce((components, { key, component }) => {
          if (hasData(initStepsData[key]) || (!initStepsData.isDefault && ['setUpFieldsMapping', 'relationshipFieldsMapping'].includes(key))) {
               components.push({ component, index: components.length });
          }
          return components;
     }, []);

     let count = componentsWithData.length;

     componentsWithData.push({ component: NameDescription, index: count++ }, { component: HistoricalSync, index: count });

     const systemFieldsMappingIndex = componentsWithData.findIndex(({ component }) => component === SystemFields);
     if (systemFieldsMappingIndex !== -1) {
          componentsWithData.splice(systemFieldsMappingIndex + 1, 0, {
               component: PreviewObject,
               index: systemFieldsMappingIndex + 1
          });

          // Update indices for components after the insertion
          for (let i = systemFieldsMappingIndex + 2; i < componentsWithData.length; i++) {
               componentsWithData[i].index = i;
          }
     }

     return (
          <React.Suspense fallback={<CenterSpinner />}>
               <div className="rule-detail object-import-detail">
                    {ruleSetup ? (
                         <div className='text-danger text-center'>Rule is being setup!</div>
                    ) : (
                         <>
                              {isLoading ? (
                                   <>
                                        <CenterSpinner />
                                   </>
                              ) : (
                                   <div className="cvr-create-new">
                                        <h1>{id ? `Edit: Salesforce Object Import - ${stepsData.name}` : `Create: Salesforce Object Import`}</h1>
                                        <p>{id ? `Edit` : `Create a new`} external data source by configuring the settings below.</p>
                                        {logError && (
                                             <div className='relative'>
                                                  <CAlert color="warning">
                                                       {stepsData.name} cannot be imported at the moment. Please enable it in Salesforce. Click 'Confirm' to proceed with importing other objects without any impacts.
                                                  </CAlert>

                                                  <CButton
                                                       color='primary'
                                                       className='position-absolute'
                                                       size="sm"
                                                       style={{
                                                            top: '50%',
                                                            right: '10px',
                                                            transform: 'translateY(-50%)',
                                                       }}
                                                       onClick={handleRuleLogError}
                                                  >
                                                       <span>Confirm</span>
                                                  </CButton>
                                             </div>
                                        )}
                                        <ObjectDetailContext.Provider
                                             value={{
                                                  id,
                                                  activeStep,
                                                  setActiveStep,
                                                  initStepsData,
                                                  setInitStepsData,
                                                  stepsData,
                                                  setStepsData,
                                                  currentData,
                                                  setCurrentData,
                                                  handleHasChange,
                                                  listObjectsField,
                                                  listFields,
                                                  fetchData,
                                                  fetchDataGetNew,
                                                  fetchDataSelectFields,
                                                  handleGetNewField,
                                                  isLoadingGetNewField,
                                                  setIsLoadingGetNewField,
                                                  stepsMappingField,
                                                  setStepsMappingField,
                                                  fetchMappingField,
                                                  customFilter,
                                                  selectExistingState,
                                                  setSelectExistingFor,
                                             }}
                                        >
                                             <DataSourceConnection />
                                             {componentsWithData.map((mapping) => {
                                                  const { component: Component, index } = mapping;
                                                  return <Component key={index} step={index + 2} />;
                                             })}
                                        </ObjectDetailContext.Provider>
                                   </div>
                              )}
                         </>
                    )}
               </div>
          </React.Suspense>
     );
};

export default ObjectImportDetail;
