// ** React Imports
import React, { useRef, useEffect } from 'react';

// ** CoreUI Imports
import { CButton, CModal, CModalBody, CForm } from '@coreui/react';
import CIcon from '@coreui/icons-react';

// ** Validations
import { Formik } from 'formik';
import * as Yup from 'yup';

// ** Constants Imports
import { VIEW_TYPE } from '../../../../../../constants/reportView';
import { useSelector } from 'react-redux';
import { CInput, CInputRadio, CTextarea } from '../../../../../migration/CInput';
import CFormGroup from '../../../../../migration/CFormGroup';
import { CInvalidFeedback } from '../../../../../migration/CInvalidFeedback';
import CLabel from '../../../../../migration/CLabel';
import CTooltip from '../../../../../migration/CTooltip';
import dayjs from 'dayjs';
import DateRangeConfig from './DateRangeConfig';

const validationSchema = (isSaveReport) => {
     return Yup.object().shape({
          name: Yup.string()
               .min(2, `Name has to be at least 2 characters`)
               .max(200, `Name contains at most 200 characters`)
               .required('Name is required'),
          dateRangeConfig: isSaveReport ? Yup.object().nullable().shape({
               rolling: Yup.object().nullable().shape({
                    numberPrevious: Yup.number().nullable().when('type', {
                         is: (type) => ['previous_x_days', 'previous_x_weeks'].includes(type),
                         then: (schema) =>
                              schema.required('Field Required').min(1, 'Must be greater than 0'),
                         otherwise: (schema) => schema.notRequired(),
                    }),
               })
          }) : Yup.mixed().notRequired()
     });
};

const CreateNewView = ({ show, data, type, onAccept, onCancel, reportName, scoped, listView }) => {
     const user = useSelector((state) => state.theme.user);
     const savedReports = useSelector((state) => state.subscriber.savedReport);

     const isSaveReport = type === VIEW_TYPE.REPORT;
     let savedReport = [];
     let isDefault = false;

     if(data && !isSaveReport && savedReports?.length > 0){
          savedReport = savedReports.filter(report => report.viewId === data.id);
     }

     if(data && !isSaveReport && listView?.length > 0){
          isDefault = listView.find(report => report.id === data.id && report.isDefault && report.isAvailable);
     }

     const initialValues = {
          name: data ? data.name : '',
          description: data ? data.description : '',
          isAvailable: data ? data.isAvailable : true, 
     };

     if (isSaveReport) {
          if (data?.content?.dateRangeConfig) {
               initialValues["dateRangeConfig"] = data.content.dateRangeConfig

               if (data.content.dateRangeConfig.type === 'staticRange') {
                    const newDateRanges = data.content.dateRangeConfig.dateRanges
                    if (newDateRanges) {
                         if (newDateRanges.selection) {
                              newDateRanges.selection.startDate = dayjs(newDateRanges.selection.startDate)
                              newDateRanges.selection.endDate = dayjs(newDateRanges.selection.endDate)
                         }

                         if (newDateRanges.compare) {
                              newDateRanges.compare.startDate = dayjs(newDateRanges.compare.startDate)
                              newDateRanges.compare.endDate = dayjs(newDateRanges.compare.endDate)
                         }
                    }

                    data.content.dateRangeConfig.dateRanges = newDateRanges
               }

               if (data.content.dateRangeConfig.type === 'rollingRange' && data.content.dateRangeConfig.rolling?.isComparison && data.content.dateRangeConfig.rolling?.periodType) {
                    const newDateRanges = data.content.dateRangeConfig.rolling.customDateCompare
                    if (newDateRanges) {
                         if (newDateRanges.selection) {
                              newDateRanges.selection.startDate = dayjs(newDateRanges.selection.startDate)
                              newDateRanges.selection.endDate = dayjs(newDateRanges.selection.endDate)
                         }

                    }

                    data.content.dateRangeConfig.rolling.customDateCompare = newDateRanges
               }
          } else {
               initialValues["dateRangeConfig"] = {
                    type: 'noRange',
                    dateScope: 'Event activity',
                    dateRanges: null,
                    rolling: null
               }
          }
     }

     const nameInputRef = useRef();

     useEffect(() => {
          if (show) {
               setTimeout(() => {
                    nameInputRef.current?.focus();
               }, 0);
          }
     }, [show]);

     const isSetPrivate = !data || data.userId === user.id;

     const getListNameSavedReport = (data) => {
          return data.map(item => item.name).join(', ');
     }

     return (
          <div className="confirm-save-popup select-domain popup-create-variable savedview">
               <CModal visible={show} onClose={onCancel} color="primary" alignment="center" portal={false}>
                    <CModalBody style={{ padding: '38px 0 30px' }} className='text-center confirm-save'>
                         <CIcon icon="cil-x" onClick={onCancel} className="icon-close-popup"></CIcon>

                         <div className="popup-publish-version-description">
                              <h1>{isSaveReport ? `${data?.id ? 'Edit' : 'Create new'} Save Report` : `${data?.id ? 'Edit' : 'Create new'} Save View`}</h1>
                              <p>Now give your {isSaveReport ? 'report' : 'view'} a name and description.</p>
                         </div>

                         <Formik
                              initialValues={initialValues}
                              validationSchema={validationSchema(isSaveReport)}
                              onSubmit={onAccept}
                              onClose={onCancel}
                              validateOnChange={false}
                              validateOnBlur={false}
                              enableReinitialize
                         >
                              {({ values, errors, handleChange, handleSubmit, isSubmitting, setFieldValue, setFieldError }) => {
                                   const handleChangeName = (e) => {
                                        setFieldValue('name', e.target.value);
                                        setFieldError('name', undefined);
                                   };

                                   return (
                                        <CForm onSubmit={handleSubmit} noValidate>
                                             <CFormGroup>
                                                  <CInput
                                                       innerRef={nameInputRef}
                                                       id="name"
                                                       placeholder="Enter a name"
                                                       invalid={!!errors.name}
                                                       required
                                                       disabled={isSubmitting}
                                                       value={values.name}
                                                       onChange={handleChangeName}
                                                  />

                                                  <CInvalidFeedback>{errors.name}</CInvalidFeedback>
                                             </CFormGroup>

                                             <CFormGroup>
                                                  <CTextarea
                                                       id="description"
                                                       placeholder="Provide an optional description."
                                                       disabled={isSubmitting}
                                                       value={values.description}
                                                       onChange={handleChange}
                                                  />
                                             </CFormGroup>

                                             <CFormGroup>
                                                  <CLabel className="mr-5 d-block" style={{fontWeight: '500'}}>
                                                       Who sees this {isSaveReport ? 'report' : 'view'}?
                                                  </CLabel>
                                                  {isSetPrivate && !isSaveReport &&
                                                       (savedReport.length > 0 || isDefault ? (
                                                            <CTooltip
                                                                 placement="left"
                                                                 content={`${savedReport.length > 0 ? `You cannot change this View to be private because it is used in the following Saved ${
                                                                     savedReport.length === 1 ? 'Report:' : 'Reports:'
                                                                 } ${getListNameSavedReport(savedReport)}${isDefault ? ' and is currently pinned' : ''}.` : ''}
                                                       
                                                                 ${savedReport.length === 0 && isDefault? 'You cannot make this View private because it is currently pinned. Please unpin it to proceed with your action!' : ''}`}
                                                            >
                                                                 <CFormGroup variant="custom-radio" className="d-block" inline>
                                                                      <CInputRadio
                                                                           custom
                                                                           id="isAvailable1"
                                                                           name="isAvailable"
                                                                           disabled={true}
                                                                           value={!values.isAvailable}
                                                                           checked={!values.isAvailable}
                                                                           onChange={(e) => setFieldValue('isAvailable', false)}
                                                                      />
                                                                      <CLabel variant="custom-checkbox" htmlFor="isAvailable1">
                                                                           Only I can see this {isSaveReport ? 'report' : 'view'}.
                                                                      </CLabel>
                                                                 </CFormGroup>
                                                            </CTooltip>
                                                       ) : (
                                                            <CFormGroup variant="custom-radio" className="d-block" inline>
                                                                 <CInputRadio
                                                                      custom
                                                                      id="isAvailable1"
                                                                      name="isAvailable"
                                                                      disabled={isSubmitting}
                                                                      value={!values.isAvailable}
                                                                      checked={!values.isAvailable}
                                                                      onChange={(e) => setFieldValue('isAvailable', false)}
                                                                 />
                                                                 <CLabel variant="custom-checkbox" htmlFor="isAvailable1">
                                                                      Only I can see this {isSaveReport ? 'report' : 'view'}.
                                                                 </CLabel>
                                                            </CFormGroup>
                                                       ))}

                                                  <CFormGroup variant="custom-radio" className="d-block" inline>
                                                       <CInputRadio
                                                            custom
                                                            id="isAvailable2"
                                                            name="isAvailable"
                                                            disabled={isSubmitting}
                                                            value={values.isAvailable}
                                                            checked={!!values.isAvailable}
                                                            onChange={(e) => setFieldValue('isAvailable', true)}
                                                       />

                                                       <CLabel variant="custom-checkbox" htmlFor="isAvailable2">
                                                            All users can see this {isSaveReport ? 'report' : 'view'}.
                                                       </CLabel>
                                                  </CFormGroup>
                                             </CFormGroup>
                                             {isSaveReport && (
                                                  <DateRangeConfig
                                                       values={values}
                                                       errors={errors}
                                                       setFieldValue={setFieldValue}
                                                       setFieldError={setFieldError}
                                                       reportName={reportName}
                                                       scoped={scoped}
                                                       data={data}
                                                  />
                                             )}


                                             <div className="form-actions text-center mt-4">
                                                  <CButton type="submit" className="btn-primary" disabled={isSubmitting}>
                                                       {isSubmitting ? (
                                                            <>
                                                                 Waiting<span className="dots-waiting"></span>
                                                            </>
                                                       ) : (
                                                            'Save'
                                                       )}
                                                  </CButton>

                                                  <CButton className="btn btn-cancel rounded-pill cancel" onClick={onCancel}>
                                                       Cancel
                                                  </CButton>
                                             </div>

                                        </CForm>
                                   );
                              }}
                         </Formik>
                    </CModalBody>
               </CModal>
          </div>
     );
};

export default CreateNewView;
