import React, { useContext, useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import { useSelector } from 'react-redux';
import { CButton, CCard, CCardBody } from '@coreui/react';
import { callTokenApi } from '../../../../../../apiCaller';
import {
     API_CLIENT_DESTINATIONS_GTM_CHANGES_SWITCH_CLIENT_TO_SERVER,
     API_CLIENT_DESTINATIONS_GTM_CHANGES_SWITCH_SERVER_TO_CLIENT,
     GTM_ACTIONS,
     SWITCH_TYPE_WIZARD_STEP,
} from '../../../../../../constants';
import { useActiveDestination, useCancelToken } from '../../../../../../helpers/customHooks';
import { toastError } from '../../../../../../utils';
import CenterSpinner from '../../../../../general/Loadings/CenterSpinner';
import { Popup } from '../../../../../general/popup';
import { SwitchTypeWizardBody, SwitchTypeWizardContext } from '../SwitchTypeWizard';
import CLabel from '../../../../../migration/CLabel';
import CFormGroup from '../../../../../migration/CFormGroup';
import { CInputRadio } from '../../../../../migration/CInput';

const GTMVerifyChanges = () => {
     const {
          setCurrentStep,
          closeWizard,
          stepsData,
          setStepsData,
          destinationName,
          isGTM,
          handleUpdateSwitchType,
          handleReloadData,
          objectsData,
          nothingObjectTurnOn,
     } = useContext(SwitchTypeWizardContext);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const accountDestination = useSelector((state) => state.subscriber.accountDestination);
     const getCancelToken = useCancelToken();
     const activeDestination = useActiveDestination();
     const initUsingType = (accountDestination && accountDestination.usingType) || 'gtm';
     const { currentContainer } = activeAccount.gtmInfo;
     const { verifyChanges, gtmHasUnpublishedChanges, doPublish, workspaceName, gtmHasLLWorkspace } = stepsData;

     const [isLoading, setIsLoading] = useState(true);
     const [showLearnMorePopup, setShowLearnMorePopup] = useState(false);
     const [isPublishLearnMoreClicked, setIsPublishLearnMoreClicked] = useState(false);

     const toggleLearnMorePopup = (isPublish) => {
          setIsPublishLearnMoreClicked(isPublish);
          setShowLearnMorePopup((show) => !show);
     };

     const completeActions = () => {
          // handleUpdateSwitchType({ initStep: SWITCH_TYPE_WIZARD_STEP.getStarted });
          setCurrentStep(SWITCH_TYPE_WIZARD_STEP.gtmMakeChanges);
     };

     const onPublishRadioChanged = (e) => {
          setStepsData({ doPublish: e.target.value === 'yes' });
     };

     // Client -> Server
     const handleSwitchClientToServer = () => {
          const postData = {
               accountId: activeAccount.id,
               container: {
                    containerId: currentContainer.containerId,
                    path: currentContainer.path,
                    publicId: currentContainer.publicId,
               },
               action: GTM_ACTIONS.VERIFY_CHANGES,
               destinationId: activeDestination.id,
          };
          callTokenApi(API_CLIENT_DESTINATIONS_GTM_CHANGES_SWITCH_CLIENT_TO_SERVER, 'POST', postData, getCancelToken())
               .then((response) => {
                    if (response) {
                         if (response.status === 200) {
                              const { changes, gtmHasUnpublishedChanges, workspaceName, workspacePath, gtmHasLLWorkspace } = response.data;
                              const newStepsData = {
                                   verifyChanges: changes,
                                   gtmHasUnpublishedChanges,
                                   workspaceName,
                                   workspacePath,
                                   gtmHasLLWorkspace,
                              };

                              setStepsData(newStepsData);
                         } else {
                              toastError(response);

                              if (response.data.noWritePermission) {
                                   closeWizard();
                              }
                         }
                    }
               })
               .finally(() => {
                    setIsLoading(false);
               });
     };

     // Server -> Client
     const handleSwitchServerToClient = () => {
          const postData = {
               accountId: activeAccount.id,
               container: {
                    containerId: currentContainer.containerId,
                    path: currentContainer.path,
                    publicId: currentContainer.publicId,
               },
               action: GTM_ACTIONS.VERIFY_CHANGES,
               objectsData,
               initUsingType,
               destinationId: activeDestination.id,
               accountDestinationId: activeDestination.accountDestinationId,
               isVerify: true,
          };
          callTokenApi(API_CLIENT_DESTINATIONS_GTM_CHANGES_SWITCH_SERVER_TO_CLIENT, 'POST', postData, getCancelToken())
               .then((response) => {
                    if (response) {
                         if (response.status === 200) {
                              const { changes, gtmHasUnpublishedChanges, workspaceName, workspacePath, gtmHasLLWorkspace } = response.data;
                              const newStepsData = {
                                   verifyChanges: changes,
                                   gtmHasUnpublishedChanges,
                                   workspaceName,
                                   workspacePath,
                                   gtmHasLLWorkspace,
                              };

                              setStepsData(newStepsData);
                         } else {
                              toastError(response);

                              if (response.data.noWritePermission) {
                                   closeWizard();
                              }
                         }
                    }
               })
               .finally(() => {
                    setIsLoading(false);
               });
     };

     const handleFirstLoad = () => {
          if (verifyChanges.length === 0) {
               if (isGTM) {
                    handleSwitchServerToClient(); // Server -> Client
               } else {
                    handleSwitchClientToServer(); // Client -> Server
               }
          } else {
               setIsLoading(false);
          }
     };
     useEffect(handleFirstLoad, []); // eslint-disable-line react-hooks/exhaustive-deps

     const goToNextStep = () => {
          // handleUpdateSwitchType({ initStep: SWITCH_TYPE_WIZARD_STEP.getStarted });
          setCurrentStep(SWITCH_TYPE_WIZARD_STEP.gtmSuccess);
          handleReloadData();
     };

     const handleSaveNoChange = () => {
          setTimeout(() => {
               goToNextStep();
          }, 500)

          if (verifyChanges.length === 0) {
               const newValues = { initStep: SWITCH_TYPE_WIZARD_STEP.getStarted };

               if (isGTM) {
                    newValues['deleteRedisSwitchServerToClient'] = true;
               }

               handleUpdateSwitchType(newValues);
          }
     };

     return (
          <SwitchTypeWizardBody shouldHideBackBtn={true}>
               <CCard className="account-script verify-changes">
                    <CCardBody>
                         {isLoading ? (
                              <CenterSpinner />
                         ) : verifyChanges.length > 0 ? (
                              <>
                                   <h3>{isGTM ? `Objects to Send to GTM` : `We'll Remove These Objects`}</h3>
                                   <p className="des mb-4">
                                        Here is a list of the specific objects {isGTM ? `we need to send to GTM.` : `we'll remove from GTM.`}
                                   </p>
                                   <p className="des mb-3">
                                        You can choose whether to publish the changes. We'll create a new version; you can always revert inside GTM.
                                   </p>
                                   <div className="list-action">
                                        {verifyChanges.map((change, i) => {
                                             const sanitizedChange = DOMPurify.sanitize(change);
                                             return <div key={i} className="action" dangerouslySetInnerHTML={{ __html: sanitizedChange }}></div>;
                                        })}
                                   </div>
                                   <div className="radio-group">
                                        <CFormGroup variant="custom-radio">
                                             <CInputRadio
                                                  id="publish-radio1"
                                                  name="publishRadios"
                                                  value="yes"
                                                  onChange={onPublishRadioChanged}
                                                  checked={stepsData.doPublish}
                                             />
                                             <CLabel htmlFor="publish-radio1">Publish the changes in GTM.</CLabel>
                                             <CButton type="button" className="btn-learn-more" onClick={() => toggleLearnMorePopup(true)}>
                                                  Learn more<i className="fal fa-angle-right"></i>
                                             </CButton>
                                        </CFormGroup>
                                        {isGTM && (
                                             <CFormGroup variant="custom-radio">
                                                  <CInputRadio
                                                       id="publish-radio2"
                                                       name="publishRadios"
                                                       value="no"
                                                       onChange={onPublishRadioChanged}
                                                       checked={typeof stepsData.doPublish === 'boolean' && !stepsData.doPublish}
                                                  />
                                                  <CLabel htmlFor="publish-radio2">Don't publish the changes.</CLabel>
                                                  <CButton type="button" className="btn-learn-more" onClick={() => toggleLearnMorePopup(false)}>
                                                       Learn more<i className="fal fa-angle-right"></i>
                                                  </CButton>
                                             </CFormGroup>
                                        )}
                                   </div>
                                   <CButton
                                        type="button"
                                        className="btn-save"
                                        onClick={completeActions}
                                        disabled={typeof doPublish === 'undefined' && verifyChanges.length > 0}
                                   >
                                        COMPLETE THESE ACTIONS
                                   </CButton>
                                   <CButton className="btn-navigation-step" onClick={goToNextStep}>
                                        Skip this step
                                   </CButton>
                              </>
                         ) : (
                              <>
                                   <h3>{isGTM ? `We found nothing to create` : `We Found Nothing to Remove`}</h3>
                                   <p className="des mb-0">
                                        {isGTM
                                             ? nothingObjectTurnOn
                                                  ? `We haven't found any ${destinationName} objects turned on and nothing needed to be created.`
                                                  : `We found that all ${destinationName} objects existed inside GTM and nothing needed to be created.`
                                             : `We haven't found any ${destinationName} objects we created inside GTM that need to be removed.`}{' '}
                                        However, we recommend that you manually review your website and GTM to ensure now client-side{' '}
                                        {destinationName} scripts are loading.
                                   </p>
                                   <CButton type="button" className="btn-save" onClick={handleSaveNoChange}>
                                        Next
                                   </CButton>
                              </>
                         )}
                    </CCardBody>
               </CCard>
               <Popup
                    className="learn-more account-script-popup"
                    showHeader={true}
                    show={showLearnMorePopup}
                    onClose={() => setShowLearnMorePopup(false)}
               >
                    {gtmHasUnpublishedChanges ? (
                         isPublishLearnMoreClicked ? (
                              <>
                                   <p>
                                        We'll publish our changes to ensure everything is live on your website. This will create a new version in GTM
                                        which will allow you to easily revert if needed.
                                   </p>
                                   {gtmHasLLWorkspace ? (
                                        <p>
                                             We found an unpublished workspace called <strong>{workspaceName}</strong> that you've been using to push
                                             changes into. We'll make these changes to that workspace and publish the entire workspace.
                                        </p>
                                   ) : (
                                        <p>
                                             We did find that your container already has some unpublished changes, and we don't want to publish those.
                                             As a result, we'll create a unique workspace so that only our changes will be published. You can then
                                             merge these changes into your other workspace at some point in the future.
                                        </p>
                                   )}
                              </>
                         ) : (
                              <>
                                   <p>
                                        We'll create the elements inside your GTM account, and you'll be required to publish them manually. We
                                        recommend publishing soon to ensure that ListenLayer and GTM remain in sync.
                                   </p>
                                   {gtmHasLLWorkspace ? (
                                        <p>
                                             We found an unpublished workspace called <strong>{workspaceName}</strong> that you've been using to push
                                             changes into. We'll make these changes to that workspace but it won't be published - you'll need to
                                             review the changes inside GTM and publish the workspace there.
                                        </p>
                                   ) : (
                                        <p>
                                             We did find that your container already has some unpublished changes, and we don't want to publish those.
                                             As a result, we'll create a unique workspace so that only these changes can be isolated. Review the new
                                             workspace and publish it when you are ready.
                                        </p>
                                   )}
                              </>
                         )
                    ) : isPublishLearnMoreClicked ? (
                         <>
                              <p>
                                   We'll publish our changes to ensure everything is live on your website. This will create a new version in GTM which
                                   will allow you to easily revert if needed.
                              </p>
                              <p>
                                   We've checked your GTM account and found that there are no other unpublished changes in the container, which means
                                   only our changes are going live.
                              </p>
                         </>
                    ) : (
                         <>
                              <p>
                                   We'll create the elements inside your GTM account, and you'll be required to publish them manually. We recommend
                                   publishing soon to ensure that ListenLayer and GTM remain in sync.
                              </p>
                              <p>
                                   We've checked your GTM account and found that there are no other unpublished changes in the container, which means
                                   only our changes are going live.
                              </p>
                         </>
                    )}
               </Popup>
          </SwitchTypeWizardBody>
     );
};

export default GTMVerifyChanges;
