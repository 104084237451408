import React, { useContext, useMemo, useState } from 'react';
import uniqBy from 'lodash/uniqBy';
import { CRow } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import { camelCase, draftSessionStorageReport, encodeObj, getOriginalField, getSegmentField } from '../../../../utils';
import { EventExplorerContext } from './EventExplorer';
import { useSelector } from 'react-redux';
import { DEFAULT_DATE_FORMAT, REPORT_DATA_TYPES, REPORT_NAMES, REPORT_TAB_TYPES, SUBSCRIBER_PATH, VARIABLE_OPERATORS_DIMENSION_REPORT } from '../../../../constants';
import { convertNameSegmentSort, formatNumber, handleChangeCol, handleChangeColSort, isNumber } from '../../../../utils/UtilReport';
import { LoadingPanel } from './report-v2/LoadingTemplate';
import CustomHeaderCell from './report-v2/grid/CustomHeaderCell';
import CSwitch from '../../../migration/CSwitch';
import { CSmartPagination } from '@coreui/react-pro';
import { TableCellValueUndefined } from './report-v2/FieldCustom';

const ReportTableCompare = (props) => {
     const {
          reportName,
          filterDimensions,
          setFilterDimensions,
          filterProperties,
          setFilterProperties,
          isLoading,
          scoped,
          reportType,
          reportData,
          reportCompare,
          itemsPerPage,
          setItemsPerPage,
          dateRangeConfig,
          conversionNameMetric,
          filterMetric,
          setFilterMetric,
          setOffSet,
          eCoItemCategories,
          utmArray,
          weight,
          handleFindView,
          listSegmentReportApply,
          tempScopedReport,
          enableRevealedCompanies,
          showData
     } = useContext(EventExplorerContext);
     let { dimensions, metrics } = reportType ? REPORT_DATA_TYPES[reportName][scoped] : REPORT_DATA_TYPES[reportName];
     if (reportName === REPORT_NAMES.CONVERSION_SOURCE_ATTRIBUTION_REPORT) {
          dimensions = [...dimensions, ...utmArray];
     }
     if (reportName === REPORT_NAMES.ECOMMERCE_ITEMS) {
          dimensions = [...dimensions, ...eCoItemCategories];
     }
     const newDimensions = handleChangeCol(dimensions, filterDimensions);
     const newMetric = handleChangeColSort([...conversionNameMetric, ...metrics], filterMetric);
     const widthColumnReports = useSelector((state) => state.subscriber.widthColumnReports);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const dateRangeReports = useSelector((state) => state.subscriber.dateRangeReports);

     const {
          getWidthByKey,
          tableGrid,
          handleColumnResize,
          lock,
          activePage,
          setActivePage,
          arrShowShortIds,
          showLock,
          showShortId,
          sort,
          disableSort,
          setLock,
          setShowShortId,
     } = props;
     const [pagination, setPagination] = useState(true);
     const totalPages = Math.ceil(reportData.overallRowCount / itemsPerPage) || 1;

     // const tableCompare = useMemo(() => {
     //      // Transform reportData.rows into suitable data for CDataTable
     //      // Each row will be {key1: value1, key2: value2, ...}
     //      let newDataMetric = []

     //      if (listSegmentReportApply.length > 0) {
     //           newMetric.forEach((i) => {
     //                listSegmentReportApply.forEach((item) => {
     //                     newDataMetric.push({
     //                          key: getSegmentField(item.id, i.key),
     //                          isDefault: i.isDefault,
     //                          label: i.label,
     //                     });
     //                });
     //           });
     //      } else {
     //           newDataMetric = [...newMetric];
     //      }
     //      if (dateRangeConfig.comparePeriod) {
     //           let returnRows = [];
     //           if (reportCompare.rows) {
     //                reportCompare.rows.forEach((row) => {
     //                     const dimensionValue = row.dimensionCompoundValues;
     //                     Object.entries(row).forEach((value) => {
     //                          let fieldValues = {};
     //                          switch (value[0]) {
     //                               case 'dimensionCompoundValues':
     //                                    fieldValues = value[1].reduce((prevVal, currentVal, i) => {
     //                                         if (i <= newDimensions.length - 1) {
     //                                              return { ...prevVal, [newDimensions[i].key]: currentVal.value };
     //                                         } else {
     //                                              return {};
     //                                         }
     //                                    }, {});
     //                                    returnRows.push(fieldValues);
     //                                    break;
     //                               case 'metricCompoundValues':
     //                                    Object.entries(value[1]).forEach((item) => {
     //                                         let dateValue = { date: item[0] };
     //                                         let fieldDimension = dimensionValue.reduce((prevVal, currentVal, i) => {
     //                                              if (i <= newDimensions.length - 1) {
     //                                                   return { ...prevVal, [newDimensions[i].key]: currentVal.value };
     //                                              } else {
     //                                                   return {};
     //                                              }
     //                                         }, {});
     //                                         let dimensions = { dimension: fieldDimension };
     //                                         fieldValues = item[1].reduce((prevVal, currentVal, i) => {
     //                                              if (i <= newDataMetric.length - 1) {
     //                                                   return { ...prevVal, [newDataMetric[i].key]: currentVal.value };
     //                                              } else {
     //                                                   return {};
     //                                              }
     //                                         }, {});
     //                                         returnRows.push({ ...fieldValues, ...dateValue, ...dimensions });
     //                                    });
     //                                    break;
     //                               case 'propertyCompoundValues':
     //                                    break;
     //                               default:
     //                                    break;
     //                          }
     //                     });
     //                });
     //                return returnRows;
     //           }
     //      }
     // }, [reportCompare.rows]); // eslint-disable-line

     const tableData = useMemo(() => {
          if (dateRangeConfig.comparePeriod) {
               let returnRows = reportData.rows.map((row) =>
                    Object.entries(row).reduce((newRow, [compoundKey, compoundValue]) => {
                         let fieldValues = [];
                         let keyCompound;

                         switch (compoundKey) {
                              case 'dimensionCompoundValues':
                                   keyCompound = newDimensions;
                                   break;
                              case 'metricCompoundValues':
                                   let newDataMetric = [];

                                   if (listSegmentReportApply.length > 0) {
                                        newMetric.forEach((i) => {
                                             listSegmentReportApply.forEach((item) => {
                                                  newDataMetric.push({
                                                       key: getSegmentField(item.id, i.key),
                                                       isDefault: i.isDefault,
                                                       label: i.label,
                                                  });
                                                  newDataMetric.push({
                                                       key: `${getSegmentField(item.id, i.key)}Compare`,
                                                       isDefault: i.isDefault,
                                                       label: i.label,
                                                  });
                                             });
                                        });
                                   } else {
                                        newMetric.forEach((i) => {
                                             newDataMetric.push({
                                                  key: i.key,
                                                  isDefault: i.isDefault,
                                                  label: i.label,
                                             });
                                             newDataMetric.push({
                                                  key: `${i.key}Compare`,
                                                  isDefault: i.isDefault,
                                                  label: i.label,
                                             });
                                        });
                                   }

                                   keyCompound = newDataMetric;
                                   break;
                              case 'propertyCompoundValues':
                                   break;
                              default:
                         }
                         // Merge all compoundValues into 1 object
                         fieldValues = compoundValue.reduce((prevVal, currentVal, i) => {
                              if (i <= keyCompound.length - 1) {
                                   return { ...prevVal, [keyCompound[i].key]: currentVal.value };
                              } else {
                                   return { ...prevVal };
                              }
                         }, {});
                         return { ...newRow, ...fieldValues };
                    }, {})
               );
               return returnRows
          }

     }, [reportCompare.rows]); // eslint-disable-line

     const handleChangePagination = () => {
          setPagination(!pagination);
          setActivePage(1);
          setOffSet(0);
     };

     const handleLinkToOtherReport = (fieldKey, linkedData, field) => {
          let path = '';
          let linkedReportData;
          let filters = [];
          let startDate, endDate;
          let tempScoped = { tempScoped:'' };

          if(field.includes('Compare')){
               startDate = dateRangeReports.compare && dateRangeReports.compare.startDate.format(DEFAULT_DATE_FORMAT)
               endDate = dateRangeReports.compare && dateRangeReports.compare.endDate.format(DEFAULT_DATE_FORMAT)
          } else {
               startDate = dateRangeReports.selection && dateRangeReports.selection.startDate.format(DEFAULT_DATE_FORMAT)
               endDate = dateRangeReports.selection && dateRangeReports.selection.endDate.format(DEFAULT_DATE_FORMAT)
          }

          if(dateRangeReports.selection && dateRangeReports.selection.startDate.format(DEFAULT_DATE_FORMAT) === startDate){
               tempScoped.tempScoped = tempScopedReport.tempScoped
          }

          if (dateRangeReports.compare && dateRangeReports.compare.startDate.format(DEFAULT_DATE_FORMAT) === startDate) {
               tempScoped.tempScoped = tempScopedReport.tempScopedCompare
          }

          const segmentClick = field && listSegmentReportApply && listSegmentReportApply.find((item) => field.includes(camelCase(item.id)));
          const linkObject = {
               reportName: reportName,
               scoped: scoped,
               optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
               dateScope: dateRangeConfig.dateScope,
               dateRange: {
                    startDate,
                    endDate,
               },
          }

          switch (fieldKey) {
               case 'sessionCount':
                    path = SUBSCRIBER_PATH.INSIGHTS_SESSIONS;
                    newDimensions.forEach((item) => {
                         filters.push({
                              ...linkObject,
                              type: item.key,
                              value: `${linkedData[item.key]}`,
                              operator: 'eq',
                              clickable: true,
                              utmArray: utmArray.filter((el) => el.key === item.key),
                              weight: weight,
                              tempScopedReport: tempScoped,
                         });
                    });
                    break;

               case 'userCount':
                    path = SUBSCRIBER_PATH.INSIGHTS_USERS;
                    newDimensions.forEach((item) => {
                         filters.push({
                              ...linkObject,
                              type: item.key,
                              value: `${linkedData[item.key]}`,
                              operator: 'eq',
                              clickable: true,
                              tempScopedReport: tempScoped,
                         });
                    });

                    break;

               case 'peopleCount':
                    path = SUBSCRIBER_PATH.INSIGHTS_PEOPLE;
                    newDimensions.forEach((item) => {
                         filters.push({
                              ...linkObject,
                              type: item.key,
                              value: `${linkedData[item.key]}`,
                              operator: 'eq',
                              clickable: true,
                              tempScopedReport: tempScoped,
                         });
                    });

                    break;

               case 'revealedCompany':
                    path = SUBSCRIBER_PATH.INSIGHTS_REVEALED_COMPANIES;
                    newDimensions.forEach((item) => {
                         filters.push({
                              ...linkObject,
                              type: item.key,
                              value: `${linkedData[item.key]}`,
                              operator: 'eq',
                              clickable: true,
                              tempScopedReport: tempScoped,
                         });
                    });

                    break;
               default:
          }

          linkedReportData = {
               filters,
               dateRange: {
                    startDate,
                    endDate,
               },
               dateScope: dateRangeConfig.dateScope,
               segmentId: segmentClick && segmentClick.id,
          };
          draftSessionStorageReport({
               accountId: activeAccount.id, reportName, fieldKey, isLink: true, isCompare: true, linkedReportData
          })
          path = path.replace(':secondId', activeAccount.secondId) + '?data=' + encodeObj({
               accountId: activeAccount.id, reportName, fieldKey, isCompare: true
          });
          window.open(path, '_blank');
     };

     function CustomCellCompare(props, index) {
          const value = props.dataItem[props.field];
          const noFormat = ['itemId', 'transactionId', 'phone', 'utmContent', 'utm_content', 'utmTerm', 'utmCampaign', 'utmMedium', 'utmSource', 'postalCodeCustom']
          const { style, className, id } = props;

          if (!showData && (props.dataIndex > 4 || tableData.length < 5)) {
               let blur = 4;
               if (tableData.length < 5) {
                    blur = -1;
               }
               const currentIndex = props.dataIndex < 8 ? props.dataIndex : 8;

               return <td style={{ filter: `blur(${currentIndex - blur}px)`}} >{formatNumber(value)} </td>;
          }

          if ([REPORT_NAMES.COMPANY_DETAILS, REPORT_NAMES.PEOPLE_AND_USER_COUNT_REPORT].includes(reportName)) {
               if (!enableRevealedCompanies && (props.dataIndex > 4 || tableData.length < 5)) {
                    let blur = 4;
                    if (tableData.length < 5) {
                         blur = -1;
                    }
                    const currentIndex = props.dataIndex < 8 ? props.dataIndex : 8;
                    
                    return <td style={{ filter: `blur(${currentIndex - blur}px)` }} >{formatNumber(value)} </td>;
               }
          }

          if (value === undefined) {
               const hasOwnProperty = props.dataItem.hasOwnProperty(props.field);
               return TableCellValueUndefined(isLoading, hasOwnProperty);
          } else {
               return typeof value === 'boolean' || noFormat.includes(props.field) ? (
                    <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
                         <span>{String(value)}</span>
                    </td>
               ) : (
                    <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
                         {' '}
                         {`${formatNumber(value)}`}
                    </td>
               );
          }
     }

     const handleEnterToLink = (e) => {
          if (e.which === 13) e.currentTarget.click();
     };

     const getFieldData = (data, field, fieldName) => {
          if (field.includes('Compare')) {
               fieldName = fieldName + 'Compare';
          }
          if (typeof data[fieldName] !== 'undefined') {
               return data[fieldName];
          }
          return data[`${field.split('_')[0]}_${fieldName}`];
     };
     
     const SessionCount = (props, handleEnterToLink, handleLinkToOtherReport, reportName) => {
          const { style, className, id, field, dataItem } = props;
          const sessionCount = getFieldData(dataItem, field, 'sessionCount');
          if (sessionCount > 0 && ![REPORT_NAMES.BY_CONVERSION,REPORT_NAMES.BY_PROPERTY].includes(reportName))
               return (
                    <td
                         style={style}
                         className={`clickable ${className}`}
                         tabIndex={0}
                         data-keyboardnavid={id}
                         onKeyPress={(e) => handleEnterToLink(e)}
                         onClick={() => {
                              return handleLinkToOtherReport('sessionCount', { ...dataItem }, field);
                         }}
                    >
                         {`${formatNumber(sessionCount)}`}
                    </td>
               );
          else
               return (
                    <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
                         {sessionCount ? formatNumber(sessionCount) : 0}{' '}
                    </td>
               );
     };

     const UserCount = (props, handleEnterToLink, handleLinkToOtherReport, reportName) => {
          const { style, className, id, field, dataItem } = props;
          const userCount = getFieldData(dataItem, field, 'userCount');
          if (userCount > 0 && ![REPORT_NAMES.BY_CONVERSION,REPORT_NAMES.BY_PROPERTY].includes(reportName)) {
               return (
                    <td
                         style={style}
                         className={`clickable ${className}`}
                         tabIndex={0}
                         data-keyboardnavid={id}
                         onKeyPress={(e) => handleEnterToLink(e)}
                         onClick={() => {
                              return handleLinkToOtherReport('userCount', { ...dataItem }, field);
                         }}
                    >
                         {`${formatNumber(userCount)}`}
                    </td>
               );
          } else {
               return (
                    <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
                         {userCount ? formatNumber(userCount) : 0}{' '}
                    </td>
               );
          }
     };

     const PeopleCount = (props, handleEnterToLink, handleLinkToOtherReport) => {
          const { style, className, id, field, dataItem } = props;
          const peopleCount = getFieldData(dataItem, field, 'peopleCount');
          if (peopleCount > 0) {
               return (
                    <td
                         style={style}
                         className={`clickable ${className}`}
                         tabIndex={0}
                         data-keyboardnavid={id}
                         onKeyPress={(e) => handleEnterToLink(e)}
                         onClick={() => {
                              return handleLinkToOtherReport('peopleCount', { ...dataItem }, field);
                         }}
                    >
                         {`${formatNumber(peopleCount)}`}
                    </td>
               );
          } else {
               return (
                    <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
                         {peopleCount ? formatNumber(peopleCount) : 0}{' '}
                    </td>
               );
          }
     };

     const RevealedCompany = (props, handleEnterToLink, handleLinkToOtherReport) => {
          const { style, className, id, field, dataItem } = props;
          const revealedCompany = getFieldData(dataItem, field, 'revealedCompany');
          if (revealedCompany > 0) {
               return (
                    <td
                         style={style}
                         className={`clickable ${className}`}
                         tabIndex={0}
                         data-keyboardnavid={id}
                         onKeyPress={(e) => handleEnterToLink(e)}
                         onClick={() => {
                              return handleLinkToOtherReport('revealedCompany', { ...dataItem }, field);
                         }}
                    >
                         {`${formatNumber(revealedCompany)}`}
                    </td>
               );
          } else {
               return (
                    <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
                         {revealedCompany ? formatNumber(revealedCompany) : 0}{' '}
                    </td>
               );
          }
     };

     function CustomCellCompareValue({ props, color }) {
          const originalField = getOriginalField(props.field).replace('Compare', '');

          const value = props.dataItem[props.field];
          let { style, className, id } = props;

          if (color) {
               className = className + ` compare-${color} `
          }

          if (value === undefined) {
               const hasOwnProperty = props.dataItem.hasOwnProperty(props.field);
               return TableCellValueUndefined(isLoading, hasOwnProperty);
          }

          if (!showData && (props.dataIndex > 4 || tableData.length < 5)) {
               let blur = 4;
               if (tableData.length < 5) {
                    blur = -1;
               }
               const currentIndex = props.dataIndex < 8 ? props.dataIndex : 8;

               return <td style={{ filter: `blur(${currentIndex - blur}px)`}} className={className}>{formatNumber(value)} </td>;
          }

          if ([REPORT_NAMES.COMPANY_DETAILS, REPORT_NAMES.PEOPLE_AND_USER_COUNT_REPORT].includes(reportName)) {
               if (!enableRevealedCompanies && (props.dataIndex > 4 || tableData.length < 5)) {
                    let blur = 4;
                    if (tableData.length < 5) {
                         blur = -1;
                    }
                    const currentIndex = props.dataIndex < 8 ? props.dataIndex : 8;
                    
                    return <td style={{ filter: `blur(${currentIndex - blur}px)` }} className={className}>{formatNumber(value)} </td>;
               }
          }

          if (props.field.includes('Rate')) {
               return <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>{`${formatNumber(value)}%`}</td>;
          }

          switch (originalField) {
               case 'avgSessionDuration':
               case 'sessionDuration':
                    return (
                         <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
                              {value}
                         </td>
                    );
               case 'avgVisibleTimeOnPage':
                    return (
                         <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
                              {value ? value : 0}
                         </td>
                    );
               case 'avgVisibleTimePerPage':
                    return (
                         <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
                              {value}
                         </td>
                    );

               case 'sessionCount':
                    return SessionCount({...props, className}, handleEnterToLink, handleLinkToOtherReport, reportName);

               case 'userCount':
                    return UserCount({...props, className}, handleEnterToLink, handleLinkToOtherReport, reportName);

               case 'peopleCount':
                    return PeopleCount({ ...props, className }, handleEnterToLink, handleLinkToOtherReport);

               case 'revealedCompany':
                    return RevealedCompany({ ...props, className }, handleEnterToLink, handleLinkToOtherReport);

               default:
                    return typeof value === 'boolean' ? (
                         <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
                              <span>{String(value)}</span>
                         </td>
                    ) : (
                         <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
                              {' '}
                              {`${formatNumber(value)}`}
                         </td>
                    );
          }
     }

     const getTotalCellCompare = (field) => {
          const totalValue = reportCompare.totalsRow.totalNor;
          const totalCompare = reportCompare.totalsRow.totalCompare;
          let total
          if ((totalValue && totalValue.length > 0) || (totalCompare && totalCompare.length > 0)) {
               const fieldDuration = field.includes('Duration') || field.includes('VisibleTime');
               const totalCell = totalValue ? totalValue[0].metricCompoundValues.find((item) => item.key === field) : { value: !fieldDuration ? 0 : `0m 0s` };
               const totalCellCompare = totalCompare ? totalCompare[0].metricCompoundValues.find((item) => item.key === field.replace('Compare', '')) : { value: !fieldDuration ? 0 : `0m 0s` };
               total = field.includes('Compare') ? totalCellCompare : totalCell
               if (total) {
                    if (!isNumber(total.value)) {
                         return `${total.value}`;
                    } else if (field.includes('Rate')) {
                         return `${formatNumber(total.value)}%`;
                    } else {
                         return `${formatNumber(total.value)}`;
                    }
               }
          }
     };

     const onChangeItemsPerPage = (e) => {
          setActivePage(1);
          setOffSet(0);
          setItemsPerPage(+e.target.value);
     };

     const onPaginationChange = (i) => {
          setOffSet((i - 1) * itemsPerPage)
          setActivePage(i);
     };

     const mergeMetrics = useMemo(() => [...filterMetric], [filterMetric])
     const uniqByMetrics = uniqBy(mergeMetrics, 'label');

     const handleColumnReorder = ({ columns, columnId }) => {
          columns.forEach((column) => {
               column.children.some((child) => {
                    if (child.id === columnId) {
                         const sortedChildren = [...column.children].sort((a, b) => a.orderIndex - b.orderIndex);
                         const filterChildren = sortedChildren.filter(_child => _child.title === child.title);

                         const beforeChildren = sortedChildren.filter(_child => _child.orderIndex < child.orderIndex && _child.title !== child.title);
                         const afterChildren = sortedChildren.filter(_child => _child.orderIndex > child.orderIndex && _child.title !== child.title);

                         const reorderedColumnsCustom = [...beforeChildren, ...filterChildren, ...afterChildren];

                         const reorderedColumns = reorderedColumnsCustom.map((child) => ({
                              label: child.title,
                              value: convertNameSegmentSort({ sort: { fieldName: child.field }, listSegmentReportApply }),
                         }));

                         switch (column.title) {
                              case 'Dimensions':
                                   setFilterDimensions(reorderedColumns);
                                   handleFindView(REPORT_TAB_TYPES.DIMENSIONS, reorderedColumns, false);
                                   break;
                              case 'Properties':
                                   setFilterProperties(reorderedColumns);
                                   handleFindView(REPORT_TAB_TYPES.PROPERTIES, reorderedColumns, false);
                                   break;
                              case 'Metrics':
                                   setFilterMetric(uniqBy(reorderedColumns, 'label'));
                                   handleFindView(REPORT_TAB_TYPES.METRICS, reorderedColumns, false);
                                   break;
                              default:
                                   break;
                         }

                         return true;
                    }

                    return false;
               });
          });
     };

     return (
          <>
               {isLoading && pagination && <LoadingPanel />}
               <Grid
                    ref={tableGrid}
                    style={{ width: 'fit-content', maxWidth: '100%', maxHeight: pagination ? '1500px' : '800px' }}
                    data={tableData.length === 0 && isLoading ? Array(5).fill({}) : tableData}
                    resizable={true}
                    reorderable={isLoading ? false : true}
                    navigatable={true}
                    scrollable="scrollable"
                    onColumnReorder={handleColumnReorder}
                    onColumnResize={handleColumnResize}
                    className="compare-report-table"
               // rowRender={isLoading && SkeletonLoader}
               >
                    <GridNoRecords>No results found</GridNoRecords>

                    {filterDimensions.length > 0 &&
                         <GridColumn orderIndex={0} title="Dimensions" resizable={false}>
                              {filterDimensions.map((item, index) => {
                                   return (
                                        <GridColumn
                                             key={index}
                                             title={item.label}
                                             field={item.value}
                                             orderIndex={index}
                                             width={getWidthByKey({
                                                  data: widthColumnReports,
                                                  reportType,
                                                  reportName,
                                                  label: item.label,
                                                  key: item.value,
                                                  tableData,
                                                  index
                                             })}
                                             headerCell={(props) => (
                                                  <CustomHeaderCell
                                                       props={props}
                                                       dimensions={dimensions}
                                                       arrShowShortIds={arrShowShortIds}
                                                       showLock={showLock}
                                                       lock={lock}
                                                       showShortId={showShortId}
                                                       sort={sort}
                                                       disableSort={disableSort}
                                                       setLock={setLock}
                                                       setShowShortId={setShowShortId}
                                                       filterMetric={filterMetric}
                                                       reportName={reportName}
                                                  />
                                             )}
                                             cell={(props) => CustomCellCompare(props, index)}
                                             locked={lock[item.value] ? true : false}
                                             footerCell={(props) => {
                                                  if (index + 1 === filterDimensions.length + filterProperties.length) {
                                                       return (
                                                            <td
                                                                 className={props.className}
                                                                 style={{ ...props.style, textAlign: 'right', borderLeft: 'none' }}
                                                            >
                                                                 Totals
                                                            </td>
                                                       );
                                                  } else {
                                                       return <td className={props.className} style={{ ...props.style, borderLeft: 'none' }}></td>;
                                                  }
                                             }}
                                        ></GridColumn>
                                   );
                              })}
                         </GridColumn>
                    }

                    {filterProperties.length > 0 &&
                         <GridColumn orderIndex={1} title="Properties" resizable={false}>
                              {filterProperties.map((item, index) => {
                                   return (
                                        <GridColumn
                                             key={index}
                                             title={item.label}
                                             field={item.value}
                                             orderIndex={index}
                                             width={getWidthByKey({
                                                  data: widthColumnReports,
                                                  reportType,
                                                  reportName,
                                                  label: item.label
                                             })}
                                             headerCell={(props) => (
                                                  <CustomHeaderCell
                                                       props={props}
                                                       dimensions={dimensions}
                                                       arrShowShortIds={arrShowShortIds}
                                                       showLock={showLock}
                                                       lock={lock}
                                                       showShortId={showShortId}
                                                       sort={sort}
                                                       disableSort={disableSort}
                                                       setLock={setLock}
                                                       setShowShortId={setShowShortId}
                                                       filterMetric={filterMetric}
                                                       reportName={reportName}
                                                  />
                                             )}
                                             cell={(props) => CustomCellCompareValue({ props })}
                                             locked={lock[item.value] ? true : false}
                                             footerCell={(props) => {
                                                  // if (colLockCount < filterProperties.length + filterProperties.length && index + 1 >= colLockCount) return <td></td>;
                                                  // else return '';
                                                  if (index + 1 === filterProperties.length) {
                                                       return (
                                                            <td
                                                                 className={props.className}
                                                                 style={{ ...props.style, textAlign: 'right', borderLeft: 'none' }}
                                                            >
                                                                 Totals
                                                            </td>
                                                       );
                                                  } else {
                                                       return <td className={props.className} style={{ ...props.style, borderLeft: 'none' }}></td>;
                                                  }
                                             }}
                                        ></GridColumn>
                                   );
                              })}
                         </GridColumn>
                    }

                    {mergeMetrics.length > 0 &&
                         (listSegmentReportApply.length > 0 ? (
                              <GridColumn orderIndex={2} title="Metrics" resizable={false}>
                                   {uniqByMetrics.flatMap((metric, metricIndex) => (
                                        listSegmentReportApply.map((seg, segIndex) => {
                                             const item = {
                                                  label: metric.label,
                                                  value: `${getSegmentField(seg.id, metric.value.split('_').pop())}`,
                                             }
                                             const orderIndex = metricIndex * listSegmentReportApply.length + (segIndex + 1);
                                             return (
                                                  <GridColumn
                                                       key={`${item.value}_${orderIndex}`}
                                                       title={item.label}
                                                       field={item.value}
                                                       orderIndex={orderIndex}
                                                       width={(getWidthByKey({
                                                            data: widthColumnReports,
                                                            reportType,
                                                            reportName,
                                                            label: item.label,
                                                            key: item.value,
                                                            tableData,
                                                            index: orderIndex,
                                                            isMetric: true,
                                                            newLabel: true
                                                       }) * 2) - 1}
                                                       headerCell={(props) => (
                                                            <div className="custom-header-segment d-flex align-items-center justify-content-between">
                                                                 <CustomHeaderCell
                                                                      props={props}
                                                                      dimensions={dimensions}
                                                                      arrShowShortIds={arrShowShortIds}
                                                                      showLock={showLock}
                                                                      lock={lock}
                                                                      showShortId={showShortId}
                                                                      sort={sort}
                                                                      disableSort={disableSort}
                                                                      setLock={setLock}
                                                                      setShowShortId={setShowShortId}
                                                                      filterMetric={filterMetric}
                                                                      reportName={reportName}
                                                                 />
                                                                 <div className="rule-segment-item-icon d-flex">
                                                                      <CIcon
                                                                           icon="iconChartPie"
                                                                           style={{
                                                                                cursor: 'pointer',
                                                                                width: '14px',
                                                                                height: '14px',
                                                                                marginRight: '5px',
                                                                           }}
                                                                      />
                                                                      <span className="rule-segment-item-level">S{segIndex + 1}</span>
                                                                 </div>
                                                            </div>
                                                       )}
                                                       locked={lock[item.value] ? true : false}
                                                  // cell={(props) => CustomCellCompareValue({props})}
                                                  // footerCell={(props) => {
                                                  //      const totalCell = getTotalCellCompare(props.field);
                                                  //      return (
                                                  //           <td className={props.className} style={props.style} width={props.width}>
                                                  //                {totalCell || totalCell === 0 ? totalCell : ''}
                                                  //           </td>
                                                  //      );
                                                  // }}
                                                  >
                                                       {['FFB300', 'D1D5DB'].map((color, key) => (
                                                            <GridColumn
                                                                 key={key}
                                                                 title={item.label}
                                                                 field={`${key === 0 ? item.value : `${item.value}Compare`}`}
                                                                 orderIndex={key}
                                                                 width={getWidthByKey({
                                                                      data: widthColumnReports,
                                                                      reportType,
                                                                      reportName,
                                                                      label: item.label,
                                                                      key: item.value,
                                                                      tableData,
                                                                      index: orderIndex,
                                                                      isMetric: true,
                                                                      newLabel: true
                                                                 })}
                                                                 cell={(props) => CustomCellCompareValue({ props, color })}
                                                                 footerCell={(props) => {
                                                                      const totalCell = getTotalCellCompare(props.field);
                                                                      const compareClass = `compare-${color}`;
                                                                      return (
                                                                           <td
                                                                                className={props.className + ` ${totalCell !== undefined ? compareClass : ''} `}
                                                                                style={props.style}
                                                                                width={props.width}
                                                                           >
                                                                                {totalCell || totalCell === 0 ? totalCell : ''}
                                                                           </td>
                                                                      );
                                                                 }}
                                                                 locked={lock[item.value] ? true : false}
                                                            ></GridColumn>
                                                       ))}
                                                  </GridColumn>
                                             );

                                        })
                                   ))
                                   }
                              </GridColumn>
                         ) : (
                              <GridColumn orderIndex={2} title="Metrics" resizable={false}>
                                   {mergeMetrics.map((item, index) => {
                                        return (
                                             <GridColumn
                                                  key={index}
                                                  title={item.label}
                                                  field={item.value}
                                                  orderIndex={index}
                                                  locked={lock[item.value] ? true : false}
                                                  headerCell={(props) => (
                                                       <CustomHeaderCell
                                                            props={props}
                                                            dimensions={dimensions}
                                                            arrShowShortIds={arrShowShortIds}
                                                            showLock={showLock}
                                                            lock={lock}
                                                            showShortId={showShortId}
                                                            sort={sort}
                                                            disableSort={disableSort}
                                                            setLock={setLock}
                                                            setShowShortId={setShowShortId}
                                                            filterMetric={filterMetric}
                                                            reportName={reportName}
                                                       />
                                                  )}
                                             >
                                                  {['FFB300', 'D1D5DB'].map((color, key) => (
                                                       <GridColumn
                                                            key={key}
                                                            title={item.label}
                                                            field={`${key === 0 ? item.value : `${item.value}Compare`}`}
                                                            orderIndex={key}
                                                            width={getWidthByKey({
                                                                 data: widthColumnReports,
                                                                 reportType,
                                                                 reportName,
                                                                 label: item.label,
                                                                 key: item.value,
                                                                 tableData,
                                                                 index,
                                                                 isMetric: true,
                                                                 newLabel: true
                                                            })}
                                                            cell={(props) => CustomCellCompareValue({ props, color })}
                                                            footerCell={(props) => {
                                                                 const totalCell = getTotalCellCompare(props.field);
                                                                 const compareClass = `compare-${color}`;
                                                                 return (
                                                                      <td className={props.className + ` ${totalCell !== undefined ? compareClass : ''} `}
                                                                           style={props.style}
                                                                           width={props.width}
                                                                      >
                                                                           {totalCell || totalCell === 0 ? totalCell : ''}
                                                                      </td>
                                                                 );
                                                            }}
                                                            locked={lock[item.value] ? true : false}
                                                       ></GridColumn>
                                                  ))}
                                             </GridColumn>
                                        );
                                   })}
                              </GridColumn>
                         )
                         )
                    }

                    {/* {convRate.length > 0 && (
                         <GridColumn orderIndex={3} title={`${scoped} Conv Rates`} resizable={false}>
                              {convRate.map((item, index) => {
                                   return (
                                        <GridColumn
                                             key={index}
                                             title={item.label}
                                             field={item.value}
                                             width={getWidthByKey(widthColumnReports, reportType, reportName, item.label)}
                                             headerCell={(props) => (
                                                  <CustomHeaderCell
                                                       props={props}
                                                       dimensions={dimensions}
                                                       arrShowShortIds={arrShowShortIds}
                                                       showLock={showLock}
                                                       lock={lock}
                                                       showShortId={showShortId}
                                                       sort={sort}
                                                       disableSort={disableSort}
                                                       setLock={setLock}
                                                       setShowShortId={setShowShortId}
                                                  />
                                             )}
                                             locked={lock[item.value] ? true : false}
                                             cell={(props) => CustomCellCompareValue(props, index)}
                                             footerCell={(props) => {
                                                  const totalCell = getTotalCellCompare(props.field);
                                                  return (
                                                       <td className={props.className} style={props.style} width={props.width}>
                                                            {totalCell || totalCell === 0 ? totalCell : ''}
                                                       </td>
                                                  );
                                             }}
                                        ></GridColumn>
                                   );
                              })}
                         </GridColumn>
                    )}
                    {convName.length > 0 && (
                         <GridColumn orderIndex={3} title={'Individual Conversions'} resizable={false}>
                              {convName.map((item, index) => {
                                   return (
                                        <GridColumn
                                             key={index}
                                             title={item.label}
                                             field={item.value}
                                             width={getWidthByKey(widthColumnReports, reportType, reportName, item.label)}
                                             headerCell={(props) => (
                                                  <CustomHeaderCell
                                                       props={props}
                                                       dimensions={dimensions}
                                                       arrShowShortIds={arrShowShortIds}
                                                       showLock={showLock}
                                                       lock={lock}
                                                       showShortId={showShortId}
                                                       sort={sort}
                                                       disableSort={disableSort}
                                                       setLock={setLock}
                                                       setShowShortId={setShowShortId}
                                                  />
                                             )}
                                             locked={lock[item.value] ? true : false}
                                             cell={(props) => CustomCellCompareValue(props, index)}
                                             footerCell={(props) => {
                                                  const totalCell = getTotalCellCompare(props.field);
                                                  return (
                                                       <td className={props.className} style={props.style} width={props.width}>
                                                            {totalCell || totalCell === 0 ? totalCell : ''}
                                                       </td>
                                                  );
                                             }}
                                        ></GridColumn>
                                   );
                              })}
                         </GridColumn>
                    )}
                    {eCommerce.length > 0 && (
                         <GridColumn orderIndex={3} title={'Individual Conversions'} resizable={false}>
                              {eCommerce.map((item, index) => {
                                   return (
                                        <GridColumn
                                             key={index}
                                             title={item.label}
                                             field={item.value}
                                             width={getWidthByKey(widthColumnReports, reportType, reportName, item.label)}
                                             headerCell={(props) => (
                                                  <CustomHeaderCell
                                                       props={props}
                                                       dimensions={dimensions}
                                                       arrShowShortIds={arrShowShortIds}
                                                       showLock={showLock}
                                                       lock={lock}
                                                       showShortId={showShortId}
                                                       sort={sort}
                                                       disableSort={disableSort}
                                                       setLock={setLock}
                                                       setShowShortId={setShowShortId}
                                                  />
                                             )}
                                             locked={lock[item.value] ? true : false}
                                             cell={(props) => CustomCellCompareValue(props, index)}
                                             footerCell={(props) => {
                                                  const totalCell = getTotalCellCompare(props.field);
                                                  return (
                                                       <td className={props.className} style={props.style} width={props.width}>
                                                            {totalCell || totalCell === 0 ? totalCell : ''}
                                                       </td>
                                                  );
                                             }}
                                        ></GridColumn>
                                   );
                              })}
                         </GridColumn>
                    )} */}
               </Grid>
               <CRow className={`report-table-bottom ${!pagination ? 'justify-content-end' : ''}`}>
                    {pagination && (
                         <div className="d-flex px-0">
                              <CSmartPagination
                                   activePage={activePage}
                                   pages={totalPages}
                                   onActivePageChange={onPaginationChange}
                                   className="report-table-page"
                              ></CSmartPagination>
                              <div className="form-inline justify-content-sm-end ml-4">
                                   <select className="form-control" onChange={onChangeItemsPerPage} defaultValue={itemsPerPage}>
                                        <option value="5">5</option>
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                   </select>
                                   <label className="ml-2">Item per page</label>
                              </div>
                         </div>
                    )}
                    {!pagination && (
                         <div className="toggle-bottom">
                              <CSwitch
                                   className="mx-1"
                                   variant="3d"
                                   color="primary"
                                   checked={pagination ? 'checked' : ''}
                                   onChange={(e) => handleChangePagination()}
                                   disabled={true}
                              />
                              <span>Paging</span>
                         </div>
                    )}
               </CRow>
          </>
     );
};

export default React.memo(ReportTableCompare);
