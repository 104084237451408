import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFlexibleMultiModal } from '../../../../../actions/common';
import { callTokenApi } from '../../../../../apiCaller';
import {
     ACCORDION_TYPES,
     API_RECORD_PROFILE_PERSON,
     COMPONENT_NAME,
     DATE_SCOPE,
     DEFAULT_DATE_FORMAT,
     REPORT_NAMES,
     SUBSCRIBER_PATH,
     VARIABLE_OPERATORS_DIMENSION_REPORT,
} from '../../../../../constants';
import { convertReportDuration, draftSessionStorageReport, encodeObj, toastError } from '../../../../../utils';
import { formatNumber } from '../../../../../utils/UtilReport';
import Accordion from '../../../../general/accordion';
import EventExplorer from '../EventExplorer';
import { RowDetails } from './CompanyDetail';
import TableRecordProfile from './TableRecordProfile';
import { UserSourceDetails, UserSourcePathDetails } from './TootleDetail';
import {
     COMPANY_RECORD_PROFILE,
     PEOPLE_ALL_PAGE_RECORD_PROFILE,
     PEOPLE_CONVERSION_RECORD_PROFILE,
     PEOPLE_SOURCE_RECORD_PROFILE,
} from './utilsCompany';

const RecordProfilePerson = ({ id, linkedData }) => {
     const dispatch = useDispatch();
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const dateRangeReports = useSelector((state) => state.subscriber.dateRangeReports);
     const [initialValue, setInitialValue] = useState(null);
     const [isLoading, setIsLoading] = useState(true);
     const [accordionShow, setAccordionShow] = useState(ACCORDION_TYPES.PEOPLE_OTHER_DATA);
     const [isFecthTableEvent, setIsFecthTableEvent] = useState(false);
     const [accordionFirstLoad, setAccordionFirstLoad] = useState([]);

     const data = { id, accountId: activeAccount.id };
     let scoped = 'People';
     let newDimensions = [{ key: 'personId', label: 'Person ID' }];
     const filterUser = [
          {
               type: 'personId',
               value: `${id}`,
               operator: 'eq',
               optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
               scoped: 'User',
               reportName: 'userDetails',
               clickable: true,
          },
     ];
     const filterSession = [
          {
               type: 'personId',
               value: `${id}`,
               operator: 'eq',
               optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
               scoped: 'Session',
               reportName: 'sessionDetails',
               clickable: true,
          },
     ];

     useEffect(() => {
          if (
               accordionShow === ACCORDION_TYPES.RECORD_PROFILE_TABLE_SESSION ||
               accordionShow === ACCORDION_TYPES.RECORD_PROFILE_TABLE_USER
          ) {
               setIsFecthTableEvent(true);
          }
     }, [accordionShow]);

     useEffect(() => {
          callTokenApi(API_RECORD_PROFILE_PERSON, 'POST', data)
               .then((response) => {
                    if (response.status === 200) {
                         setInitialValue(response.data.dataPerson);
                    } else {
                         toastError(response);
                    }
               })
               .finally(() => {
                    setIsLoading(false);
               });
     }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

     const personInfo = initialValue && initialValue.newPersonInfo;

     const handleNavigateCompany = (id) => {
          dispatch(
               setFlexibleMultiModal({
                    show: true,
                    attributes: { id: id },
                    component: COMPONENT_NAME.RECORD_PROFILE_COMPANY,
               })
          );
     };

     const handleLinkToOtherReport = (fieldKey, linkedData) => {
          let path = '';
          let linkedReportData;
          let filters = [];
          const reportName = REPORT_NAMES.SESSION_DETAILS;

          switch (fieldKey) {
               case 'sessionCount':
                    path = SUBSCRIBER_PATH.INSIGHTS_SESSIONS;
                    newDimensions.forEach((item) => {
                         filters.push({
                              type: 'personId',
                              value: `${id}`,
                              operator: 'ct',
                              optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
                              scoped: scoped,
                              reportName: reportName,
                              clickable: true,
                              filterRecord: true,
                         });
                    });

                    break;

               case 'revealedCompany':
                    path = SUBSCRIBER_PATH.INSIGHTS_REVEALED_COMPANIES;
                    // newDimensions = [{ key: 'companyId', label: 'Revealed Company ID' }];
                    newDimensions.forEach((item) => {
                         filters.push({
                              type: 'personId',
                              value: `${id}`,
                              operator: 'ct',
                              optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
                              scoped: scoped,
                              reportName: reportName,
                              clickable: true,
                              filterRecord: true,
                         });
                    });

                    break;
               default:
          }

          linkedReportData = {
               filters,
               dateRange: {
                    startDate: dateRangeReports.selection.startDate.format(DEFAULT_DATE_FORMAT),
                    endDate: dateRangeReports.selection.endDate.format(DEFAULT_DATE_FORMAT),
               },
               dateScope: DATE_SCOPE.EVENT_ACTIVITY,
               segmentId: '',
          };

          draftSessionStorageReport({
               accountId: activeAccount.id, reportName, fieldKey, isLink: true, isProfile: true, linkedReportData
          })
          path = path.replace(':secondId', activeAccount.secondId) + '?data=' + encodeObj({
               accountId: activeAccount.id, reportName, fieldKey, isProfile: true
          });
          window.open(path, '_blank');
     };

     const handleSetAccordion = (value) => {
          setAccordionShow(value)
          const data = [...accordionFirstLoad]
          if (!data.includes(value)) {
               data.push(value)
          }
          setAccordionFirstLoad(data)
     }

     return (
          <div className="record-profile">
               {isLoading ? (
                    <div className="record-profile loading-profile">
                         <h1>Person Profile</h1>
                         <>
                              <div className="record-profile-info">
                                   <p className="event-profile-session">Person ID: </p>
                                   <div className="date">
                                        <p className="event-profile-session">Identified Date: </p>
                                        <p className="event-profile-session">First Session: </p>
                                        <p className="event-profile-session">Latest Session: </p>
                                   </div>
                                   <p className="event-profile-session">
                                        Revealed Companies: <span></span>
                                   </p>
                              </div>
                              <div className="list-count-data">
                                   <div className="card box-count-data event-profile-session">
                                        <h4>Lifetime Sessions</h4>
                                        <p className="item-name"></p>
                                   </div>
                                   <div className="card box-count-data event-profile-session">
                                        <h4>Lifetime Engaged Sessions</h4>
                                        <p className="item-name"></p>
                                   </div>
                                   <div className="card box-count-data event-profile-session">
                                        <h4>Avg Session Duration</h4>
                                        <p className="item-name"></p>
                                   </div>
                                   <div className="card box-count-data event-profile-session">
                                        <h4>Lifetime Pageviews</h4>
                                        <p className="item-name"></p>
                                   </div>
                                   <div className="card box-count-data event-profile-session">
                                        <h4>Lifetime Conversions</h4>
                                        <p className="item-name"></p>
                                   </div>
                                   <div className="card box-count-data event-profile-session">
                                        <h4>Revealed Companies</h4>
                                        <p className="item-name"></p>
                                   </div>
                                   <div className="card box-count-data event-profile-session">
                                        <h4>Lifetime Engagement Score</h4>
                                        <p className="item-name"></p>
                                   </div>
                                   <div className="card box-count-data event-profile-session">
                                        <h4>is Target Account</h4>
                                        <p className="item-name"></p>
                                   </div>
                              </div>
                              <div className="page">
                                   <div className="page-path-name event-profile-session">
                                        <p className="title">Source (first):</p>
                                        <p className="description"></p>
                                   </div>
                                   <div className="page-path-name event-profile-session">
                                        <p className="title">Source (last):</p>
                                        <p className="description"></p>
                                   </div>
                                   <div className="page-path-name event-profile-session">
                                        <p className="title">Source (path):</p>
                                        <p className="description source-path"></p>
                                   </div>
                                   <div className="page-path-name event-profile-session">
                                        <p className="title">Source person created (first):</p>
                                        <p className="description"></p>
                                   </div>
                                   <div className="page-path-name event-profile-session">
                                        <p className="title">Source person created (last):</p>
                                        <p className="description"></p>
                                   </div>
                                   <div className="page-path-name event-profile-session">
                                        <p className="title">Source person created (path):</p>
                                        <p className="description source-path"></p>
                                   </div>
                              </div>
                              <div className="accordion accordion-profile">
                                   <Accordion
                                        accordionType={ACCORDION_TYPES.PEOPLE_OTHER_DATA}
                                        title="Other Details"
                                        classNameBody="event-profile-session"
                                        accordionShow={accordionShow}
                                        setAccordionShow={setAccordionShow}
                                   >
                                        <RowDetails label="First Name" />
                                        <RowDetails label="Last Name" />
                                        <RowDetails label="Phone Number" />
                                        <RowDetails label="Address" />
                                        <RowDetails label="External Id" />
                                        <RowDetails label="Email List" />
                                        <RowDetails label="Phone List" />
                                   </Accordion>
                              </div>
                         </>
                    </div>
               ) : (
                    <div className="record-profile">
                         <h1>Person Profile</h1>
                         {personInfo && (
                              <>
                                   <div className="record-profile-info">
                                        <h4>{personInfo.email}</h4>
                                        <p>Person ID: {personInfo.personId}</p>
                                        <div className="date">
                                             <p>Identified Date: {personInfo.identifiedDate}</p>
                                             <p>First Session: {personInfo.firstSessionDate}</p>
                                             <p>Latest Session: {personInfo.lastSessionDate}</p>
                                        </div>
                                        <p className="list-company">
                                             Revealed Company:{' '}
                                             {personInfo.companyList.map((item, index) => {
                                                  return (
                                                       <span key={index} className="link-data" onClick={() => handleNavigateCompany(item.companyId)}>
                                                            {index < personInfo.companyList.length - 1 ? (
                                                                 <>{item.companyName},&nbsp;</>
                                                            ) : (
                                                                 <>{item.companyName}</>
                                                            )}
                                                       </span>
                                                  );
                                             })}
                                        </p>
                                   </div>
                                   <div className="list-count-data">
                                        <div className="card box-count-data">
                                             <h4>Lifetime Sessions</h4>
                                             {personInfo.countSession > 0 ? (
                                                  <p
                                                       className="item-name link-data"
                                                       onClick={() => handleLinkToOtherReport('sessionCount', linkedData)}
                                                  >
                                                       {formatNumber(personInfo.countSession)}
                                                  </p>
                                             ) : (
                                                  <p className="item-name">{personInfo.countSession}</p>
                                             )}
                                        </div>
                                        <div className="card box-count-data">
                                             <h4>Lifetime Engaged Sessions</h4>
                                             <p className="item-name">{formatNumber(personInfo.countEngaged)}</p>
                                        </div>
                                        <div className="card box-count-data">
                                             <h4>Avg Session Duration</h4>
                                             <p className="item-name">{convertReportDuration(personInfo.avgSessionDuration)}</p>
                                        </div>
                                        <div className="card box-count-data">
                                             <h4>Lifetime Pageviews</h4>
                                             <p className="item-name">{formatNumber(personInfo.countPageView)}</p>
                                        </div>
                                        <div className="card box-count-data">
                                             <h4>Lifetime Conversions</h4>
                                             <p className="item-name">{formatNumber(personInfo.countConversion)}</p>
                                        </div>
                                        <div className="card box-count-data">
                                             <h4>Revealed Companies</h4>
                                             {personInfo.companyList.length > 0 ? (
                                                  <p
                                                       className="item-name link-data"
                                                       onClick={() => handleLinkToOtherReport('revealedCompany', linkedData)}
                                                  >
                                                       {personInfo.companyList.length}
                                                  </p>
                                             ) : (
                                                  <p className="item-name">{personInfo.companyList.length}</p>
                                             )}
                                        </div>
                                        <div className="card box-count-data">
                                             <h4>Lifetime Engagement Score</h4>
                                             <p className="item-name">{formatNumber(Number(personInfo.engagementScore))}</p>
                                        </div>
                                        <div className="card box-count-data">
                                             <h4>is Target Account</h4>
                                             <p className="item-name">{personInfo.isTargetAccount}</p>
                                        </div>
                                   </div>
                                   <div className="page">
                                        <div className="page-path-name">
                                             <p className="title">Source (first):</p>
                                             <div className="description">
                                                  <UserSourceDetails data={personInfo.sourceFirst} />
                                             </div>
                                        </div>
                                        <div className="page-path-name">
                                             <p className="title">Source (last):</p>
                                             <div className="description">
                                                  <UserSourceDetails data={personInfo.sourceLast} />
                                             </div>
                                        </div>
                                        <div className="page-path-name">
                                             <p className="title">Source (path):</p>
                                             <UserSourcePathDetails data={personInfo.sourcePath} />
                                        </div>
                                        <div className="page-path-name">
                                             <p className="title">Source person created (first):</p>
                                             <div className="description">
                                                  <UserSourceDetails data={personInfo.userSourceFirstPersonCreated} />
                                             </div>
                                        </div>
                                        <div className="page-path-name">
                                             <p className="title">Source person created (last):</p>
                                             <div className="description">
                                                  <UserSourceDetails data={personInfo.userSourceLastPersonCreated} />
                                             </div>
                                        </div>
                                        <div className="page-path-name">
                                             <p className="title">Source person created (path):</p>
                                             <div className="description source-path">
                                                  <UserSourceDetails data={personInfo.userSourcePathPersonCreated} />
                                             </div>
                                        </div>
                                   </div>
                                   <div className="accordion accordion-profile">
                                        <Accordion
                                             accordionType={ACCORDION_TYPES.PEOPLE_OTHER_DATA}
                                             title="Other Details"
                                             accordionShow={accordionShow}
                                             setAccordionShow={setAccordionShow}
                                        >
                                             <RowDetails label="First Name" value={personInfo.firstName} />
                                             <RowDetails label="Last Name" value={personInfo.lastName} />
                                             <RowDetails label="Phone Number" value={personInfo.phone} />
                                             <RowDetails label="Address" value={personInfo.address} isScrollbar={true} />
                                             <RowDetails label="External Id" value={personInfo.externalId} />
                                             <RowDetails label="Email List" value={personInfo.emailAddress} isScrollbar={true} />
                                             <RowDetails label="Phone List" value={personInfo.phoneList} isScrollbar={true} />
                                        </Accordion>
                                   </div>
                              </>
                         )}
                    </div>
               )}
               <div className="accordion">
                    {isLoading ? (
                         <>
                              <Accordion
                                   accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_COMPANY}
                                   title={`Companies (0)`}
                                   accordionShow={accordionShow}
                                   setAccordionShow={setAccordionShow}
                              ></Accordion>
                              <Accordion
                                   accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_ALL_PAGE}
                                   title={`All Pages (0)`}
                                   accordionShow={accordionShow}
                                   setAccordionShow={setAccordionShow}
                              ></Accordion>
                              <Accordion
                                   accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_SOURCE}
                                   title={`Sources (0)`}
                                   accordionShow={accordionShow}
                                   setAccordionShow={setAccordionShow}
                              ></Accordion>
                              <Accordion
                                   accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_CONVERSION}
                                   title={`Conversions (0)`}
                                   accordionShow={accordionShow}
                                   setAccordionShow={setAccordionShow}
                              ></Accordion>
                              <Accordion
                                   accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_USER}
                                   title={`User Details (0)`}
                                   accordionShow={accordionShow}
                                   setAccordionShow={setAccordionShow}
                              ></Accordion>
                              <Accordion
                                   accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_SESSION}
                                   title={`Session Details (0)`}
                                   accordionShow={accordionShow}
                                   setAccordionShow={setAccordionShow}
                              ></Accordion>
                         </>
                    ) : (
                         <>
                              <TableRecordProfile
                                   id={id}
                                   header={COMPANY_RECORD_PROFILE}
                                   reportType={'peopleCompany'}
                                   tableType={'table-company'}
                                   title={'Companies'}
                                   accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_COMPANY}
                                   accordionShow={accordionShow}
                                   setAccordionShow={setAccordionShow}
                              />
                              <TableRecordProfile
                                   id={id}
                                   header={PEOPLE_ALL_PAGE_RECORD_PROFILE}
                                   reportType={'peopleAllPage'}
                                   tableType={'table-all-page'}
                                   title={'All Pages'}
                                   accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_ALL_PAGE}
                                   accordionShow={accordionShow}
                                   setAccordionShow={setAccordionShow}
                              />
                              <TableRecordProfile
                                   id={id}
                                   header={PEOPLE_SOURCE_RECORD_PROFILE}
                                   reportType={'peopleSource'}
                                   tableType={'table-source'}
                                   title={'Sources'}
                                   accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_SOURCE}
                                   accordionShow={accordionShow}
                                   setAccordionShow={setAccordionShow}
                              />
                              <TableRecordProfile
                                   id={id}
                                   header={PEOPLE_CONVERSION_RECORD_PROFILE}
                                   reportType={'peopleConversion'}
                                   tableType={'table-conversion'}
                                   title={'Conversions'}
                                   accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_CONVERSION}
                                   accordionShow={accordionShow}
                                   setAccordionShow={setAccordionShow}
                              />
                              {personInfo && personInfo.countUser > 0 && (
                                   <Accordion
                                        accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_USER}
                                        title={`User Details (${personInfo.countUser})`}
                                        accordionShow={accordionShow}
                                        setAccordionShow={handleSetAccordion}
                                   >
                                        {(accordionShow === ACCORDION_TYPES.RECORD_PROFILE_TABLE_USER || accordionFirstLoad.includes(ACCORDION_TYPES.RECORD_PROFILE_TABLE_USER)) && (
                                             <EventExplorer title={''} reportName={REPORT_NAMES.USER_DETAILS} recordProfile={true} filterRecord={filterUser} isFecthTableEvent={isFecthTableEvent} />
                                        )}
                                   </Accordion>
                              )}
                              {personInfo && personInfo.countSession > 0 && (
                                   <Accordion
                                        accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_SESSION}
                                        title={`Session Details (${personInfo.countSession})`}
                                        accordionShow={accordionShow}
                                        setAccordionShow={handleSetAccordion}
                                   >
                                        {(accordionShow === ACCORDION_TYPES.RECORD_PROFILE_TABLE_SESSION || accordionFirstLoad.includes(ACCORDION_TYPES.RECORD_PROFILE_TABLE_SESSION)) && (
                                             <EventExplorer title={''} reportName={REPORT_NAMES.SESSION_DETAILS} recordProfile={true} filterRecord={filterSession} isFecthTableEvent={isFecthTableEvent} />
                                        )}
                                   </Accordion>
                              )}
                         </>
                    )}
               </div>
          </div>
     );
};

export default RecordProfilePerson;
