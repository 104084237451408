import { callApiCrawlerStart, callSalesforceApi, callTokenApi, makeRequest } from '../apiCaller';
// import { toast } from 'react-toastify';
import { toast } from 'react-toastify';
import {
     API_CLIENT_ACCOUNT,
     API_CLIENT_ACCOUNTS,
     API_CLIENT_ACCOUNT_COMPLETED_CHILD_SETUP_GUIDE,
     API_CLIENT_ACCOUNT_CRAWLER_STATUS,
     API_CLIENT_ACCOUNT_DESTINATION,
     API_CLIENT_ACCOUNT_DISABLE_EVENT_LISTENERS,
     API_CLIENT_ACCOUNT_ENABLE_EVENT_LISTENERS,
     API_CLIENT_ACCOUNT_NOTIFICATIONS,
     API_CLIENT_ACCOUNT_RULES,
     API_CLIENT_ACCOUNT_UPGRADE,
     API_CLIENT_ALL_RULES,
     API_CLIENT_CHANNELS,
     API_CLIENT_CHECK_RELATED_BEFORE_DISABLE_EVENT,
     API_CLIENT_COUNT_FORM_IGNORED,
     API_CLIENT_COUNT_FORM_INVENTORY,
     API_CLIENT_COUNT_FORM_REQUIRING,
     API_CLIENT_CUSTOM_REPORTS,
     CLIENT_GET_SAVED_REPORTS,
     API_CLIENT_CUSTOM_VARIABLE,
     API_CLIENT_DATALAYER_VARIABLE,
     API_CLIENT_DESTINATIONS_GTM_ACCOUNTS,
     API_CLIENT_DESTINATIONS_GTM_CONTAINERS,
     API_CLIENT_DIMENSIONS,
     API_CLIENT_EVENT_ACCOUNT,
     API_CLIENT_FEATURE_CONFIG_MY_VALUE,
     API_CLIENT_FETCH_CHILD_FORM_IGNORED,
     API_CLIENT_FETCH_CHILD_FORM_INVENTORY,
     API_CLIENT_FETCH_CHILD_FORM_REQUIRING,
     API_CLIENT_FETCH_FORM_INVENTORY,
     API_CLIENT_FORM_CATEGORIES,
     API_CLIENT_FORM_IGNORED,
     API_CLIENT_FORM_REQUIRING,
     API_CLIENT_GET_CUSTOM_CATEGORIES,
     API_CLIENT_METRICS,
     API_CLIENT_REPORT_FILTER_CHECKLIST,
     API_CLIENT_RULES,
     API_CLIENT_SCOPES,
     API_CLIENT_SELECTOR_VARIABLE,
     API_CLIENT_TICKETS_NUMBER_COMMENT,
     API_CLIENT_UPDATE_CURRENT_STEP_CATEGORIES,
     API_CLIENT_UPDATE_CURRENT_STEP_CONVERSION,
     API_CLIENT_UPDATE_CURRENT_STEP_CUSTOM_FORMS,
     API_CLIENT_UPDATE_CUSTOM_CATEGORIES,
     API_CLIENT_UPDATE_FORM_CATEGORIES,
     API_CLIENT_UPDATE_IS_REDIRECT,
     API_CLIENT_UPDATE_REVEAL_PEOPLE,
     API_CLIENT_USER_SOURCES,
     API_CONSENT_RULES,
     // API_GTM_ACCOUNTS,
     // API_GTM_CONTAINERS,
     API_GTM_WORKSPACES,
     API_SAVE_NOTIFICATION_PUSHER_TO_REDIS,
     API_VERSION_WAITING,
     CLIENT_LISTENER,
     COMPLETE_OWNERSHIP_TRANFER,
     GET_USERS,
     LISTENER_TYPE,
     LOCAL_ACCORDION_SHOW,
     LOCAL_GTM_ACCESS_TOKEN,
     LOCAL_GTM_USER_EMAIL,
     LOCAL_PREVIEW,
     LOCAL_PREVIEW_DOMAIN,
     LOCAL_TEMP_LIMITATION,
     LOWERCASE_CLICK_FEATURES,
     REPORT_NAMES,
     RULE_GROUP,
     STEPS_CUSTOM_FORM,
     STEPS_WIZARD_CATEGORIES,
     // API_GTM_USER_PERMISSIONS,
     // API_GOOGLE_USER_INFO,
     UPDATE_DOMAIN_ACCOUNT,
     API_SALESFORCE_RULE_LIST,
     API_CLIENT_DATALAYER_VARIABLE_RULE,
     API_CLIENT_GET_LIST_SEGMENT,
     CRAWLER_PREFIX,
     API_CLIENT_GET_LIST_SEVER_SIDE_TRIGGER,
     API_CLIENT_GOALS,
     API_CLIENT_GET_LIST_SALES_CONVERSION,
} from '../constants';
import {
     findAccount,
     findAccountIndex,
     getAccountGoalsRoute,
     getDestinationRoutes,
     getEnabledListeners,
     getListVariables,
     getListenerRoutes,
} from '../helpers/cms/subscriber';
import { defaultSubscriberRoutes } from '../routes';
import { deepCopyArray, isMatchCustomForm, toastError, tryParseJSON } from '../utils';
import { crawlFormsAction, setListRuleConditions, setNumberOfHistories } from './pusher';
import {
     FORM_CATEGORY_SET_ALL_FORMS_IGNORE,
     FORM_CATEGORY_SET_ALL_FORMS_INVENTORY,
     FORM_CATEGORY_SET_FORM_EDIT,
     FORM_CATEGORY_SET_LIST_FORM_CHILDS,
     FORM_CATEGORY_SET_NUMBER_OFFSET,
     FORM_CATEGORY_UPDATE_FORM_CHILD,
     FORM_IGNORE_SET_FORM_EDIT,
     FORM_REQUIRING_SET_ALL_FORMS,
     FORM_REQUIRING_SET_FORM_EDIT,
     PUSHER_SCHEDULE_UPGRADE,
     RELOAD_CONVERSION_RULES,
     RELOAD_DATA_ACTIONS,
     RELOAD_DESTINATION_CONVERSIONS,
     RELOAD_DESTINATION_ECOMMERCE,
     RELOAD_LOOKUP_TABLE,
     RELOAD_TRIGGER_RULES,
     RELOAD_USER_SOURCE,
     RESET_LIMITTAION_TYPE,
     RESET_PREVIEW,
     RESET_SUBSCRIBER_REDUCER,
     REVERT_PUBLISH_DATA,
     SAVE_TEMP_PUBLISH_DATA,
     SET_ACCORDION,
     SET_ACCOUNTS,
     SET_ACCOUNT_DESTINATION,
     SET_ACCOUNT_LISTENERS,
     SET_ACCOUNT_PASTDUE,
     SET_ACCOUNT_PREVIEW,
     SET_ACCOUNT_REDEEMED_COUPONS,
     SET_ACROSS_DOMAINS,
     SET_ACTIVE_ACCOUNT,
     SET_ACTIVE_FEATURES,
     SET_ACTIVE_LISTENER,
     SET_ACTIVE_LISTENERS,
     SET_ALL_DATA_ACTION_RULES,
     SET_ALL_EVENTS,
     SET_APPLY_CHILD,
     SET_APPLY_REDIRECT,
     SET_AUTOMATIC_VARIABLE_RELOAD,
     SET_AUTOMATIC_VARIABLE_WARNING,
     SET_AVAILABLE_HOURS,
     SET_BROWSER_RULES,
     SET_BROWSER_VARIABLE_OPTIONS,
     SET_BROWSE_LISTENER_FORM_CATEGORY,
     SET_BUY_OFFICE_HOURS,
     SET_CHART_OPTIONS,
     SET_CHECKED_ITEMS_BYPAGE,
     SET_CLICK_IDS,
     SET_CLICK_RULES,
     SET_COMPLETE_SETUP_GUIDE,
     SET_CONFIRM_ADD_SCRIPT,
     SET_CONSENT_LISTENER_ACCOUNT,
     SET_CONSENT_RULES_ACCOUNT,
     SET_CONVERSION_RULES,
     SET_COUNT_FORM_IGNORED,
     SET_COUNT_FORM_INVENTORY,
     SET_COUNT_FORM_REQUIRING,
     SET_CREATED_ACCOUNT,
     SET_CURRENT_PAGE,
     SET_CUSTOM_CATEGORY,
     SET_CUSTOM_DESTINATION_MODAL,
     SET_CUSTOM_FORM_TARGET_RULES,
     SET_CUSTOM_REPORT,
     SET_SAVED_REPORT,
     SET_CUSTOM_VARIABLE_OPTIONS,
     SET_CUSTOM_VARIABLE_RULES,
     SET_DATA_ACTIONS_RULES,
     SET_DATA_CHILD_SETUP_GUIDE_COMPLETED,
     SET_DATA_SETUP_GUIDE_COMPLETED,
     SET_DATA_WEATHER_TEMPERATURE,
     SET_DATE_REPORTS,
     SET_DATE_RANGE_RECORD,
     SET_DISABLE,
     SET_DOMAINS,
     SET_DOMAINS_PREVIEW,
     SET_DOMAIN_PREVIEW,
     SET_DORMANT_ACCOUNT,
     SET_EDITING_RULE_RELOAD,
     SET_ENGAGEMENT_RULES,
     SET_ESTIMATED_TOTAL_EVENTS,
     SET_EVENT_CHART,
     SET_EVENT_NATIVE_OPTIONS,
     SET_EVENT_OPTIONS,
     SET_EVENT_TABLE,
     SET_EVENT_TABLE_OF_LISTENERS,
     SET_FEATURES,
     SET_FEATURE_CONFIGURE_DESTINATION,
     SET_FEATURE_CUSTOM_OPTINAL,
     SET_FEATURE_FORM_CONVERSION,
     SET_FEATURE_INSTALL_GOALS,
     SET_FEATURE_LEARN_DATA,
     SET_FEATURE_LEARN_SEDING_DATA,
     SET_FILTER_SHOW_ENABLED_FEATURES,
     SET_FORM_CATEGORY,
     SET_FORM_IGNORED,
     SET_FORM_INVENTORY,
     SET_FORM_REQUIRING_ACTION,
     SET_GLOBAL_REPORTING,
     SET_GTM_ACCOUNTS,
     SET_GTM_CHANGELOG,
     SET_GTM_CONTAINERS,
     SET_GTM_TAGS_IN_LL,
     SET_GTM_TRIGGERS_IN_LL,
     SET_GTM_VARIABLES_IN_LL,
     SET_GTM_WIZARD,
     SET_INITIAL_CLICK_RULES,
     SET_INITIAL_CONVERSION_RULES,
     SET_INITIAL_CUSTOM_VARIABLE_RULES,
     SET_INITIAL_PERSONAL_DATA,
     SET_INITIAL_TRIGGER_RULES,
     SET_INITIAL_USER_SOURCE_RULES,
     SET_INVOICES,
     SET_IS_CUSTOMER_SUPPORT_LOGIN_AS,
     SET_IS_FROM_GET_STARTED,
     SET_LESSON_VIDEO_REF,
     SET_LIMITATION_TYPE,
     SET_LISTENERS_IN_TICKET,
     SET_LISTENER_EVENTS,
     SET_LISTENER_EVENTS_NATIVE,
     SET_LISTENER_FORMS_ENABLED,
     SET_LISTENER_PREVIEW,
     SET_LISTENER_SELECTORS,
     SET_LISTENER_VARIABLES,
     SET_LIST_BROWSER_VARIABLES,
     SET_LIST_CUSTOM_VARIABLES,
     SET_LIST_FEATURE_CUSTOM_FORMS,
     SET_LOADINGS,
     SET_LOOKUP_RULE_ACTIVE_STEP,
     SET_MANAGERS,
     SET_MANAGE_GOALS_TYPES,
     SET_MASTERCLASS_CERTIFICATION,
     SET_MASTERCLASS_COURSE,
     SET_MAX_PACKAGE,
     SET_MONITORING_NOTIFICATIONS,
     SET_MONITORING_RULES,
     SET_MY_CREATED_TICKETS,
     SET_NATIVE_BROWSER_ACCOUNT_FEATURES,
     SET_NATIVE_BROWSER_STORAGE,
     SET_NATIVE_BROWSER_STORAGE_OPTIONS,
     SET_NEW_CUSTOM_VARIABLE,
     SET_NEW_LAYOUT,
     SET_NEXT_PACKAGE_INDEX,
     SET_NUMBER_OF_COMMENT_TICKETS,
     SET_OFFICE_HOUR,
     SET_OFFICE_HOUR_CALENDAR,
     SET_OTHER_TICKETS,
     SET_OWNER,
     SET_PACKAGES,
     SET_PARENT_SETUP_GUIDE_COMPLETED,
     SET_POPULATE_RULES,
     SET_POPUP_CONFIGURATION_REQUIRED,
     SET_POPUP_CONFIRM_ADD_SCRIPT,
     SET_POPUP_CREATE_BROWSER_VARIABLE,
     SET_POPUP_CREATE_VARIABLE,
     SET_POPUP_DOMAIN_PREVIEW,
     SET_PREVIEW_BROWSER_RULE,
     SET_PREVIEW_CUSTOM_RULE,
     SET_PREVIEW_OPTIONS,
     SET_REDIRECT_RULES,
     SET_ROUTES,
     SET_SCROLL_SIDEBAR,
     SET_SEARCH_KEY,
     SET_SELECTOR_OPTIONS,
     SET_SELECTOR_VALUES,
     SET_SELECTOR_VARIABLE_RELOAD,
     SET_SETUP_GUIDE_ROADMAP,
     SET_SETUP_GUIDE_ROADMAP_DECREASE,
     SET_SETUP_GUIDE_ROADMAP_INCREASE,
     SET_SHOW_ACCOUNT_DORMANT_POPUP,
     SET_SHOW_ARCHIVE_NOTIFICATIONS,
     SET_SHOW_BLOCK_ACCOUNT_POPUP,
     SET_SHOW_BLOCK_SETUP_GUIDE_POPUP,
     SET_SHOW_CATEGORY_FORM,
     SET_SHOW_COMPARE_PLANS_POPUP,
     SET_SHOW_CREATE_ACCOUNT_POPUP,
     SET_SHOW_CUSTOM_FORM,
     SET_SHOW_EVENT_REPORTING_WARNING,
     SET_SHOW_GET_HELP_LABEL,
     SET_SHOW_HAMBURGER_ICON,
     SET_SHOW_IFRAME_CUSTOM_FORMS,
     SET_SHOW_IGNORED_FORM,
     SET_SHOW_IGNORED_INVENTORY_FORM,
     SET_SHOW_MASTERCLASS_ASIDE,
     SET_SHOW_PUBLISH_POPUP,
     SET_SHOW_ROADMAP,
     SET_SHOW_ROADMAP_MINIMIZE,
     SET_SHOW_STEP_CUSTOM_FORM,
     SET_SHOW_STEP_FORM_CATEGORY,
     SET_SHOW_TOUR_COMPETED_WIZARD,
     SET_SHOW_TOUR_FROM_ROADMAP,
     SET_SHOW_UPGRADE_ACCOUNT_POPUP,
     SET_SHOW_UPGRADE_ACCOUNT_SCHEDULE_POPUP,
     SET_SHOW_VIDEO,
     SET_SIGN_UP_STEP,
     SET_SKIP_WIZARD_FORM_CATEGORY,
     SET_STEPS_CUSTOM_FORMS,
     SET_STEPS_WIZARD_CATEGORIES,
     SET_STEP_NOT_SHOW_ANIMATION_ROADMAP,
     SET_SUBSCRIBER_STATE,
     SET_SUBSCRIBER_TICKET,
     SET_SWITCH_TYPE_WIZARD,
     SET_TERM_PUBLISH,
     SET_TICKET_INIT,
     SET_TOAST_IS_OPEN,
     SET_TOGGLE_PREVIEW_POPUP,
     SET_TOUR,
     SET_TRIGGERS_NAME_RULES,
     SET_TRIGGER_EVENT_ECOMMERCE,
     SET_TRIGGER_RULES,
     SET_TURNOFF_PREVIEW_POPUP,
     SET_UNSAVED_LOOKUP_TABLE_MODAL,
     SET_USED_ITEMS,
     SET_USER_SOURCE_RULES,
     SET_USER_TERMS,
     SET_VARIABLE_OPTIONS,
     SET_VARIABLE_VALUE_CHART,
     SET_VERSIONS,
     SET_VERSION_PUBLISH,
     SET_VIDEO_ENGAGEMENTS,
     SET_VIDEO_PROGRESSES,
     SET_VISITOR_SESSIONS_UNIQUE,
     SET_WIDTH_COLUMN_REPORTS,
     TOGGLE_ASIDE_LIMITATION_HIT,
     SET_REDUX_ACTIVE_DROPDOWN,
     SET_LOAD_FORM_CHILD_REQUIRING,
     SET_OPEN_FROM_CONFIG_CONVERSION,
     SET_SHOW_POPUP_CONFIG_CONVERSION,
     SET_STEPS_CONVERSION,
     FEW_FORMS_SET_FORM_EDIT,
     SET_FORM_DRAFT,
     SET_ELEMENT_CLICK_TARGET_RULES,
     FORM_CATEGORY_SET_FILTERS,
     FORM_CATEGORY_SET_FILTERS_EDIT,
     SET_SKIP_FORM_LISTENER,
     SET_ACCOUNT_EXTERNAL_DATA_SOURCES,
     SET_MONITORING_FORM_CATEGORY,
     SET_LIST_CHANNELS,
     SET_CUSTOM_USER_SOURCES,
     SET_LIST_USER_SOURCES,
     SET_MAX_STEP_RECORD_PROFILE,
     SET_MAX_STEP_FORM,
     SET_NATIVE_ECOMMERCE,
     SET_LISTENER_EVENTS_LOADING,
     SET_CUSTOMER_IDS_RESPONSE,
     SET_GOOGLE_ADS_ACCOUNTS,
     SET_SALESFORCE_ACCOUNTS,
     SET_SHOW_HISTORICAL_POPUP,
     SET_DATA_HISTORICAL_SALESFORCE,
     SET_REDUX_RELOAD_TABLE_REPORT,
     SET_REDUX_RELOAD_TABLE_CHART,
     SET_LIST_SEGMENT,
     SET_LIST_SEGMENT_REPORT,
     SET_LIST_SEGMENT_APPLY_RELOAD,
     SET_LIST_SEGMENT_RELOAD,
     SET_CONVERSIONS_NAME_RULES,
     SET_BLOCK_TRIGGER_RULES,
     SET_LOADING_FORM_IGNORE,
     SET_STEP_FORM_CATEGORY_FORM_WEB_CONVERSION,
     SET_SALESFORCE_RULES,
     SET_RELOAD_ACCOUNT_SALESFORCE,
     SET_LIST_SEVER_SIDE_TRIGGER,
     SET_SALESFORCE_RULES_PAGING,
     SET_LIST_SALES_CONVERSION,
     SET_SALESFORCE_SEARCH_LOGS,
     RESET_SALESFORCE_SEARCH_LOGS,
     SET_LIST_SEGMENT_DB,
     SET_DATA_DASHBOARD,
     SET_DB_SEGMENT_SELECTED,
     SET_LIST_TABLE_TEMP_QUERY_DATA,
     PUSHER_SET_LIST_TABLE_TEMP_QUERY_DATA,
     SET_SCHEDULE_SAVED_REPORT,
} from './types';

export const setStepNotShowAnimationRoadmap = (data) => {
     return {
          type: SET_STEP_NOT_SHOW_ANIMATION_ROADMAP,
          payload: data,
     };
};

export const setCurrentPage = (data) => {
     return {
          type: SET_CURRENT_PAGE,
          payload: data,
     };
};

export const setScheduleUpgrade = (data) => {
     return {
          type: PUSHER_SCHEDULE_UPGRADE,
          payload: data,
     };
};

export const setSearchKey = (data) => {
     return {
          type: SET_SEARCH_KEY,
          payload: data,
     };
};

export const setCheckedItemsByPage = (data) => {
     return {
          type: SET_CHECKED_ITEMS_BYPAGE,
          payload: data,
     };
};

export const setSubscriberState = (state) => {
     return {
          type: SET_SUBSCRIBER_STATE,
          payload: state,
     };
};

export const setAccounts = (accounts) => {
     return {
          type: SET_ACCOUNTS,
          payload: accounts,
     };
};

export const setActiveAccount = (account) => {
     return {
          type: SET_ACTIVE_ACCOUNT,
          payload: account,
     };
};

export const setConsentListenerAccount = (data) => {
     return {
          type: SET_CONSENT_LISTENER_ACCOUNT,
          payload: data,
     };
};

export const setConsentRules = (rules) => {
     return {
          type: SET_CONSENT_RULES_ACCOUNT,
          payload: rules,
     };
};

export const setDomains = (domains) => {
     return {
          type: SET_DOMAINS,
          payload: domains,
     };
};

export const setDisable = (disable) => {
     return {
          type: SET_DISABLE,
          payload: disable,
     };
};

export const setOwner = (owner) => {
     return {
          type: SET_OWNER,
          payload: owner,
     };
};

export const setManagers = (managers) => {
     return {
          type: SET_MANAGERS,
          payload: managers,
     };
};

export const setPackages = (packages) => {
     return {
          type: SET_PACKAGES,
          payload: packages,
     };
};

export const setMaxPackage = (maxPackage) => {
     return {
          type: SET_MAX_PACKAGE,
          payload: maxPackage,
     };
};

export const setNextPackageIndex = (index) => {
     return {
          type: SET_NEXT_PACKAGE_INDEX,
          payload: index,
     };
};

export const setAccountListeners = (listeners) => {
     return {
          type: SET_ACCOUNT_LISTENERS,
          payload: listeners,
     };
};

export const setActiveListeners = (listeners) => {
     return {
          type: SET_ACTIVE_LISTENERS,
          payload: listeners,
     };
};

export const setActiveListener = (payload) => {
     return {
          type: SET_ACTIVE_LISTENER,
          payload,
     };
};

export const setAccountDestination = (payload) => {
     return {
          type: SET_ACCOUNT_DESTINATION,
          payload,
     };
};

export const setUsedItems = ({ itemName, quantity, accountId }) => {
     return {
          type: SET_USED_ITEMS,
          payload: { itemName, quantity, accountId },
     };
};

export const setCustomVariables = (customVariables) => {
     return {
          type: SET_LIST_CUSTOM_VARIABLES,
          payload: customVariables,
     };
};

export const setBrowserVariables = (browserVariables) => {
     return {
          type: SET_LIST_BROWSER_VARIABLES,
          payload: browserVariables,
     };
};

export const setFeatures = (features) => {
     return {
          type: SET_FEATURES,
          payload: features,
     };
};

export const setCustomVariableRules = (customVariableRules) => {
     return {
          type: SET_CUSTOM_VARIABLE_RULES,
          payload: customVariableRules,
     };
};

export const setUserSourceRules = (rules) => {
     return {
          type: SET_USER_SOURCE_RULES,
          payload: rules,
     };
};

export const setInitialUserSourceRules = (initialUserSourceRules) => {
     return {
          type: SET_INITIAL_USER_SOURCE_RULES,
          payload: initialUserSourceRules,
     };
};

export const setTriggerRules = (rules) => {
     return {
          type: SET_TRIGGER_RULES,
          payload: rules,
     };
};
export const setTriggerEventEcommerce = (trigger) => {
     return {
          type: SET_TRIGGER_EVENT_ECOMMERCE,
          payload: trigger,
     };
};

export const setInitialTriggerRules = (initial) => {
     return {
          type: SET_INITIAL_TRIGGER_RULES,
          payload: initial,
     };
};

export const setTriggersName = (rules) => {
     return {
          type: SET_TRIGGERS_NAME_RULES,
          payload: rules,
     };
};

export const setConversionsName = (rules) => {
     return {
          type: SET_CONVERSIONS_NAME_RULES,
          payload: rules,
     };
};

export const setConversionRules = (rules) => {
     return {
          type: SET_CONVERSION_RULES,
          payload: rules,
     };
};

export const setInitialConversionRules = (initial) => {
     return {
          type: SET_INITIAL_CONVERSION_RULES,
          payload: initial,
     };
};

export const setDataActionRules = (datas) => {
     return {
          type: SET_DATA_ACTIONS_RULES,
          payload: datas,
     };
};

export const setAllDataActionRules = (rules) => {
     return {
          type: SET_ALL_DATA_ACTION_RULES,
          payload: rules,
     };
};

export const setInitialCustomVariableRules = (initialCustomVariableRules) => {
     return {
          type: SET_INITIAL_CUSTOM_VARIABLE_RULES,
          payload: initialCustomVariableRules,
     };
};

export const setRedirectRules = (data) => {
     return {
          type: SET_REDIRECT_RULES,
          payload: data,
     };
};

export const setListenerEvents = (events) => {
     return {
          type: SET_LISTENER_EVENTS,
          payload: events,
     };
};

export const setListenerEventsLoading = (payload) => {
     return {
          type: SET_LISTENER_EVENTS_LOADING,
          payload: payload,
     };
};

export const setListenerEventsNative = (events) => {
     return {
          type: SET_LISTENER_EVENTS_NATIVE,
          payload: events,
     };
};

export const setNativeBrowserStorage = (events) => {
     return {
          type: SET_NATIVE_BROWSER_STORAGE,
          payload: events,
     };
};

export const setListenerVariables = (variables) => {
     return {
          type: SET_LISTENER_VARIABLES,
          payload: variables,
     };
};

export const setListenerSelectors = (selectors) => {
     return {
          type: SET_LISTENER_SELECTORS,
          payload: selectors,
     };
};

export const setVideoProgresses = (videoProgresses) => {
     return {
          type: SET_VIDEO_PROGRESSES,
          payload: videoProgresses,
     };
};

export const setVideoEngagements = (videoEngagements) => {
     return {
          type: SET_VIDEO_ENGAGEMENTS,
          payload: videoEngagements,
     };
};

export const setEventOptions = (options) => {
     return {
          type: SET_EVENT_OPTIONS,
          payload: options,
     };
};

export const setCustomReport = (reports) => {
     return {
          type: SET_CUSTOM_REPORT,
          payload: reports,
     };
};

export const setSavedReport = (payload) => {
     return {
          type: SET_SAVED_REPORT,
          payload: payload,
     };
};

export const setNativeECommerce = (payload) => {
     return {
          type: SET_NATIVE_ECOMMERCE,
          payload: payload,
     };
};

export const setFeatureInstallGoal = (payload) => {
     return {
          type: SET_FEATURE_INSTALL_GOALS,
          payload,
     };
};

export const setCompleteStepSetupGuide = (payload) => {
     return {
          type: SET_COMPLETE_SETUP_GUIDE,
          payload,
     };
};

export const setListChannels = (channels) => {
     return {
          type: SET_LIST_CHANNELS,
          payload: channels,
     };
};

export const setCustomUserSources = (customUserSources) => {
     return {
          type: SET_CUSTOM_USER_SOURCES,
          payload: customUserSources,
     };
};

export const setListUserSources = (userSources) => {
     return {
          type: SET_LIST_USER_SOURCES,
          payload: userSources,
     };
};

export const setSalesforceSearchLogs = (key, value) => {
     return {
          type: SET_SALESFORCE_SEARCH_LOGS,
          payload: {
               key,
               value
          },
     };
};

export const resetSalesforceSearchLogs = () => {
     return {
          type: RESET_SALESFORCE_SEARCH_LOGS,
     };
};

export const callAPIGetInstallAndLearn = (activeAccountId, finallyNext = (f) => f) => {
     return (dispatch) => {
          callTokenApi(`${API_CLIENT_ACCOUNT_COMPLETED_CHILD_SETUP_GUIDE}/${activeAccountId}`, 'POST', { step: `installAndLearn` })
               .then((response) => {
                    if (response.status === 200) {
                         let result = JSON.parse(response.data.data);
                         let obj = {};
                         for (let key of result) {
                              obj[key.replace(/\s/g, '')] = true;
                         }
                         dispatch(setFeatureInstallGoal(obj));
                    } else {
                         toastError(response);
                    }
               })
               .finally(() => finallyNext());
     };
};

export const setFeatureforCategorizationAndWebConversion = (payload) => {
     return {
          type: SET_FEATURE_FORM_CONVERSION,
          payload: payload,
     };
};

export const setFeaturedataEnrichment = (payload) => {
     return {
          type: SET_FEATURE_LEARN_DATA,
          payload: payload,
     };
};

export const setFeatureLearnSendingData = (payload) => {
     return {
          type: SET_FEATURE_LEARN_SEDING_DATA,
          payload: payload,
     };
};

export const setFeatureDestination = (payload) => {
     return {
          type: SET_FEATURE_CONFIGURE_DESTINATION,
          payload: payload,
     };
};

export const setFeaturecustomImplementation = (payload) => {
     return {
          type: SET_FEATURE_CUSTOM_OPTINAL,
          payload: payload,
     };
};

// export const setScopesReport = (scopes) => {
//      return {
//           type: SET_SCOPES_REPORT,
//           payload: scopes
//      }
// }

export const setEventNativeOptions = (options) => {
     return {
          type: SET_EVENT_NATIVE_OPTIONS,
          payload: options,
     };
};

export const setEventChart = (chartData) => {
     return {
          type: SET_EVENT_CHART,
          payload: chartData,
     };
};

export const setOptionsChart = (chartOptions) => {
     return {
          type: SET_CHART_OPTIONS,
          payload: chartOptions,
     };
};

export const setAllEvents = (events) => {
     return {
          type: SET_ALL_EVENTS,
          payload: events,
     };
};

export const setVariableOptions = (options) => {
     return {
          type: SET_VARIABLE_OPTIONS,
          payload: options,
     };
};

export const setSelectorOptions = (options) => {
     return {
          type: SET_SELECTOR_OPTIONS,
          payload: options,
     };
};

export const setCustomVariableOptions = (options) => {
     return {
          type: SET_CUSTOM_VARIABLE_OPTIONS,
          payload: options,
     };
};

export const setBrowserVariableOptions = (options) => {
     return {
          type: SET_BROWSER_VARIABLE_OPTIONS,
          payload: options,
     };
};

export const setVersionPublish = (options) => {
     return {
          type: SET_VERSION_PUBLISH,
          payload: options,
     };
};

export const setVersions = (options) => {
     return {
          type: SET_VERSIONS,
          payload: options,
     };
};

export const setLoadings = (loadings) => {
     return {
          type: SET_LOADINGS,
          payload: loadings,
     };
};

export const setToastStatus = (data) => {
     return {
          type: SET_TOAST_IS_OPEN,
          payload: data,
     };
};

export const setFilterShowEnabledFeatures = (data) => {
     return {
          type: SET_FILTER_SHOW_ENABLED_FEATURES,
          payload: data,
     };
};

export const setLookupRuleActiveStep = (step) => {
     return {
          type: SET_LOOKUP_RULE_ACTIVE_STEP,
          payload: step,
     };
};

export const setAcrossDomains = (acrossDomains) => {
     return {
          type: SET_ACROSS_DOMAINS,
          payload: acrossDomains,
     };
};

export const setClickIds = (payload) => {
     return {
          type: SET_CLICK_IDS,
          payload,
     };
};

export const resetSubscriberReducer = () => {
     return {
          type: RESET_SUBSCRIBER_REDUCER,
     };
};

export const setLimitationType = (limitationType, saveLimitationToLocal = true) => {
     if (saveLimitationToLocal) {
          // Save limitation type to local
          localStorage.setItem(LOCAL_TEMP_LIMITATION, limitationType);
     }

     return {
          type: SET_LIMITATION_TYPE,
          payload: limitationType,
     };
};

export const resetLimitationType = () => {
     return {
          type: RESET_LIMITTAION_TYPE,
     };
};

export const setShowHamburgerIcon = (show) => {
     return {
          type: SET_SHOW_HAMBURGER_ICON,
          payload: show,
     };
};

export const setShowCreateAccountPopup = (show) => {
     return {
          type: SET_SHOW_CREATE_ACCOUNT_POPUP,
          payload: show,
     };
};

export const setShowAccountDormantPopup = (show) => {
     return {
          type: SET_SHOW_ACCOUNT_DORMANT_POPUP,
          payload: show,
     };
};

export const setShowUpgradeAccountPopup = (show) => {
     return {
          type: SET_SHOW_UPGRADE_ACCOUNT_POPUP,
          payload: show,
     };
};

export const setShowSetupGuidePopup = (show) => {
     return {
          type: SET_SHOW_BLOCK_SETUP_GUIDE_POPUP,
          payload: show,
     };
};

export const setShowUpgradeAccountSchedulePopup = (show) => {
     return {
          type: SET_SHOW_UPGRADE_ACCOUNT_SCHEDULE_POPUP,
          payload: show,
     };
};

export const setShowComparePlansPopup = (show) => {
     return {
          type: SET_SHOW_COMPARE_PLANS_POPUP,
          payload: show,
     };
};

export const setShowBlockAccountPopup = (show) => {
     return {
          type: SET_SHOW_BLOCK_ACCOUNT_POPUP,
          payload: show,
     };
};

export const setAccountPastdue = (data) => {
     return {
          type: SET_ACCOUNT_PASTDUE,
          payload: data,
     };
};

export const setInvoices = (invoices) => {
     return {
          type: SET_INVOICES,
          payload: invoices,
     };
};

export const setAutomaticVariableReload = (reload) => {
     return {
          type: SET_AUTOMATIC_VARIABLE_RELOAD,
          payload: reload,
     };
};

export const setSelectorVariableReload = (reload) => {
     return {
          type: SET_SELECTOR_VARIABLE_RELOAD,
          payload: reload,
     };
};

export const setGlobalReporting = (data) => {
     return {
          type: SET_GLOBAL_REPORTING,
          payload: data,
     };
};

export const setActiveFeatures = (activeFeatures) => {
     return {
          type: SET_ACTIVE_FEATURES,
          payload: activeFeatures,
     };
};

export const setEditingRuleReload = (reload) => {
     return {
          type: SET_EDITING_RULE_RELOAD,
          payload: reload,
     };
};

export const setBrowserRules = (rules) => {
     return {
          type: SET_BROWSER_RULES,
          payload: rules,
     };
};

export const setPopulateRules = (rules) => {
     return {
          type: SET_POPULATE_RULES,
          payload: rules,
     };
};

export const setMonitoringRules = (rules) => {
     return {
          type: SET_MONITORING_RULES,
          payload: rules,
     };
};

export const toggleAsideLimitationHit = () => {
     return {
          type: TOGGLE_ASIDE_LIMITATION_HIT,
     };
};

export const setShowEventReportingWarning = (show) => {
     return {
          type: SET_SHOW_EVENT_REPORTING_WARNING,
          payload: show,
     };
};

export const setEventTable = (data) => {
     return {
          type: SET_EVENT_TABLE,
          payload: data,
     };
};

export const setEventTableOfListeners = (data) => {
     return {
          type: SET_EVENT_TABLE_OF_LISTENERS,
          payload: data,
     };
};

export const setVariableValueChart = (data) => {
     return {
          type: SET_VARIABLE_VALUE_CHART,
          payload: data,
     };
};

export const setAutomaticVariableWarning = (listenerId = '', show = false) => {
     return {
          type: SET_AUTOMATIC_VARIABLE_WARNING,
          payload: { listenerId, show },
     };
};

export const setEstimatedTotalEvents = (data) => {
     return {
          type: SET_ESTIMATED_TOTAL_EVENTS,
          payload: data,
     };
};

export const setUnsavedLookupTableModal = (data) => {
     return {
          type: SET_UNSAVED_LOOKUP_TABLE_MODAL,
          payload: data,
     };
};

export const saveTempPublishData = () => {
     return { type: SAVE_TEMP_PUBLISH_DATA };
};

export const revertPublishData = () => {
     return { type: REVERT_PUBLISH_DATA };
};

export const setNativeBrowserAccountFeatures = (data) => {
     return {
          type: SET_NATIVE_BROWSER_ACCOUNT_FEATURES,
          payload: data,
     };
};

export const setIsCustomerSupportLoginAs = (isLoginAs) => {
     return {
          type: SET_IS_CUSTOMER_SUPPORT_LOGIN_AS,
          payload: isLoginAs,
     };
};

export const setMyCreatedTickets = (tickets) => {
     return {
          type: SET_MY_CREATED_TICKETS,
          payload: tickets,
     };
};

export const setOtherTickets = (tickets) => {
     return {
          type: SET_OTHER_TICKETS,
          payload: tickets,
     };
};

export const setSubscriberTicket = (ticket) => {
     return {
          type: SET_SUBSCRIBER_TICKET,
          payload: ticket,
     };
};

export const setListenersInTicket = (listeners) => {
     return {
          type: SET_LISTENERS_IN_TICKET,
          payload: listeners,
     };
};

export const setNumberOfComments = (num) => {
     return {
          type: SET_NUMBER_OF_COMMENT_TICKETS,
          payload: num,
     };
};

export const setTicketInit = (payload) => {
     return {
          type: SET_TICKET_INIT,
          payload,
     };
};

export const setShowMasterclassAside = (show) => {
     return {
          type: SET_SHOW_MASTERCLASS_ASIDE,
          payload: show,
     };
};

export const setBuyOfficeHours = (show) => {
     return {
          type: SET_BUY_OFFICE_HOURS,
          payload: show,
     };
};

export const setMasterclassCourse = (course) => {
     return {
          type: SET_MASTERCLASS_COURSE,
          payload: course,
     };
};

export const setMasterclassCertification = (certification) => {
     return {
          type: SET_MASTERCLASS_CERTIFICATION,
          payload: certification,
     };
};

export const setOfficeHour = (officeHour) => {
     return {
          type: SET_OFFICE_HOUR,
          payload: officeHour,
     };
};

export const setAvailableHours = (availableHours) => {
     return {
          type: SET_AVAILABLE_HOURS,
          payload: availableHours,
     };
};

export const setAccountRedeemedCoupons = (redeemedCoupons) => {
     return {
          type: SET_ACCOUNT_REDEEMED_COUPONS,
          payload: redeemedCoupons,
     };
};

export const setOfficeHourCalendar = (officeHourCalendar) => {
     return {
          type: SET_OFFICE_HOUR_CALENDAR,
          payload: officeHourCalendar,
     };
};

export const setLessonVideoRef = (ref) => {
     return {
          type: SET_LESSON_VIDEO_REF,
          payload: ref,
     };
};

export const setWeatherTemperature = (payload) => {
     return {
          type: SET_DATA_WEATHER_TEMPERATURE,
          payload: payload,
     };
};

export const setCustomFormTargetRules = (targetRules) => {
     return {
          type: SET_CUSTOM_FORM_TARGET_RULES,
          payload: targetRules,
     };
};

export const setElementClickTargetRules = (targetRules) => {
     return {
          type: SET_ELEMENT_CLICK_TARGET_RULES,
          payload: targetRules,
     };
};

export const setClickRules = (settings) => {
     return {
          type: SET_CLICK_RULES,
          payload: settings,
     };
};

export const setInitialClickRules = (settings) => {
     return {
          type: SET_INITIAL_CLICK_RULES,
          payload: settings,
     };
};

export const setMonitoringNotifications = (settings) => {
     return {
          type: SET_MONITORING_NOTIFICATIONS,
          payload: settings,
     };
};

export const setShowArchiveNotifications = (settings) => {
     return {
          type: SET_SHOW_ARCHIVE_NOTIFICATIONS,
          payload: settings,
     };
};

export const setVisitorsSessionsUnique = (data) => {
     return {
          type: SET_VISITOR_SESSIONS_UNIQUE,
          payload: data,
     };
};

export const setShowPublishPopup = (show) => {
     return {
          type: SET_SHOW_PUBLISH_POPUP,
          payload: show,
     };
};

export const setShowGetHelpLabel = (show) => {
     return {
          type: SET_SHOW_GET_HELP_LABEL,
          payload: show,
     };
};

export const setGTMContainers = (containers) => {
     return {
          type: SET_GTM_CONTAINERS,
          payload: containers,
     };
};

export const setIsFromGetStarted = (isFromGetStarted) => {
     return {
          type: SET_IS_FROM_GET_STARTED,
          payload: isFromGetStarted,
     };
};

export const setTogglePreviewPopup = (show) => {
     return {
          type: SET_TOGGLE_PREVIEW_POPUP,
          payload: show,
     };
};

export const setScrollSidebar = (data) => {
     return {
          type: SET_SCROLL_SIDEBAR,
          payload: data,
     };
};

export const setDomainsPreview = (domains) => {
     return {
          type: SET_DOMAINS_PREVIEW,
          payload: domains,
     };
};

export const setDomainPreview = (domain) => {
     return {
          type: SET_DOMAIN_PREVIEW,
          payload: domain,
     };
};

export const setTurnOffPreview = () => {
     return {
          type: SET_TURNOFF_PREVIEW_POPUP,
     };
};

export const setShowPopupDomain = (value) => {
     return {
          type: SET_POPUP_DOMAIN_PREVIEW,
          payload: value,
     };
};

export const setShowPopupConfigConversion = (value) => {
     return {
          type: SET_SHOW_POPUP_CONFIG_CONVERSION,
          payload: value,
     };
};

export const setShowCreateVariable = (value) => {
     return {
          type: SET_POPUP_CREATE_VARIABLE,
          payload: value,
     };
};
export const setNewCustomVariable = (value) => {
     return {
          type: SET_NEW_CUSTOM_VARIABLE,
          payload: value,
     };
};
export const setShowCreateBrowserVariable = (value) => {
     return {
          type: SET_POPUP_CREATE_BROWSER_VARIABLE,
          payload: value,
     };
};
export const setShowConfigurationRequire = (value) => {
     return {
          type: SET_POPUP_CONFIGURATION_REQUIRED,
          payload: value,
     };
};
export const setShowPopupConfirmAddScript = (value) => {
     return {
          type: SET_POPUP_CONFIRM_ADD_SCRIPT,
          payload: value,
     };
};
export const setConfirmAddScript = (value) => {
     return {
          type: SET_CONFIRM_ADD_SCRIPT,
          payload: value,
     };
};

export const setListenerPreview = (value) => {
     return {
          type: SET_LISTENER_PREVIEW,
          payload: value,
     };
};

export const setAccountPreview = (accountId) => {
     return {
          type: SET_ACCOUNT_PREVIEW,
          payload: accountId,
     };
};

export const setNewLayout = (payload) => {
     return {
          type: SET_NEW_LAYOUT,
          payload,
     };
};

export const setPreviewOptions = (payload) => {
     return {
          type: SET_PREVIEW_OPTIONS,
          payload,
     };
};

export const resetPreview = () => {
     return {
          type: RESET_PREVIEW,
     };
};

export const setManageGoalTypes = (payload) => {
     return {
          type: SET_MANAGE_GOALS_TYPES,
          payload,
     };
};

export const resetAllPreview = (accountId, previewDestinationKey) => {
     return (dispatch) => {
          console.clear();
          dispatch(resetPreview());
          localStorage.removeItem(LOCAL_PREVIEW);
          localStorage.removeItem(LOCAL_PREVIEW_DOMAIN);
          localStorage.removeItem(previewDestinationKey);
          for (var i = 0; i < localStorage.length; i++) {
               const localKey = localStorage.key(i);

               if (localKey.includes('llEventPreview') && localKey.includes(accountId)) {
                    localStorage.removeItem(localKey);
               }
          }
     };
};

export const setGTMWizard = (data = {}) => {
     return {
          type: SET_GTM_WIZARD,
          payload: data,
     };
};

export const setGTMVariablesInLL = (data) => {
     return {
          type: SET_GTM_VARIABLES_IN_LL,
          payload: data,
     };
};

export const setGTMTriggersInLL = (data) => {
     return {
          type: SET_GTM_TRIGGERS_IN_LL,
          payload: data,
     };
};

export const setGTMTagsInLL = (data) => {
     return {
          type: SET_GTM_TAGS_IN_LL,
          payload: data,
     };
};

export const setGTMChangelog = (changelog) => {
     return {
          type: SET_GTM_CHANGELOG,
          payload: changelog,
     };
};

export const setSwitchTypeWizard = (payload = {}) => {
     return {
          type: SET_SWITCH_TYPE_WIZARD,
          payload,
     };
};

export const setAccordion = (data) => {
     return {
          type: SET_ACCORDION,
          payload: data,
     };
};

export const reloadDataActions = () => {
     return {
          type: RELOAD_DATA_ACTIONS,
     };
};

export const setPreviewBrowserRule = (show) => {
     return {
          type: SET_PREVIEW_BROWSER_RULE,
          payload: show,
     };
};

export const setPreviewCustomRule = (show) => {
     return {
          type: SET_PREVIEW_CUSTOM_RULE,
          payload: show,
     };
};

export const setTour = (payload) => {
     return {
          type: SET_TOUR,
          payload: payload,
     };
};

export const reloadTriggerRules = () => {
     return {
          type: RELOAD_TRIGGER_RULES,
     };
};
export const reloadUserSource = () => {
     return {
          type: RELOAD_USER_SOURCE,
     };
};

export const reloadConversionRules = () => {
     return {
          type: RELOAD_CONVERSION_RULES,
     };
};

export const setReloadDestinationConversions = () => {
     return {
          type: RELOAD_DESTINATION_CONVERSIONS,
     };
};

export const setReloadDestinationEcommerce = () => {
     return {
          type: RELOAD_DESTINATION_ECOMMERCE,
     };
};

export const setReloadLookupTable = () => {
     return {
          type: RELOAD_LOOKUP_TABLE,
     };
};

export const setCreatedAccount = (account) => {
     return {
          type: SET_CREATED_ACCOUNT,
          payload: account,
     };
};

export const setSignUpStep = (payload) => {
     return {
          type: SET_SIGN_UP_STEP,
          payload: payload,
     };
};

export const setGTMAccounts = (accounts) => {
     return {
          type: SET_GTM_ACCOUNTS,
          payload: accounts,
     };
};

export const setInitialPersonalData = (personalData) => {
     return {
          type: SET_INITIAL_PERSONAL_DATA,
          payload: personalData,
     };
};

export const setCustomDestinationModal = (data = {}) => {
     return {
          type: SET_CUSTOM_DESTINATION_MODAL,
          payload: data,
     };
};

export const setDormantAccount = (payload) => {
     return {
          type: SET_DORMANT_ACCOUNT,
          payload,
     };
};

export const setSetupGuideRoadmapIncrease = (payload) => {
     return {
          type: SET_SETUP_GUIDE_ROADMAP_INCREASE,
          payload,
     };
};

export const setSetupGuideRoadmapDecrease = (payload) => {
     return {
          type: SET_SETUP_GUIDE_ROADMAP_DECREASE,
          payload,
     };
};

export const setSetupGuideRoadmap = (payload) => {
     return {
          type: SET_SETUP_GUIDE_ROADMAP,
          payload,
     };
};

export const setShowVideo = (payload) => {
     return {
          type: SET_SHOW_VIDEO,
          payload,
     };
};
export const setShowIgnoredForm = (showIgnoredForm) => {
     return {
          type: SET_SHOW_IGNORED_FORM,
          payload: showIgnoredForm,
     };
};

export const setShowIgnoredInventoryForm = (showIgnoredInventoryForm) => {
     return {
          type: SET_SHOW_IGNORED_INVENTORY_FORM,
          payload: showIgnoredInventoryForm,
     };
};

export const setFormInherit = (applyChild) => {
     return {
          type: SET_APPLY_CHILD,
          payload: applyChild,
     };
};

export const setFormRedirect = (applyRedirect) => {
     return {
          type: SET_APPLY_REDIRECT,
          payload: applyRedirect,
     };
};

export const setFormCategory = (formCategory) => {
     return {
          type: SET_FORM_CATEGORY,
          payload: formCategory,
     };
};

export const setFormRequiringAction = (forms) => {
     return {
          type: SET_FORM_REQUIRING_ACTION,
          payload: forms,
     };
};

export const setAllFormsRequiring = (forms) => {
     return {
          type: FORM_REQUIRING_SET_ALL_FORMS,
          payload: forms,
     };
};

export const setCountFormRequiring = (countFormRequiring) => {
     return {
          type: SET_COUNT_FORM_REQUIRING,
          payload: countFormRequiring,
     };
};

export const setFormIgnored = (formIgnored) => {
     return {
          type: SET_FORM_IGNORED,
          payload: formIgnored,
     };
};

export const setCountFormIgnored = (countFormIgnored) => {
     return {
          type: SET_COUNT_FORM_IGNORED,
          payload: countFormIgnored,
     };
};

export const setFormInventory = (formInventory) => {
     return {
          type: SET_FORM_INVENTORY,
          payload: formInventory,
     };
};

export const setCountFormInventory = (countFormInventory) => {
     return {
          type: SET_COUNT_FORM_INVENTORY,
          payload: countFormInventory,
     };
};

export const setCustomCategories = (customCategories) => {
     return {
          type: SET_CUSTOM_CATEGORY,
          payload: customCategories,
     };
};

export const setShowIframeCustomForms = (showIframe) => {
     return {
          type: SET_SHOW_IFRAME_CUSTOM_FORMS,
          payload: showIframe,
     };
};

export const setListFeatureCustomForms = (listFeatureCustomForms) => {
     return {
          type: SET_LIST_FEATURE_CUSTOM_FORMS,
          payload: listFeatureCustomForms,
     };
};

export const setShowCustomForms = (isShowCustomForm) => {
     return {
          type: SET_SHOW_CUSTOM_FORM,
          payload: isShowCustomForm,
     };
};

export const setShowCategoryForms = (isShowCategoryForm) => {
     return {
          type: SET_SHOW_CATEGORY_FORM,
          payload: isShowCategoryForm,
     };
};

export const setStepFormCategoryFormTabWebConversion = (isFromWebConversion) => {
     return {
          type: SET_STEP_FORM_CATEGORY_FORM_WEB_CONVERSION,
          payload: isFromWebConversion,
     };
};

export const setOpenFromConfigConversion = (payload) => {
     return {
          type: SET_OPEN_FROM_CONFIG_CONVERSION,
          payload: payload,
     };
};

export const setDataSetupGuideDataCompleted = (data) => {
     return {
          type: SET_DATA_SETUP_GUIDE_COMPLETED,
          payload: data,
     };
};

export const setParentSetupGuideDataCompleted = (data) => {
     return {
          type: SET_PARENT_SETUP_GUIDE_COMPLETED,
          payload: data,
     };
};

export const setChildSetupGuideDataCompleted = (data) => {
     return {
          type: SET_DATA_CHILD_SETUP_GUIDE_COMPLETED,
          payload: data,
     };
};

export const setShowRoadMap = (data) => {
     return {
          type: SET_SHOW_ROADMAP,
          payload: data,
     };
};

export const setShowTourFormRoadMap = (showTourFromRoadMap) => {
     return {
          type: SET_SHOW_TOUR_FROM_ROADMAP,
          payload: showTourFromRoadMap,
     };
};

export const setShowTourCompletedWizard = (showTourCompletedWizard) => {
     return {
          type: SET_SHOW_TOUR_COMPETED_WIZARD,
          payload: showTourCompletedWizard,
     };
};
export const setListFormsChild = (forms) => {
     return {
          type: FORM_CATEGORY_SET_LIST_FORM_CHILDS,
          payload: forms,
     };
};

export const updateChildFormCategory = (forms) => {
     return {
          type: FORM_CATEGORY_UPDATE_FORM_CHILD,
          payload: forms,
     };
};

export const setFormInventoryEdit = (forms) => {
     return {
          type: FORM_CATEGORY_SET_FORM_EDIT,
          payload: forms,
     };
};

export const setFormRequiringEdit = (forms) => {
     return {
          type: FORM_REQUIRING_SET_FORM_EDIT,
          payload: forms,
     };
};

export const setFewFormsEdit = (forms) => {
     return {
          type: FEW_FORMS_SET_FORM_EDIT,
          payload: forms,
     };
};

export const setFormsDraft = (forms) => {
     return {
          type: SET_FORM_DRAFT,
          payload: forms,
     };
};

export const setNumberOffsetFormsCategories = (number) => {
     return {
          type: FORM_CATEGORY_SET_NUMBER_OFFSET,
          payload: number,
     };
};

export const setAllFormsInventory = (forms) => {
     return {
          type: FORM_CATEGORY_SET_ALL_FORMS_INVENTORY,
          payload: forms,
     };
};

export const setAllFormsIgnore = (forms) => {
     return {
          type: FORM_CATEGORY_SET_ALL_FORMS_IGNORE,
          payload: forms,
     };
};
export const setFormIgnoreEdit = (forms) => {
     return {
          type: FORM_IGNORE_SET_FORM_EDIT,
          payload: forms,
     };
};

export const setFilterFormCategories = (filters) => {
     return {
          type: FORM_CATEGORY_SET_FILTERS,
          payload: filters,
     };
};

export const setFilterEditFormCategories = (filters) => {
     return {
          type: FORM_CATEGORY_SET_FILTERS_EDIT,
          payload: filters,
     };
};

export const setReduxActiveDropdown = (payload) => {
     return {
          type: SET_REDUX_ACTIVE_DROPDOWN,
          payload,
     };
};

export const setLoadFormChildRequiring = (formChildId) => {
     return {
          type: SET_LOAD_FORM_CHILD_REQUIRING,
          payload: formChildId,
     };
};

export const setSkipFormListener = (payload) => {
     return {
          type: SET_SKIP_FORM_LISTENER,
          payload,
     };
};

export const updateMonitoringFormCategory = (payload) => {
     return {
          type: SET_MONITORING_FORM_CATEGORY,
          payload,
     };
};

export const setMaxStepForm = (payload) => {
     return {
          type: SET_MAX_STEP_FORM,
          payload,
     };
};

export const setAccountExternalDataSources = (sources) => {
     return {
          type: SET_ACCOUNT_EXTERNAL_DATA_SOURCES,
          payload: sources,
     };
};

export const setMaxStepRecordProfile = (payload) => {
     return {
          type: SET_MAX_STEP_RECORD_PROFILE,
          payload,
     };
};
export const setCustomerIdsResponse = (customerIds) => {
     return {
          type: SET_CUSTOMER_IDS_RESPONSE,
          payload: customerIds,
     };
};

export const setGoogleAdsAccounts = (googleAdsAccounts) => {
     return {
          type: SET_GOOGLE_ADS_ACCOUNTS,
          payload: googleAdsAccounts,
     };
};

export const setSalesforceAccounts = (salesforceAccounts) => {
     return {
          type: SET_SALESFORCE_ACCOUNTS,
          payload: salesforceAccounts,
     };
};

export const setRoutes = (routes) => {
     return {
          type: SET_ROUTES,
          payload: routes,
     };
};

export const setTermPublish = (termId) => {
     return {
          type: SET_TERM_PUBLISH,
          payload: termId,
     };
};

export const setUserTerms = (userTerms) => {
     return {
          type: SET_USER_TERMS,
          payload: userTerms,
     };
};

export const setEngagementRules = (rules) => {
     return {
          type: SET_ENGAGEMENT_RULES,
          payload: rules,
     };
};

export const setDateRangeReports = (payload) => {
     return {
          type: SET_DATE_REPORTS,
          payload: payload,
     };
};

export const setDateRangeRecord = (payload) => {
     return {
          type: SET_DATE_RANGE_RECORD,
          payload: payload,
     };
};

export const setWidthColumnReports = (payload) => {
     return {
          type: SET_WIDTH_COLUMN_REPORTS,
          payload: payload,
     };
};

export const setShowHistoricalPopup = (payload) => {
     return {
          type: SET_SHOW_HISTORICAL_POPUP,
          payload: payload,
     };
};

export const setDataHistoricalSalesforce = (payload) => {
     return {
          type: SET_DATA_HISTORICAL_SALESFORCE,
          payload: payload,
     };
};
export const setReloadReportData = (payload) => {
     return {
          type: SET_REDUX_RELOAD_TABLE_REPORT,
          payload: payload,
     };
};

export const setReloadReportChart = (payload) => {
     return {
          type: SET_REDUX_RELOAD_TABLE_CHART,
          payload: payload,
     };
};

export const setSalesforceRules = (salesforceRules) => {
     return {
          type: SET_SALESFORCE_RULES,
          payload: salesforceRules,
     };
};

export const setSalesforceRulesPaging = (data) => {
     return {
          type: SET_SALESFORCE_RULES_PAGING,
          payload: data,
     };
};

export const setReloadAccountSalesforce = (payload) => {
     return {
          type: SET_RELOAD_ACCOUNT_SALESFORCE,
     };
};

export const setLoadingFormIgnore = (loadingFormIgnore) => {
     return {
          type: SET_LOADING_FORM_IGNORE,
          payload: loadingFormIgnore,
     };
};

export const setScheduleSavedReport = (payload) => {
     return {
          type: SET_SCHEDULE_SAVED_REPORT,
          payload
     }
}

export const fetchUserAccounts = (accountId, isIdOfActiveAccount, finallyNext = (f) => f) => {
     return (dispatch) => {
          dispatch(setLoadings({ accounts: true }));
          callTokenApi(`${API_CLIENT_ACCOUNTS}`, 'GET')
               .then((response) => {
                    if (response.status === 200) {
                         const { accounts } = response.data;
                         const activeAccount = findAccount(accountId, accounts);

                         if (!isIdOfActiveAccount) {
                              dispatch(resetSubscriberReducer());
                         }

                         dispatch(setAccounts(accounts));
                         dispatch(setActiveAccount(activeAccount));
                         dispatch(fetchMonitoringNotifications(activeAccount.accountId));
                         localStorage.setItem('activeAccountId', activeAccount.accountId);

                         if (!activeAccount.isBlocked) {
                              dispatch(setShowBlockAccountPopup(false));
                         }
                    } else {
                         toastError(response);
                    }
               })
               .finally(() => {
                    dispatch(setLoadings({ accounts: false }));
                    finallyNext();
               });
     };
};

export const fetchAccountUsers = (accountId, finallyNext = (f) => f) => {
     return (dispatch) => {
          callTokenApi(`${GET_USERS}/${accountId}`, 'GET')
               .then((response) => {
                    if (response.status === 200) {
                         dispatch(setOwner(response.data.user.owner));
                         dispatch(setManagers(response.data.user.managers));

                         const newListManagerBillingRight = response.data.user.managers.map((manager) => {
                              return {
                                   userId: manager.userId,
                                   billingRight: manager.billingRight,
                              };
                         });
                         dispatch(setSubscriberState({ listManagerBillingRight: newListManagerBillingRight }));
                    } else {
                         toastError(response);
                    }
               })
               .finally(() => finallyNext());
     };
};

export const handleCompleteOwnershipTransfer = ({ accountId, userId, fetchUserAccount = true }, finallyNext = (f) => f) => {
     return (dispatch) => {
          callTokenApi(COMPLETE_OWNERSHIP_TRANFER, 'POST', { accountId, userId }).then((response) => {
               if (response.status === 200) {
                    toast.success('Ownership of this account has been transferred to you');
                    dispatch(fetchAccountUsers(accountId));

                    if (fetchUserAccount) {
                         dispatch(fetchUserAccounts(accountId, true, finallyNext));
                    } else {
                         finallyNext();
                    }
               } else {
                    toastError(response);
               }
          });
     };
};

export const fetchAnAccount = (account, accounts, next = (f) => f) => {
     return (dispatch) => {
          dispatch(setLoadings({ account: true }));

          callTokenApi(`${API_CLIENT_ACCOUNT}${account.id}`, 'GET', null)
               .then((response) => {
                    if (response.status === 200) {
                         let newAccounts = [...accounts];
                         const accountIndex = findAccountIndex(accounts, account.id);
                         newAccounts[accountIndex] = { ...account, ...response.data.account };

                         dispatch(setAccounts(newAccounts));
                         dispatch(setActiveAccount({ ...newAccounts[accountIndex] }));
                         localStorage.setItem('activeAccountId', newAccounts[accountIndex].accountId);
                         next();
                    } else {
                         dispatch(setActiveAccount(account));
                         localStorage.setItem('activeAccountId', account.accountId);
                         if (response.data.accountBlocked) {
                              dispatch(setShowBlockAccountPopup(true));
                         } else {
                              toastError(response);
                         }
                    }
               })
               .finally(() => dispatch(setLoadings({ account: false })));
     };
};

export const fetchListeners = (activeAccountId, secondId) => {
     return (dispatch) => {
          dispatch(setLoadings({ listeners: true, destinations: true }));

          if (activeAccountId) {
               callTokenApi(`${CLIENT_LISTENER}${activeAccountId}`, 'GET', null).then((response) => {
                    if (response.status === 200) {
                         const { accountListeners, accountDestinations, accountGoals } = response.data;
                         let enabledListeners = getEnabledListeners(accountListeners);
                         let enabledDestinations = getEnabledListeners(accountDestinations);
                         let goalsRoutes = getAccountGoalsRoute(accountGoals, secondId);
                         const specialFeaturesList = [
                              'myvalues',
                              'customvalues',
                              'browserstorage',
                              'monitoring',
                              'reporting',
                              'populate',
                              'usersource',
                              'selector',
                              ...LOWERCASE_CLICK_FEATURES,
                         ];

                         if (enabledListeners.length > 0) {
                              // Fetch active features
                              callTokenApi(`${API_CLIENT_FEATURE_CONFIG_MY_VALUE}${activeAccountId}`, 'GET', null)
                                   .then((response) => {
                                        if (response.status === 200) {
                                             const { accountFeatureActive } = response.data;

                                             // Get custom values, browser storage, monitoring and reporting features
                                             let specificFeatures = accountFeatureActive.filter((feature) => {
                                                  let strToCompare = feature.code.toLowerCase();

                                                  return specialFeaturesList.some((specialItem) => strToCompare.includes(specialItem));
                                             });

                                             let listenerRoutes = getListenerRoutes(enabledListeners, specificFeatures, secondId);
                                             if (enabledDestinations.length > 0) {
                                                  // Fetch active features
                                                  let destinationRoutes = getDestinationRoutes(enabledDestinations, secondId);
                                                  let routes = [...defaultSubscriberRoutes, ...destinationRoutes, ...listenerRoutes, ...goalsRoutes];

                                                  dispatch(
                                                       setSubscriberState({
                                                            routes,
                                                            accountDestinations,
                                                            activeDestinations: enabledDestinations,
                                                            accountListeners,
                                                            activeListeners: enabledListeners,
                                                            activeFeatures: accountFeatureActive,
                                                            accountGoals
                                                       })
                                                  );
                                             } else {
                                                  let routes = [...defaultSubscriberRoutes, ...listenerRoutes, ...goalsRoutes];

                                                  dispatch(
                                                       setSubscriberState({
                                                            routes,
                                                            accountListeners,
                                                            activeListeners: enabledListeners,
                                                            activeFeatures: accountFeatureActive,
                                                            accountDestinations,
                                                            activeDestinations: [],
                                                            accountGoals,
                                                       })
                                                  );
                                             }
                                        } else {
                                             let listenerRoutes = getListenerRoutes(enabledListeners, [], secondId);
                                             if (enabledDestinations.length > 0) {
                                                  // Fetch active features
                                                  let destinationRoutes = getDestinationRoutes(enabledDestinations, secondId);
                                                  let routes = [...defaultSubscriberRoutes, ...destinationRoutes, ...listenerRoutes, ...goalsRoutes];

                                                  dispatch(
                                                       setSubscriberState({
                                                            routes,
                                                            accountDestinations,
                                                            activeDestinations: enabledDestinations,
                                                            accountListeners,
                                                            activeListeners: enabledListeners,
                                                            accountGoals
                                                       })
                                                  );
                                             } else {
                                                  let routes = [...defaultSubscriberRoutes, ...listenerRoutes, ...goalsRoutes];

                                                  dispatch(
                                                       setSubscriberState({
                                                            routes,
                                                            accountListeners,
                                                            activeListeners: enabledListeners,
                                                            accountDestinations,
                                                            activeDestinations: [],
                                                            accountGoals
                                                       })
                                                  );
                                             }

                                             toastError(response);
                                        }
                                   })
                                   .finally(() => dispatch(setLoadings({ listeners: false, destinations: false })));
                         } else {
                              if (enabledDestinations.length > 0) {
                                   // Fetch active features
                                   let destinationRoutes = getDestinationRoutes(enabledDestinations, secondId);
                                   let routes = [...defaultSubscriberRoutes, ...destinationRoutes, ...accountGoals];

                                   dispatch(
                                        setSubscriberState({
                                             routes,
                                             accountDestinations,
                                             activeDestinations: enabledDestinations,
                                             accountListeners,
                                             activeListeners: [],
                                        })
                                   );
                              } else {
                                   // enabledListeners = [] and activeListeners default = [], so no need to set activeListeners
                                   dispatch(
                                        setSubscriberState({
                                             routes: [...defaultSubscriberRoutes, ...goalsRoutes],
                                             accountListeners,
                                             activeListeners: [],
                                             accountDestinations,
                                             activeDestinations: [],
                                        })
                                   );
                              }

                              dispatch(setLoadings({ listeners: false, destinations: false }));
                         }
                    } else {
                         dispatch(setRoutes([...defaultSubscriberRoutes]));
                         toastError(response);
                         dispatch(setLoadings({ listeners: false, destinations: false }));
                    }
               });
          }
     };
};

export const upgradeAccount = (data, next = (f) => f) => {
     return (dispatch) => {
          callTokenApi(`${API_CLIENT_ACCOUNT_UPGRADE}`, 'POST', data)
               .then((response) => {
                    next();
                    if (response.status === 200) {
                         // const finallyNext = () => toast.success("Your account has been successfully upgraded");
                         // dispatch(fetchUserAccounts(data.accountId, true, finallyNext));
                         window.location.reload();
                    } else {
                         toastError(response);
                    }
               })
               .finally(() => {
                    dispatch(resetAllPreview(data.accountId));
               });
     };
};

// export const fetchCustomVariableRules = (accountId, listenerId, getListenerAutomaticVariables = false) => {
//      return (dispatch) => {
//           dispatch(setLoadings({ customVariableRules: true }));
//           let data = {
//                accountId,
//                listenerId,
//                group: 'rule',
//                getListenerAutomaticVariables, // we're gonna get number of automatic variables or not
//           };

//           callTokenApi(`${API_CLIENT_RULES}`, 'POST', data)
//                .then((response) => {
//                     if (response.status === 200) {
//                          const { rules, countDatalayerVariables } = response.data;
//                          dispatch(setCustomVariableRules(rules));
//                          dispatch(setInitialCustomVariableRules(deepCopyArray(rules)));

//                          if (getListenerAutomaticVariables && typeof countDatalayerVariables === 'number') {
//                               if (countDatalayerVariables === 0) {
//                                    dispatch(setAutomaticVariableWarning(listenerId, true));
//                               } else {
//                                    dispatch(setAutomaticVariableWarning(listenerId, false));
//                               }
//                          }
//                     } else {
//                          toastError(response);
//                     }
//                })
//                .finally(() => dispatch(setLoadings({ customVariableRules: false })));
//      };
// };

export const fetchAllRulesRequest = (accountId, group) => (dispatch) => {
     dispatch(setLoadings({ allRules: true }));
     callTokenApi(`${API_CLIENT_ALL_RULES.replace(':accountId', accountId)}?group=${group}`, 'GET')
          .then((response) => {
               if (response && response.status === 200) {
                    const { rules } = response.data;

                    switch (group) {
                         case RULE_GROUP.DATA_LAYER:
                              dispatch(
                                   setSubscriberState({
                                        customVariableRules: rules,
                                        initialCustomVariableRules: deepCopyArray(rules),
                                   })
                              );
                              break;
                         case RULE_GROUP.FORM_CATEGORIES:
                              dispatch(
                                   setSubscriberState({
                                        formCategoriesRules: rules,
                                        initialFormCategoriesRules: deepCopyArray(rules),
                                   })
                              );
                              break;
                         case RULE_GROUP.BROWSER_STORAGE:
                              dispatch(
                                   setSubscriberState({
                                        browserRules: rules,
                                        initialBrowserRules: deepCopyArray(rules),
                                   })
                              );
                              break;
                         case RULE_GROUP.MONITORING:
                              dispatch(
                                   setSubscriberState({
                                        monitoringRules: rules,
                                        initialMonitoringRules: deepCopyArray(rules),
                                   })
                              );
                              break;
                         case RULE_GROUP.POPULATE:
                              dispatch(
                                   setSubscriberState({
                                        populateRules: rules,
                                        initialPopulateRules: deepCopyArray(rules),
                                   })
                              );
                              break;
                         case RULE_GROUP.ENGAGEMENT:
                              dispatch(setEngagementRules(rules));
                              break;
                         default:
                              break;
                    }
               } else {
                    toastError(response);
               }
          })
          .finally(() => dispatch(setLoadings({ allRules: false })));
};

export const fetchDatalayerVariables = (accountId, listenerId, onlyCountVariables = false) => (dispatch) => {
     if (!accountId || !listenerId) return;

     callTokenApi(`${API_CLIENT_DATALAYER_VARIABLE_RULE}${accountId}/${listenerId}`, 'GET').then((response) => {
          if (response.status === 200) {
               const datalayerVariables = response.data.datalayerVariables;
               const countDatalayerVariables = datalayerVariables ? datalayerVariables.length : 0;

               if (!onlyCountVariables) {
                    dispatch(setVariableOptions({ listenerId, variables: datalayerVariables }));
               }

               if (countDatalayerVariables === 0) {
                    dispatch(setAutomaticVariableWarning(listenerId, true));
               } else {
                    dispatch(setAutomaticVariableWarning(listenerId, false));
               }
          }
     });
};

export const fetchTriggerRules = (accountId) => {
     return (dispatch) => {
          dispatch(setLoadings({ triggerRules: true }));

          callTokenApi(`${API_CLIENT_ACCOUNT_RULES}/${accountId}?group=triggers`, 'GET')
               .then((response) => {
                    if (response.status === 200) {
                         const { rules } = response.data;
                         dispatch(setTriggerRules(rules));
                         dispatch(setInitialTriggerRules(deepCopyArray(rules)));
                    } else {
                         toastError(response);
                    }
               })
               .finally(() => dispatch(setLoadings({ triggerRules: false })));
     };
};

export const fetchConversionRules = (accountId) => {
     return (dispatch) => {
          dispatch(setLoadings({ conversionRules: true }));

          callTokenApi(`${API_CLIENT_ACCOUNT_RULES}/${accountId}?group=conversions`, 'GET')
               .then((response) => {
                    if (response.status === 200) {
                         const { rules } = response.data;
                         dispatch(setConversionRules(rules));
                         dispatch(setInitialConversionRules(deepCopyArray(rules)));
                    } else {
                         toastError(response);
                    }
               })
               .finally(() => dispatch(setLoadings({ conversionRules: false })));
     };
};

export const fetchDataActionRules = (accountId, destinationId) => {
     return (dispatch) => {
          callTokenApi(`${API_CLIENT_ACCOUNT_RULES}/${accountId}?group=dataAction&destinationId=${destinationId}`, 'GET').then((response) => {
               if (response.status === 200) {
                    const { rules } = response.data;

                    dispatch(setDataActionRules({ rules: rules, loading: false, initialRules: deepCopyArray(rules) }));
               } else {
                    toastError(response);
               }
          });
     };
};

export const fetchAllDataActionRules = (accountId) => {
     return (dispatch) => {
          callTokenApi(`${API_CLIENT_ACCOUNT_RULES}/${accountId}?group=dataAction`, 'GET').then((response) => {
               if (response.status === 200) {
                    const { rules } = response.data;
                    dispatch(setAllDataActionRules(rules));
               } else {
                    toastError(response);
               }
          });
     };
};

// export const fetchBrowserRules = (accountId, listenerId, getListenerAutomaticVariables = false) => {
//      return (dispatch) => {
//           dispatch(setLoadings({ browserRules: true }));
//           let data = {
//                accountId,
//                listenerId,
//                group: 'browser',
//                getListenerAutomaticVariables, // we're gonna get number of automatic variables or not
//           };

//           callTokenApi(`${API_CLIENT_RULES}`, 'POST', data)
//                .then((response) => {
//                     if (response.status === 200) {
//                          const { rules, countDatalayerVariables } = response.data;
//                          dispatch(
//                               setSubscriberState({
//                                    browserRules: rules,
//                                    initialBrowserRules: deepCopyArray(rules),
//                               })
//                          );

//                          if (getListenerAutomaticVariables && typeof countDatalayerVariables === 'number') {
//                               if (countDatalayerVariables === 0) {
//                                    dispatch(setAutomaticVariableWarning(listenerId, true));
//                               } else {
//                                    dispatch(setAutomaticVariableWarning(listenerId, false));
//                               }
//                          }
//                     } else {
//                          toastError(response);
//                     }
//                })
//                .finally(() => dispatch(setLoadings({ browserRules: false })));
//      };
// };

export const fetchPopulateRules = (accountId, listenerId) => {
     return (dispatch) => {
          dispatch(setLoadings({ populateRules: true }));
          let data = {
               accountId,
               listenerId,
               group: 'populate',
          };

          callTokenApi(`${API_CLIENT_RULES}`, 'POST', data)
               .then((response) => {
                    if (response.status === 200) {
                         const { rules } = response.data;
                         dispatch(
                              setSubscriberState({
                                   populateRules: rules,
                                   initialPopulateRules: deepCopyArray(rules),
                              })
                         );
                    } else {
                         toastError(response);
                    }
               })
               .finally(() => dispatch(setLoadings({ populateRules: false })));
     };
};

export const fetchConsentRules = (accountId) => {
     return (dispatch) => {
          dispatch(setLoadings({ consentRules: true }));

          callTokenApi(`${API_CONSENT_RULES}${accountId}`, 'GET')
               .then((response) => {
                    if (response.status === 200) {
                         const { rules } = response.data;
                         dispatch(
                              setSubscriberState({
                                   consentRules: rules,
                                   initialConsentRules: rules,
                              })
                         );
                    } else {
                         toastError(response);
                    }
               })
               .finally(() => dispatch(setLoadings({ consentRules: false })));
     };
};

// export const fetchMonitoringRules = (accountId, listenerId, getListenerAutomaticVariables = false) => {
//      return (dispatch) => {
//           dispatch(setLoadings({ monitoringRules: true }));
//           let data = {
//                accountId,
//                listenerId,
//                group: 'monitoring',
//                getListenerAutomaticVariables, // we're gonna get number of automatic variables or not
//           };

//           callTokenApi(`${API_CLIENT_RULES}`, 'POST', data)
//                .then((response) => {
//                     if (response.status === 200) {
//                          const { rules, countDatalayerVariables } = response.data;
//                          dispatch(
//                               setSubscriberState({
//                                    monitoringRules: rules,
//                                    initialMonitoringRules: deepCopyArray(rules),
//                               })
//                          );

//                          if (getListenerAutomaticVariables && typeof countDatalayerVariables === 'number') {
//                               if (countDatalayerVariables === 0) {
//                                    dispatch(setAutomaticVariableWarning(listenerId, true));
//                               } else {
//                                    dispatch(setAutomaticVariableWarning(listenerId, false));
//                               }
//                          }
//                     } else {
//                          toastError(response);
//                     }
//                })
//                .finally(() => dispatch(setLoadings({ monitoringRules: false })));
//      };
// };

export const fetchEvents = (accountId, listenerId) => {
     return (dispatch) => {
          const urlToCall = `${API_CLIENT_EVENT_ACCOUNT}${accountId}/${listenerId}`;

          callTokenApi(urlToCall, 'GET', null).then((response) => {
               if (response.status === 200) {
                    let events = {
                         listenerId,
                         events: response.data.eventAccounts,
                    };
                    dispatch(setEventOptions([events]));
               }
          });
     };
};

export const fetchVersion = (accountId, flag) => {
     return (dispatch) => {
          const data = {
               accountId: accountId,
               status: 'waiting',
          };
          callTokenApi(`${API_VERSION_WAITING}`, 'POST', data).then((response) => {
               if (response.status === 200) {
                    let isPublish = parseInt(response.data.version.numberHistories) > 0 ? 'enable' : 'disable';
                    dispatch(setVersions(response.data.versions));
                    dispatch(setVersionPublish({ version: response.data.version, isPublish }));
                    if (flag) {
                         if (isPublish === 'enable') {
                              dispatch(setNumberOfHistories(parseInt(response.data.version.numberHistories)));
                         } else {
                              dispatch(setNumberOfHistories(0));
                         }
                    }
               } else {
                    toastError(response);
               }
          });
     };
};

export const fetchCustomVariables = (activeAccountId, finallyFunc = (f) => f) => {
     return (dispatch) => {
          return callTokenApi(`${API_CLIENT_CUSTOM_VARIABLE}${activeAccountId}?group=rule`, 'GET', null)
               .then((response) => {
                    if (response.status === 200) {
                         const { count, variables } = response.data;
                         let initialVariables = [],
                              listDisabled = [];

                         if (count >= 1) {
                              variables.forEach((item) => {
                                   initialVariables.push({ id: item.id, name: item.name });
                                   listDisabled.push(true);
                              });
                              dispatch(
                                   setCustomVariables({
                                        initialVariables,
                                        addedVariables: [],
                                        editedVariables: [],
                                        listDisabled: [...listDisabled],
                                        numOfVariables: count,
                                   })
                              );
                              dispatch(setCustomVariableOptions(variables));
                         } else {
                              dispatch(
                                   setCustomVariables({
                                        initialVariables: [{ id: 'variable1', name: '' }],
                                        addedVariables: [{ id: 'variable1', name: '' }],
                                        editedVariables: [],
                                        listDisabled: [false],
                                        numOfVariables: 1,
                                   })
                              );
                         }
                    } else {
                         toastError(response);
                    }
               })
               .finally(() => finallyFunc());
     };
};

export const fetchAutomaticVariables = (activeAccountId, activeListenerId, finallyFunc = (f) => f) => {
     return (dispatch) => {
          callTokenApi(`${API_CLIENT_DATALAYER_VARIABLE}${activeAccountId}/${activeListenerId}`, 'GET', null)
               .then((response) => {
                    if (response.status === 200) {
                         let data = response.data.datalayerVariables;
                         if (response.data.ruleConditions) {
                              dispatch(setListRuleConditions({ listenerId: activeListenerId, ruleConditions: response.data.ruleConditions }));
                         }
                         dispatch(
                              setListenerVariables({
                                   listenerId: activeListenerId,
                                   initialVariables: data,
                                   editedVariables: [],
                                   nonLevelList: getListVariables(data),
                              })
                         );
                    } else {
                         if (response.data.accountBlocked) {
                              dispatch(setShowBlockAccountPopup(true));
                         } else {
                              toastError(response);
                         }
                    }
               })
               .finally(() => finallyFunc());
     };
};

export const setNativeBrowserStorageOptions = (options) => {
     return {
          type: SET_NATIVE_BROWSER_STORAGE_OPTIONS,
          payload: options,
     };
};

export const setSelectorValues = (selectorValues) => {
     return {
          type: SET_SELECTOR_VALUES,
          payload: selectorValues,
     };
};

export const fetchSelectors = (activeAccountId, activeListenerId, selectorVariableReload, finallyFunc = (f) => f) => {
     return async (dispatch) => {
          await callTokenApi(`${API_CLIENT_SELECTOR_VARIABLE}${activeAccountId}/${activeListenerId}`, 'GET', null)
               .then((response) => {
                    if (response.status === 200) {
                         let data = response.data.selectorVariables;
                         dispatch(
                              setListenerSelectors({
                                   listenerId: activeListenerId,
                                   initialSelectors: data,
                                   editedSelectors: [],
                              })
                         );
                         dispatch(setSelectorVariableReload(false));
                    } else {
                         if (response.data.accountBlocked) {
                              dispatch(setShowBlockAccountPopup(true));
                         } else {
                              toastError(response);
                         }
                    }
               })
               .finally(() => finallyFunc());
     };
};

export const fetchDataTickets = (activeAccountId, isCustomerSupportLoginAs) => {
     const isCStLoginAs = isCustomerSupportLoginAs ? `&isCStLoginAs=true` : '';

     return async (dispatch) => {
          await callTokenApi(`${API_CLIENT_TICKETS_NUMBER_COMMENT}?accountId=${activeAccountId}${isCStLoginAs}`, 'GET', null).then((response) => {
               if (response.status === 200) {
                    const { number } = response.data;

                    dispatch(setNumberOfComments(number));
               } else {
                    toastError(response);
               }
          });
     };
};

export const fetchMonitoringNotifications = (accountId, finallyFunc = (f) => f) => {
     return async (dispatch) => {
          callTokenApi(`${API_CLIENT_ACCOUNT_NOTIFICATIONS}/${accountId}`, 'GET')
               .then((response) => {
                    if (response.status === 200) {
                         const monitoringNotifications = response.data.notifications;
                         dispatch(setMonitoringNotifications(monitoringNotifications));
                         dispatch(setShowArchiveNotifications(false));
                    } else {
                         toastError(response);
                    }
               })
               .finally(() => finallyFunc());
     };
};

export const saveNotificationPusherToRedis = (accountId, data) => {
     return async () => {
          callTokenApi(`${API_SAVE_NOTIFICATION_PUSHER_TO_REDIS}`, 'POST', { accountId, data });
     };
};

export const fetchGTMContainers = ({ accountId, setIsLoading, rateLimitExceededNext = (f) => f }) => {
     return async (dispatch) => {
          // const accessToken = localStorage.getItem(LOCAL_GTM_ACCESS_TOKEN);

          // if (accessToken) {
          //      makeRequest(API_GTM_ACCOUNTS, 'GET', null, accessToken)
          //           .then(async (response) => {
          //                if (response.status === 200) {
          //                     const { account } = response.data;
          //                     let gtmUserEmail = localStorage.getItem(LOCAL_GTM_USER_EMAIL); // Need email of logged Google account to get user permission of GTM containers

          //                     if (!gtmUserEmail) {
          //                          let userInfoRes = await makeRequest(API_GOOGLE_USER_INFO, 'GET', null, accessToken);

          //                          if (userInfoRes.status === 200) {
          //                               gtmUserEmail = userInfoRes.data.email;
          //                               localStorage.setItem(LOCAL_GTM_USER_EMAIL, gtmUserEmail);
          //                          }
          //                     }
          //                     const containerPromises = account.map(el => makeRequest(API_GTM_CONTAINERS.replace('+parent', el.path), 'GET', null, accessToken));
          //                     const userPermissionPromises = account.map(el => makeRequest(API_GTM_USER_PERMISSIONS.replace('+parent', el.path), 'GET', null, accessToken));

          //                     const containerResponses = await Promise.all(containerPromises);
          //                     const userPermissionResponses = await Promise.all(userPermissionPromises);

          //                     // From user email, all containers and container permissions to get containers which this user can write
          //                     const containers = containerResponses.reduce((arr, containerRes, index) => {
          //                          if (containerRes.status === 200 && userPermissionResponses[index].status === 200 && containerRes.data.container) {
          //                               const foundThisUserPermission = userPermissionResponses[index].data.userPermission.find(permission => permission.emailAddress === gtmUserEmail);

          //                               if (foundThisUserPermission) {
          //                                    let containersUserCanWrite = [];

          //                                    containerRes.data.container.forEach(c => {
          //                                         const foundContainer = foundThisUserPermission.containerAccess.find(cAccess => cAccess.containerId === c.containerId);

          //                                         if (foundContainer && foundContainer.permission !== 'read') {
          //                                              containersUserCanWrite.push({ ...c, userPermissions: userPermissionResponses[index].data.userPermission });
          //                                         }
          //                                    });

          //                                    return [...arr, ...containersUserCanWrite];
          //                               }
          //                          }

          //                          return arr;
          //                     }, []);

          //                     dispatch(setGTMContainers(containers));
          //                } else if (response.status === 401 && response.data.error.status === 'UNAUTHENTICATED') {
          //                     localStorage.removeItem('gtmAccessToken')
          //                }
          //           })
          //           .finally(() => setIsLoading(false))
          // } else {
          //      setIsLoading(false);
          // }

          setIsLoading(true);

          callTokenApi(`${API_CLIENT_DESTINATIONS_GTM_CONTAINERS}/${accountId}`, 'GET')
               .then((response) => {
                    if (response.status === 200) {
                         const { containers, gtmEmail } = response.data;

                         localStorage.setItem(LOCAL_GTM_USER_EMAIL, gtmEmail);
                         dispatch(setGTMContainers(containers));
                    } else {
                         if (response.data.rateLimitExceeded) {
                              rateLimitExceededNext();
                         } else {
                              toastError(response);
                         }
                    }
               })
               .finally(() => setIsLoading(false));
     };
};

export const fetchGTMContainersByGTMAccount = ({ accountId, gtmAccountId, setIsLoading, rateLimitExceededNext = (f) => f }) => {
     return async (dispatch) => {
          setIsLoading(true);

          callTokenApi(`${API_CLIENT_DESTINATIONS_GTM_CONTAINERS}/${accountId}/${gtmAccountId}`, 'GET')
               .then((response) => {
                    if (response.status === 200) {
                         const { containers, gtmEmail } = response.data;

                         localStorage.setItem(LOCAL_GTM_USER_EMAIL, gtmEmail);
                         dispatch(setGTMContainers(containers));
                    } else {
                         if (response.data.rateLimitExceeded) {
                              rateLimitExceededNext();
                         } else {
                              toastError(response);
                         }
                    }
               })
               .finally(() => setIsLoading(false));
     };
};

export const fetchGTMAccounts = ({ accountId, setIsLoading, rateLimitExceededNext = (f) => f }) => {
     return async (dispatch) => {
          setIsLoading(true);

          callTokenApi(`${API_CLIENT_DESTINATIONS_GTM_ACCOUNTS}/${accountId}`, 'GET')
               .then((response) => {
                    if (response.status === 200) {
                         const { accounts } = response.data;

                         dispatch(setGTMAccounts(accounts));
                    } else {
                         if (response.data.rateLimitExceeded) {
                              rateLimitExceededNext();
                         } else {
                              toastError(response);
                         }
                    }
               })
               .finally(() => setIsLoading(false));
     };
};

export const fetchGTMWorkspaces = (path, setIsLoading, successNext = (f) => f) => {
     return async (dispatch) => {
          const accessToken = localStorage.getItem(LOCAL_GTM_ACCESS_TOKEN);

          if (accessToken) {
               makeRequest(API_GTM_WORKSPACES.replace('+parent', path), 'GET', null, accessToken)
                    .then(async (response) => {
                         if (response.status === 200) {
                              successNext(response.data.workspace);
                         }
                    })
                    .finally(() => setIsLoading(false));
          } else {
               setIsLoading(false);
          }
     };
};

export const addFirstDomain = ({ accountId, domain, domainStatus, successNext, setIsLoading = (f) => f }) => {
     return () => {
          let data = {
               accountId,
               actions: [
                    {
                         action: 'add',
                         domainName: domain,
                    },
               ],
               domains: [
                    {
                         name: domain,
                         scriptInstalled: false,
                         status: domainStatus,
                    },
               ],
          };

          callTokenApi(UPDATE_DOMAIN_ACCOUNT, 'POST', data).then((response) => {
               setIsLoading(false);

               if (response.status === 200) {
                    successNext(response);
               } else {
                    toastError(response);
               }
          });
     };
};

export const enableManyListeners = ({ accountId, listenerIds, weatherFeatures, shouldPublish, successNext = (f) => f, updateCompletedSteps }) => {
     return () => {
          window._noPublishToast = true; // Don't toast message in ThePusher

          callTokenApi(API_CLIENT_ACCOUNT_ENABLE_EVENT_LISTENERS, 'POST', {
               accountId,
               listenerIds,
               shouldPublish,
               weatherFeatures,
               updateCompletedSteps,
          }).then((response) => {
               if (response.status === 200) {
                    successNext();
               } else {
                    toastError(response);
               }
          });
     };
};

export const disableManyListeners = ({ accountId, listenerIds, shouldPublish, successNext }) => {
     return () => {
          window._noPublishToast = true; // Don't toast message in ThePusher

          callTokenApi(API_CLIENT_ACCOUNT_DISABLE_EVENT_LISTENERS, 'POST', { accountId, listenerIds, shouldPublish }).then((response) => {
               if (response.status === 200) {
                    successNext();
               } else {
                    toastError(response);
               }
          });
     };
};

export const setStepFormsCategories = (step) => {
     return {
          type: SET_STEPS_WIZARD_CATEGORIES,
          payload: step,
     };
};

export const setShowStepFormCategory = (isShowStepFormCategory) => {
     return {
          type: SET_SHOW_STEP_FORM_CATEGORY,
          payload: isShowStepFormCategory,
     };
};

export const setStepCustomForms = (step) => {
     return {
          type: SET_STEPS_CUSTOM_FORMS,
          payload: step,
     };
};

export const setShowStepCustomForm = (isShowStepCustomForm) => {
     return {
          type: SET_SHOW_STEP_CUSTOM_FORM,
          payload: isShowStepCustomForm,
     };
};

export const setStepConversion = (step) => {
     return {
          type: SET_STEPS_CONVERSION,
          payload: step,
     };
};

export const setShowRoadmapMinimize = (isShowRoadmapMinize) => {
     return {
          type: SET_SHOW_ROADMAP_MINIMIZE,
          payload: isShowRoadmapMinize,
     };
};

// show Finish Your Configuration Form Category
export const setSkipWizardFormCategory = (skipWizardFormCategory) => {
     return {
          type: SET_SKIP_WIZARD_FORM_CATEGORY,
          payload: skipWizardFormCategory,
     };
};

export const setBrowseListenerFormCategory = (activeListenerWizard) => {
     return {
          type: SET_BROWSE_LISTENER_FORM_CATEGORY,
          payload: activeListenerWizard,
     };
};

// Action Form Categories

export const setListenerFormsEnabled = (listeners) => {
     return {
          type: SET_LISTENER_FORMS_ENABLED,
          payload: listeners,
     };
};

export const handleSetListenerFormsEnabled = () => (dispatch, getState) => {
     const activeListeners = getState().subscriber.activeListeners;
     const listenerFormEnabled = activeListeners.filter((listener) => listener.configStatus && listener.type === LISTENER_TYPE.FORMS);
     dispatch(setListenerFormsEnabled(listenerFormEnabled));
};

export const handleUpdateCustomCategories = (data, nextDispatch, openFromConfigConversion) => (dispatch, getState) => {
     if (nextDispatch.setIsLoading) {
          nextDispatch.setIsLoading(true);
     }
     const accountId = getState().subscriber.activeAccount.id;
     const formCategoriesRules = getState().subscriber.formCategoriesRules;
     const payload = {
          accountId,
          data,
     };

     const rulesDefault = formCategoriesRules ? formCategoriesRules.filter((rule) => rule.name === 'Categorize Forms') : [];

     callTokenApi(API_CLIENT_UPDATE_CUSTOM_CATEGORIES, 'PUT', payload).then((response) => {
          if (response.status === 200) {
               // toast.success(response.data.message);

               if (nextDispatch.functionSaveAccept) {
                    nextDispatch.functionSaveAccept();
               }
               if (nextDispatch.setIsLoading) {
                    nextDispatch.setIsLoading(false);
               }

               if (nextDispatch.listFormEdit) {
                    nextDispatch.listFormEdit();
               }

               if (nextDispatch.onClose && typeof nextDispatch.onClose === 'function') {
                    nextDispatch.onClose();
               }

               const customFormCategories = response.data.customCategories;

               dispatch(setCustomCategories(customFormCategories));
               if (nextDispatch.nextStep) {
                    dispatch(setStepFormsCategories(nextDispatch.nextStep));
               }

               // check next step
               if (nextDispatch.functionNextStep) {
                    nextDispatch.functionNextStep();
               }

               const { itemChoose, fewForms, setFewForms } = nextDispatch;

               if (nextDispatch.setFewForms && nextDispatch.fewForms) {
                    const newValues = [...fewForms];
                    const newDataEdit = { ...newValues[itemChoose], categoryId: response.data.categoryAdd[0].id };
                    newValues[itemChoose].categoryId = response.data.categoryAdd[0].id;
                    setFewForms(newValues);
                    dispatch(setFewFormsEdit(newDataEdit));
               }

               if (openFromConfigConversion) {
                    dispatch(setShowCategoryForms(false));
                    dispatch(setOpenFromConfigConversion(false));
               }
               if (rulesDefault && rulesDefault.length === 0) {
                    dispatch(setSubscriberState({ formCategoriesRules: null }));
               }

               // reset all forms
               if (nextDispatch.resetForm) {
                    dispatch(handleResetAllFormCategories());
               }
          } else {
               if (nextDispatch.setIsLoading) {
                    nextDispatch.setIsLoading(false);
               }
               toastError(response);
          }
     });
};

export const handleUpdateCustomCategoriesDetails = (data, nextDispatch, openFromConfigConversion) => async (dispatch, getState) => {
     if (nextDispatch.setIsLoading) {
          nextDispatch.setIsLoading(true);
     }
     const accountId = getState().subscriber.activeAccount.id;
     const formCategoriesRules = getState().subscriber.formCategoriesRules;
     const formRequiringEdit = getState().subscriber.formRequiringEdit;
     const formInventoryEdit = getState().subscriber.formInventoryEdit;
     const formIgnoreEdit = getState().subscriber.formIgnoreEdit;
     const showIgnoredForm = getState().subscriber.showIgnoredForm;
     const showIgnoredInventoryForm = getState().subscriber.showIgnoredInventoryForm;
     const payload = {
          accountId,
          data,
     };

     const rulesDefault = formCategoriesRules ? formCategoriesRules.filter((rule) => rule.name === 'Categorize Forms') : [];

     return await callTokenApi(API_CLIENT_UPDATE_CUSTOM_CATEGORIES, 'PUT', payload).then((response) => {
          if (response.status === 200) {
               // toast.success(response.data.message);

               if (nextDispatch.setIsLoading) {
                    nextDispatch.setIsLoading(false);
               }

               if (nextDispatch.listFormEdit) {
                    nextDispatch.listFormEdit();
               }

               if (nextDispatch.onClose && typeof nextDispatch.onClose === 'function') {
                    nextDispatch.onClose();
               }

               const customFormCategories = response.data.customCategories;

               dispatch(setCustomCategories(customFormCategories));
               if (nextDispatch.nextStep) {
                    dispatch(setStepFormsCategories(nextDispatch.nextStep));
               }
               const { setFormRequiringEdit, setFormInventoryEdit, setFormIgnoreEdit } = nextDispatch;

               if (nextDispatch.setFormRequiringEdit) {
                    const newDataEdit = formRequiringEdit.find((item) => item.categoryId === 'createNewCategories');
                    const newData = customFormCategories.find((data) => data.name === payload.data[0].name);
                    dispatch(setFormRequiringEdit({ ...newDataEdit, categoryId: newData.id }));
               }

               if (nextDispatch.setFormInventoryEdit) {
                    const newDataEdit = formInventoryEdit.find((item) => item.categoryId === 'createNewCategories');
                    const newData = customFormCategories.find((data) => data.name === payload.data[0].name);
                    dispatch(setFormInventoryEdit({ ...newDataEdit, categoryId: newData.id }));
               }

               if (nextDispatch.setFormIgnoreEdit && (showIgnoredForm || showIgnoredInventoryForm)) {
                    const newDataEdit = formIgnoreEdit.find((item) => item.categoryId === 'createNewCategories');
                    const newData = customFormCategories.find((data) => data.name === payload.data[0].name);
                    dispatch(setFormIgnoreEdit({ ...newDataEdit, categoryId: newData.id }));
               }

               if (openFromConfigConversion) {
                    dispatch(setShowCategoryForms(false));
                    dispatch(setOpenFromConfigConversion(false));
               }
               if (rulesDefault && rulesDefault.length === 0) {
                    dispatch(setSubscriberState({ formCategoriesRules: null }));
               }

               // reset all forms
               if (nextDispatch.resetForm) {
                    dispatch(handleResetAllFormCategories());
               }

               return response.data;
          } else {
               if (nextDispatch.setIsLoading) {
                    nextDispatch.setIsLoading(false);
               }
               toastError(response);
          }
     });
};

export const handleSetCurrentStepCategories = (values, nextDispatch) => (dispatch, getState) => {
     const activeAccount = getState().subscriber.activeAccount;

     if (nextDispatch && nextDispatch.setIsLoading) {
          nextDispatch.setIsLoading(true);
     }

     callTokenApi(API_CLIENT_UPDATE_CURRENT_STEP_CATEGORIES, 'PUT', values).then((response) => {
          if (response.status === 200) {
               if (nextDispatch && nextDispatch.setIsLoading) {
                    nextDispatch.setIsLoading(false);
               }

               if (nextDispatch && nextDispatch.nextStep) {
                    document.querySelector('html').setAttribute('style', 'overflow:initial');
                    dispatch(setStepFormsCategories(nextDispatch.nextStep));
               }
               const newActiveAccount = { ...activeAccount };
               newActiveAccount.currentStepFormCategories = values.currentStepFormCategories;
               dispatch(setActiveAccount(newActiveAccount));
          } else {
               if (nextDispatch && nextDispatch.setIsLoading) {
                    nextDispatch.setIsLoading(false);
               }
               toastError(response);
          }
     });
};

export const handleSetCurrentStepCustomForms = (values, nextDispatch) => (dispatch, getState) => {
     const activeAccount = getState().subscriber.activeAccount;
     if (nextDispatch && nextDispatch.setIsLoading) {
          nextDispatch.setIsLoading(true);
     }

     callTokenApi(API_CLIENT_UPDATE_CURRENT_STEP_CUSTOM_FORMS, 'PUT', values).then((response) => {
          if (response.status === 200) {
               if (nextDispatch && nextDispatch.setIsLoading) {
                    nextDispatch.setIsLoading(false);
               }

               if (nextDispatch && nextDispatch.nextStep) {
                    document.querySelector('html').setAttribute('style', 'overflow:initial');
                    dispatch(setStepCustomForms(nextDispatch.nextStep));
               }
               const newActiveAccount = { ...activeAccount };
               newActiveAccount.currentStepCustomForms = values.currentStepCustomForms;
               dispatch(setActiveAccount(newActiveAccount));
          } else {
               if (nextDispatch && nextDispatch.setIsLoading) {
                    nextDispatch.setIsLoading(false);
               }
               toastError(response);
          }
     });
};

export const handleSetCurrentStepConversion = (values, nextDispatch) => (dispatch, getState) => {
     const activeAccount = getState().subscriber.activeAccount;
     if (nextDispatch && nextDispatch.setIsLoading) {
          nextDispatch.setIsLoading(true);
     }

     callTokenApi(API_CLIENT_UPDATE_CURRENT_STEP_CONVERSION, 'PUT', values).then((response) => {
          if (response.status === 200) {
               if (nextDispatch && nextDispatch.setIsLoading) {
                    nextDispatch.setIsLoading(false);
               }

               if (nextDispatch && nextDispatch.nextStep) {
                    document.querySelector('html').setAttribute('style', 'overflow:initial');
                    dispatch(setStepConversion(nextDispatch.nextStep));
               }
               const newActiveAccount = { ...activeAccount };
               newActiveAccount.currentStepConversion = values.currentStepConversion;
               dispatch(setActiveAccount(newActiveAccount));
          } else {
               if (nextDispatch && nextDispatch.setIsLoading) {
                    nextDispatch.setIsLoading(false);
               }
               toastError(response);
          }
     });
};

export const handleUpdateFormCategories = (values, nextDispatch) => (dispatch, getState) => {
     if (nextDispatch.setIsLoading) {
          nextDispatch.setIsLoading(true);
     }
     const activeAccount = getState().subscriber.activeAccount;
     const { id: accountId } = activeAccount;
     let fetchData = false;
     const parentFormHasCategory = [];
     values.forEach((form) => {
          if (form.inheritParent && form.categoryId && form.name) {
               parentFormHasCategory.push({
                    id: form.parentId,
                    hasChild: true,
                    name: form.name,
                    categoryId: form.categoryId,
                    proposeCategory: form.proposeCategory,
                    proposeName: form.name,
               });
          }
     });

     const payload = {
          accountId,
          data: [...values, ...parentFormHasCategory],
     };

     //suggest form wizard
     if (nextDispatch.formWizard) {
          payload.formWizard = nextDispatch.formWizard;
     }

     callTokenApi(API_CLIENT_UPDATE_FORM_CATEGORIES, 'PUT', payload)
          .then((response) => {
               if (response.status === 200) {
                    callTokenApi(API_CLIENT_UPDATE_CURRENT_STEP_CATEGORIES, 'PUT', {
                         currentStepFormCategories: STEPS_WIZARD_CATEGORIES.yourDone,
                         accountId: accountId,
                    }).then((response) => {
                         if (response.status === 200) {
                              if (nextDispatch.setIsLoading) {
                                   nextDispatch.setIsLoading(false);
                              }
                              document.querySelector('html').setAttribute('style', 'overflow:initial');
                              dispatch(setStepFormsCategories(STEPS_WIZARD_CATEGORIES.yourDone));
                         } else {
                              toastError(response);
                         }
                    });
                    dispatch(setFormInventory(null));
                    dispatch(setAllFormsInventory(null));
                    dispatch(setFewFormsEdit(null));
                    dispatch(handleResetAllFormCategories());
                    fetchData = true;
               } else {
                    toastError(response);
                    if (nextDispatch.setIsLoading) {
                         nextDispatch.setIsLoading(false);
                    }
                    fetchData = false;
               }
          })
          .finally(() => {
               if (fetchData) {
                    dispatch(handleFetchFormsRequiring(20));
                    dispatch(handleFetchFormsInventory(20));
                    dispatch(handleFetchFormsIgnored(20));
               }
          });
};

export const handleFetchCustomCategories = (nextDispatch) => async (dispatch, getState) => {
     const activeAccount = getState().subscriber.activeAccount;
     const { id: accountId } = activeAccount;
     if (nextDispatch && nextDispatch.setIsLoading) {
          nextDispatch.setIsLoading(true);
     }
     await callTokenApi(API_CLIENT_GET_CUSTOM_CATEGORIES.replace(':accountId', accountId), 'GET')
          .then((response) => {
               if (response.status === 200) {
                    const listCustomCategories = response.data.category;
                    if (nextDispatch && nextDispatch.convertNewCategories) {
                         nextDispatch.convertNewCategories(listCustomCategories);
                    }
                    dispatch(setCustomCategories(listCustomCategories));
               } else {
                    toastError(response);
               }
          })
          .finally(() => {
               if (nextDispatch && nextDispatch.setIsLoading) {
                    nextDispatch.setIsLoading(false);
               }
          });
};

export const handleFetchFormCategories = (nextDispatch, activeAccount, limit) => (dispatch, getState) => {
     let newActiveAccount = activeAccount;
     if (!activeAccount) {
          newActiveAccount = getState().subscriber.activeAccount;
     }
     const { id: accountId } = newActiveAccount;
     const oldListFormCategory = getState().subscriber.formCategory || [];

     if (nextDispatch && nextDispatch.setIsLoading) {
          nextDispatch.setIsLoading(true);
     }

     if (accountId && accountId !== '') {
          callTokenApi(`${API_CLIENT_FORM_CATEGORIES.replace(':accountId', accountId)}?limit=${limit}&offset=${oldListFormCategory.length}`, 'GET')
               .then((response) => {
                    if (response.status === 200) {
                         const listFormCategory = response.data.formAccount;
                         const newListFormCategory = limit ? [...oldListFormCategory, ...listFormCategory] : listFormCategory;
                         dispatch(setFormCategory(newListFormCategory));
                         if (nextDispatch && nextDispatch.setFoundForm && listFormCategory.length > 0) {
                              nextDispatch.setFoundForm(true);
                         }
                         if (nextDispatch && nextDispatch.setShowLoadMoreBtn && listFormCategory.length === 0) {
                              nextDispatch.setShowLoadMoreBtn(false);
                         }
                    } else {
                         toastError(response);
                    }
               })
               .finally(() => {
                    if (nextDispatch && nextDispatch.setIsLoading) {
                         nextDispatch.setIsLoading(false);
                    }
               });
     }
};

export const handleFetchFormsRequiring =
     (limit, next, numberPage = 0) =>
          (dispatch, getState) => {
               const activeAccount = getState().subscriber.activeAccount;
               const newStateSet = {};
               let firstLoad,
                    numberOffset = 0;

               if (next) {
                    firstLoad = { requiringFormsLoading: true };
                    numberOffset = numberPage * 20;
               } else {
                    firstLoad = { requiringLoading: true };
               }

               dispatch(setSubscriberState(firstLoad));

               callTokenApi(`${API_CLIENT_FORM_REQUIRING.replace(':accountId', activeAccount.id)}?limit=${limit}&offset=${numberOffset}`, 'GET')
                    .then((response) => {
                         if (response.status === 200) {
                              const listFormRequiring = response.data.formAccount;

                              // listFormRequiring.forEach(parent => {
                              //      if (parent.hasChild) {
                              //           const childs = getState().subscriber[`requiring${parent.id}`];

                              //           if (childs) {
                              //                dispatch(
                              //                     setSubscriberState({
                              //                          [`requiring${parent.id}`]: null
                              //                     })
                              //                );
                              //           }
                              //      }
                              // });

                              // next = click from view all
                              if (next) {
                                   if (listFormRequiring && listFormRequiring.length > 0) {
                                        const newAllForms = [...listFormRequiring].splice(0, limit);
                                        dispatch(setAllFormsRequiring(newAllForms));
                                   } else {
                                        newStateSet.requiringDisableNextPage = true;

                                        if (numberPage === 1) {
                                             dispatch(setSubscriberState({ requiringShowPaging: false }));
                                        }
                                   }
                              } else {
                                   // first load
                                   const newForms = [...listFormRequiring].splice(0, 12);
                                   const newAllForms = [...listFormRequiring].splice(0, limit);

                                   dispatch(setFormRequiringAction(newForms));
                                   dispatch(setAllFormsRequiring(newAllForms));

                                   if (listFormRequiring.length <= limit) {
                                        dispatch(setSubscriberState({ requiringShowPaging: false }));
                                   } else {
                                        dispatch(setSubscriberState({ requiringShowPaging: true }));
                                   }
                              }

                              if (listFormRequiring && listFormRequiring.length > 0) {
                                   newStateSet.requiringNumberPerpage = numberPage || 0;
                              }

                              if (listFormRequiring.length <= limit) {
                                   newStateSet.requiringDisableNextPage = true;
                              } else {
                                   newStateSet.requiringDisableNextPage = false;
                              }
                         } else {
                              toastError(response);
                         }
                    })
                    .finally(() => {
                         if (next) {
                              newStateSet.requiringFormsLoading = false;
                         } else {
                              newStateSet.requiringLoading = false;
                         }

                         dispatch(setSubscriberState(newStateSet));
                    });
          };

export const handleFetchFormsIgnored =
     (limit, next, numberPage = 0) =>
          (dispatch, getState) => {
               const activeAccount = getState().subscriber.activeAccount;

               const newStateSet = {};
               let firstLoad,
                    numberOffset = 0;

               if (next) {
                    firstLoad = { ignoredFormsLoading: true };
                    numberOffset = numberPage * 20;
               } else {
                    firstLoad = { ignoredLoading: true };
               }

               dispatch(setSubscriberState(firstLoad));

               callTokenApi(`${API_CLIENT_FORM_IGNORED.replace(':accountId', activeAccount.id)}?limit=${limit}&offset=${numberOffset}`, 'GET')
                    .then((response) => {
                         if (response.status === 200) {
                              const listForms = response.data.formAccount;

                              // next = click from view all
                              if (next) {
                                   if (listForms && listForms.length > 0) {
                                        const newAllForms = [...listForms].splice(0, limit);
                                        dispatch(setAllFormsIgnore(newAllForms));
                                   } else {
                                        newStateSet.ignoredDisableNextPage = true;

                                        if (numberPage === 1) {
                                             dispatch(setSubscriberState({ ignoredShowPaging: false }));
                                        }
                                   }
                              } else {
                                   // first load
                                   const newForms = [...listForms].splice(0, 12);
                                   const newAllForms = [...listForms].splice(0, limit);

                                   dispatch(setFormIgnored(newForms));
                                   dispatch(setAllFormsIgnore(newAllForms));

                                   if (listForms.length <= limit) {
                                        dispatch(setSubscriberState({ ignoredShowPaging: false }));
                                   } else {
                                        dispatch(setSubscriberState({ ignoredShowPaging: true }));
                                   }
                              }

                              if (listForms && listForms.length > 0) {
                                   newStateSet.ignoredNumberPerpage = numberPage || 0;
                              }

                              if (listForms.length <= limit) {
                                   newStateSet.ignoredDisableNextPage = true;
                              } else {
                                   newStateSet.ignoredDisableNextPage = false;
                              }
                         } else {
                              toastError(response);
                         }
                    })
                    .finally(() => {
                         if (next) {
                              newStateSet.ignoredFormsLoading = false;
                         } else {
                              newStateSet.ignoredLoading = false;
                         }

                         dispatch(setSubscriberState(newStateSet));
                    });
          };

export const handleFetchFormsInventory =
     (limit, next, numberPage = 0, filters = []) =>
          async (dispatch, getState) => {
               const activeAccount = getState().subscriber.activeAccount;

               await dispatch(handleFetchCustomCategories());
               const listCustomCategories = getState().subscriber.customCategories || [];

               const newStateSet = {};
               let firstLoad,
                    numberOffset = 0;
               let filterQuery = '';

               if (next) {
                    firstLoad = { inventoryFormsLoading: true };
                    numberOffset = numberPage * 20;
               } else {
                    firstLoad = { inventoryLoading: true };
               }

               dispatch(setSubscriberState(firstLoad));

               filters.forEach((filter) => {
                    if (filter.type) {
                         const filterValue = filter.value.trim() ? filter.value : '';
                         filterQuery = `${filterQuery}&filter.${filter.type}.${filter.operator}=${filterValue}`;
                    }
               });

               callTokenApi(
                    `${API_CLIENT_FETCH_FORM_INVENTORY.replace(':accountId', activeAccount.id)}?limit=${limit}&offset=${numberOffset}${filterQuery}`,
                    'GET'
               )
                    .then((response) => {
                         if (response.status === 200) {
                              const listFormInventory = response.data.formAccount;

                              if (listFormInventory && listFormInventory.length > 0) {
                                   listFormInventory.map((form) => {
                                        const flagFormName = !form.name || form.name === null || form.name.trim() === '';
                                        const flagFormCate = !form.categoryId || form.categoryId === null || form.categoryId.trim() === '';

                                        // && form.issue !== "isDrafted" && form.issue !== "isAttention" && form.issue !== "isIgnored"
                                        if (flagFormName && flagFormCate && form.issue !== 'isDrafted' && form.issue !== 'isIgnored') {
                                             form.disable = true;
                                        } else {
                                             form.disable = false;
                                        }

                                        if (flagFormName && flagFormCate && form.issue !== 'isDrafted' && form.issue !== 'isIgnored') {
                                             form.name = form.proposeName || form.name;
                                        }

                                        const matchingCategory =
                                             listCustomCategories && listCustomCategories.find((category) => category.name === form.proposeCategory);
                                        if (flagFormName && flagFormCate && form.issue !== 'isDrafted' && form.issue !== 'isIgnored') {
                                             if (matchingCategory) {
                                                  form.categoryId = matchingCategory.id;
                                             } else {
                                                  form.categoryId = form.proposeCategory ? 'fake-' + form.proposeCategory : null;
                                             }
                                        }
                                        return form;
                                   });
                              }

                              // next = click from view all
                              if (next) {
                                   if (listFormInventory && listFormInventory.length > 0) {
                                        const newAllForms = [...listFormInventory].splice(0, limit);

                                        dispatch(setAllFormsInventory(newAllForms));
                                   } else {
                                        newStateSet.inventoryDisableNextPage = true;

                                        if (numberPage === 1) {
                                             dispatch(setSubscriberState({ inventoryShowPaging: false }));
                                        }
                                   }
                              } else {
                                   // first load
                                   const newForms = [...listFormInventory].splice(0, 12);
                                   const newAllForms = [...listFormInventory].splice(0, limit);

                                   dispatch(setFormInventory(newForms));
                                   dispatch(setAllFormsInventory(newAllForms));

                                   if (listFormInventory.length <= limit) {
                                        dispatch(setSubscriberState({ inventoryShowPaging: false }));
                                   } else {
                                        dispatch(setSubscriberState({ inventoryShowPaging: true }));
                                   }
                              }

                              if (listFormInventory && listFormInventory.length > 0) {
                                   newStateSet.inventoryNumberPerpage = numberPage || 0;
                              }

                              if (listFormInventory.length <= limit) {
                                   newStateSet.inventoryDisableNextPage = true;
                              } else {
                                   newStateSet.inventoryDisableNextPage = false;
                              }
                         } else {
                              toastError(response);
                         }
                    })
                    .finally(() => {
                         if (next) {
                              newStateSet.inventoryFormsLoading = false;
                         } else {
                              newStateSet.inventoryLoading = false;
                         }

                         dispatch(setSubscriberState(newStateSet));
                    });
          };

export const handleFetchChildForm =
     (id, nextDispatch, limit, paging, filters = []) =>
          (dispatch, getState) => {
               let listFormsChild = [];
               let filterQuery = '';
               const showIgnoredInventoryForm = getState().subscriber.showIgnoredInventoryForm;
               const numberOffsetChild = paging ? getState().subscriber[`numberOffset${id}`] : 0;

               const listCustomCategories = getState().subscriber.customCategories;

               if (nextDispatch && nextDispatch.setLoading) {
                    nextDispatch.setLoading(true);
               }

               if (nextDispatch && nextDispatch.setisLoadingLoadMore) {
                    nextDispatch.setisLoadingLoadMore(true);
               }

               if (!showIgnoredInventoryForm) {
                    listFormsChild = paging ? getState().subscriber[id] : [];
               } else {
                    listFormsChild = paging ? getState().subscriber[`ignored${id}`] : [];
               }

               const url = showIgnoredInventoryForm ? API_CLIENT_FETCH_CHILD_FORM_IGNORED : API_CLIENT_FETCH_CHILD_FORM_INVENTORY;

               filters.forEach((filter) => {
                    if (filter.type) {
                         const filterValue = filter.value.trim() ? filter.value : '';
                         filterQuery = `${filterQuery}&filter.${filter.type}.${filter.operator}=${filterValue}`;
                    }
               });

               callTokenApi(`${url.replace(':parentId', id)}?limit=${limit}&offset=${listFormsChild.length}${filterQuery}`, 'GET')
                    .then((response) => {
                         if (response.status === 200) {
                              const listFormResponse = response.data.formAccount;
                              const newListForms = limit ? [...listFormsChild, ...listFormResponse] : listFormResponse;
                              const newNumber = numberOffsetChild + listFormResponse.length;

                              //update suggest categories
                              newListForms.map((form) => {
                                   // exit suggest categories
                                   const flagExitSuggest = form.name && form.categoryId && !form.inheritParent && form.issue !== 'isDrafted' && form.issue !== 'isIgnored';

                                   if (flagExitSuggest && !form.disable) {
                                        form.exitSuggest = true;
                                   } else {
                                        form.exitSuggest = false;
                                   }

                                   const checkCategoryFake = (form.categoryId && form.categoryId.includes('fake')) || form.disable;
                                   const flagFormName = !form.name || form.name === null || form.name.trim() === '' || checkCategoryFake;
                                   const flagFormCate = !form.categoryId || form.categoryId === null || form.categoryId.trim() === '' || checkCategoryFake;

                                   // isAttention - isIgnored
                                   if (flagFormName && flagFormCate && form.issue !== 'isDrafted' && form.issue !== 'isIgnored') {
                                        form.disable = true;
                                   } else {
                                        form.disable = false;
                                   }

                                   if (flagFormName && flagFormCate && form.issue !== 'isDrafted' && form.issue !== 'isIgnored') {
                                        form.name = form.proposeName || form.name;
                                   }

                                   if (!form.inheritParent) {
                                        form.suggestInherit = false;
                                   }

                                   const matchingCategory =
                                        listCustomCategories && listCustomCategories.find((category) => category.name === form.proposeCategory);

                                   if (flagFormName && flagFormCate && form.issue !== 'isDrafted' && form.issue !== 'isIgnored') {
                                        if (matchingCategory) {
                                             form.categoryId = matchingCategory.id;
                                        } else {
                                             form.categoryId = form.proposeCategory ? 'fake-' + form.proposeCategory : null;
                                        }
                                   }

                                   return form;
                              });

                              //end suggest categories

                              if (!showIgnoredInventoryForm) {
                                   dispatch(
                                        setListFormsChild({
                                             [id]: newListForms,
                                             [`numberOffset${id}`]: newNumber,
                                        })
                                   );
                              } else {
                                   dispatch(
                                        setListFormsChild({
                                             [`ignored${id}`]: newListForms,
                                             [`numberOffset${id}`]: newNumber,
                                        })
                                   );
                              }

                              if (nextDispatch && nextDispatch.setShowLoadMoreBtn && !response.data.hasMore) {
                                   nextDispatch.setShowLoadMoreBtn(false);
                              }
                         } else {
                              toastError(response);
                         }
                    })
                    .finally(() => {
                         if (nextDispatch && nextDispatch.setLoading) {
                              nextDispatch.setLoading(false);
                         }
                         if (nextDispatch && nextDispatch.setFetched) {
                              nextDispatch.setFetched(true);
                         }
                         if (nextDispatch && nextDispatch.setisLoadingLoadMore) {
                              nextDispatch.setisLoadingLoadMore(false);
                         }
                    });
          };

export const handleFetchChildFormListSuggest = (parentId, newData) => (dispatch, getState) => {
     const getListChildForm = getState().subscriber[`${parentId}`];
     const formInventoryEdit = getState().subscriber.formInventoryEdit;

     if (getListChildForm && getListChildForm.length > 0) {
          if (!newData.inheritParent && newData.suggestInherit) {
               newData.categoryId = null;
               newData.name = null;
               newData.disable = !newData.disable; //false
               newData.suggestInherit = false;

               dispatch(setFormInventoryEdit(newData));
          } else {
               const updateListChildInventory = getListChildForm.map((child) => {
                    if (child.id === newData.id) {
                         const updateData = {
                              ...newData,
                              categoryId: null,
                              name: null,
                              disable: !newData.disable, //false
                              suggestInherit: false,
                         };
                         // In case of editing the parent and then editing the child
                         if (formInventoryEdit.some((form) => form.parentId === null && form.hasChild === true)) {
                              dispatch(setFormInventoryEdit(updateData));
                         }

                         return updateData;
                    }
                    return child;
               });
               dispatch(
                    setSubscriberState({
                         [parentId]: updateListChildInventory,
                    })
               );
          }
     }
};

export const handleCancelChildFormRequiringSuggest = (parentId, newData) => (dispatch, getState) => {
     const getListChildForm =  getState().subscriber[`requiring${parentId}`];
     const formRequiringEdit = getState().subscriber.formRequiringEdit;

     if (getListChildForm && getListChildForm.length > 0) {

          const updateListChildInventory = getListChildForm.map((child) => {
               if (child.id === newData.id) {
                    const updateData = {
                         ...newData,
                         proposeCategory: null,
                         proposeName: null
                    }
                    return updateData;
               }
               return child;
          });

               formRequiringEdit.forEach((item) => {
                    if (item.id === newData.id) {
                         dispatch(setFormRequiringEdit({ ...item, proposeCategory: null, proposeName: null }));
                    }
               })

               dispatch(
                    setSubscriberState({
                         [`requiring${parentId}`]: updateListChildInventory,
                    }),
               );
     }
};

export const handleFetchChildFormRequiring = (id, nextDispatch, limit, paging) => (dispatch, getState) => {
     let listFormsChild = [];
     const activeAccount = getState().subscriber.activeAccount;
     const showIgnoredForm = getState().subscriber.showIgnoredForm;
     const applyChild = getState().subscriber.applyChild;
     const numberOffsetChild = paging ? getState().subscriber[`numberOffset${id}`] : 0;
     const formRequiringEdit = getState().subscriber.formRequiringEdit;
     const requiringNumberPerpage = getState().subscriber.requiringNumberPerpage;
     const allFormsRequiring = getState().subscriber.allFormsRequiring;
     const listChildRequiring = getState().subscriber[`requiring${id}`];

     if (!showIgnoredForm) {
          listFormsChild = getState().subscriber[`requiring${id}`] || [];
     } else {
          listFormsChild = getState().subscriber[`ignored${id}`] || [];
     }

     if (nextDispatch && nextDispatch.setLoading) {
          nextDispatch.setLoading(true);
     }

     if (nextDispatch && nextDispatch.setisLoadingLoadMore) {
          nextDispatch.setisLoadingLoadMore(true);
     }

     const url = showIgnoredForm ? API_CLIENT_FETCH_CHILD_FORM_IGNORED : API_CLIENT_FETCH_CHILD_FORM_REQUIRING;

     callTokenApi(`${url.replace(':parentId', id)}?limit=${limit}&offset=${listFormsChild.length}`, 'GET')
          .then((response) => {
               if (response.status === 200) {
                    const listFormResponse = response.data.formAccount;
                    const countChild = response.data.count;
                    let newListForms = limit ? [...listFormsChild, ...listFormResponse] : listFormResponse;
                    const newNumber = numberOffsetChild + listFormResponse.length;

                    if (applyChild !== activeAccount.isInheritParent) {
                         newListForms.map((form) => {
                              form.inheritParent = applyChild;

                              return form;
                         });
                    }
                    if (!showIgnoredForm) {
                         dispatch(
                              setListFormsChild({
                                   [`requiring${id}`]: newListForms,
                                   [`numberOffset${id}`]: newNumber,
                              })
                         );
                    } else {
                         dispatch(
                              setListFormsChild({
                                   [`ignored${id}`]: newListForms,
                                   [`numberOffset${id}`]: newNumber,
                              })
                         );
                    }
                    dispatch(setLoadFormChildRequiring(null));

                    newListForms.forEach((form) => {
                         if (!form.inheritParent && form.issue === 'isDrafted') {
                              dispatch(setFormRequiringEdit(form));
                         }
                    });

                    if (nextDispatch && nextDispatch.setShowLoadMoreBtn && !response.data.hasMore) {
                         nextDispatch.setShowLoadMoreBtn(false);
                    }

                    if (countChild === 0 && listChildRequiring <= 0) {
                         const newAllFormsRequiring = allFormsRequiring.filter((form) => id !== form.id)
                         if (requiringNumberPerpage === 0) {
                              dispatch(setFormRequiringAction(newAllFormsRequiring.slice(0, 12)));
                         }
                         dispatch(setAllFormsRequiring(newAllFormsRequiring));
                    }

                    if (!paging) {
                         formRequiringEdit.forEach((form) => {
                              if (
                                   form.parentId &&
                                   listFormResponse &&
                                   listFormResponse.length === 0 &&
                                   form.name &&
                                   form.categoryId &&
                                   form.issue !== 'isDrafted'
                              ) {
                                   const newAllForms = allFormsRequiring.filter((item) => item.id !== form.parentId);
                                   if (requiringNumberPerpage === 0) {
                                        dispatch(setFormRequiringAction(newAllForms.slice(0, 12)));
                                   }
                                   dispatch(setAllFormsRequiring(newAllForms));
                              }
                         });
                    }
               } else {
                    toastError(response);
               }
          })
          .finally(() => {
               if (nextDispatch && nextDispatch.setLoading) {
                    nextDispatch.setLoading(false);
               }
               if (nextDispatch && nextDispatch.setFetched) {
                    nextDispatch.setFetched(true);
               }
               if (nextDispatch && nextDispatch.setisLoadingLoadMore) {
                    nextDispatch.setisLoadingLoadMore(false);
               }

               dispatch(setLoadingFormIgnore(false));
          });
};

const processUpdateTargetForm = (forms, targetRules) => {
     const newForms = [...forms];

     newForms.forEach((form, index) => {
          const content = { ...form };
          let isCustomForm = isMatchCustomForm(content, targetRules);

          content.isUnknown = !isCustomForm;
          newForms[index] = content;
     });

     return newForms;
};

export const handleUpdateTargetForm = (targetRules) => (dispatch, getState) => {
     const listenerFormsEnabled = getState().subscriber.listenerFormsEnabled;
     const filterFormCategories = getState().subscriber.filterFormCategories;
     const customFormListenerEnabled = listenerFormsEnabled.find((listener) => listener.code === 'customForms');

     if (customFormListenerEnabled) {
          const allFormsInventory = getState().subscriber.allFormsInventory || [];
          const formsInventory = getState().subscriber.formsInventory || [];

          if (filterFormCategories && filterFormCategories.length > 0) {
               dispatch(handleFetchFormsInventory(20, false, 0, filterFormCategories));
               dispatch(handleFetchCountFormsInventory(null, null, filterFormCategories));
          } else {
               if (allFormsInventory.length > 0) {
                    const newAllForms = [...allFormsInventory];

                    allFormsInventory.forEach((form, index) => {
                         if (form.listenerId === customFormListenerEnabled.id) {
                              if (form.hasChild) {
                                   const formsChild = getState().subscriber[form.id];

                                   if (formsChild) {
                                        const newForms = processUpdateTargetForm(formsChild, targetRules);

                                        dispatch(
                                             setSubscriberState({
                                                  [form.id]: newForms,
                                             })
                                        );
                                   }
                              }

                              let isCustomForm = isMatchCustomForm(form, targetRules);

                              form.isUnknown = !isCustomForm;
                              newAllForms[index] = form;
                         }
                    });

                    dispatch(setAllFormsInventory(newAllForms));
               }

               if (formsInventory.length > 0) {
                    const newAllForms = [...formsInventory];

                    formsInventory.forEach((form, index) => {
                         if (form.listenerId === customFormListenerEnabled.id) {
                              if (form.hasChild) {
                                   const formsChild = getState().subscriber[form.id];

                                   if (formsChild) {
                                        const newForms = processUpdateTargetForm(formsChild, targetRules);

                                        dispatch(
                                             setSubscriberState({
                                                  [form.id]: newForms,
                                             })
                                        );
                                   }
                              }

                              let isCustomForm = isMatchCustomForm(form, targetRules);

                              form.isUnknown = !isCustomForm;
                              newAllForms[index] = form;
                         }
                    });

                    dispatch(setFormInventory(newAllForms));
               }
          }

          const allFormsRequiring = getState().subscriber.allFormsRequiring || [];
          const formsRequiring = getState().subscriber.formsRequiring || [];

          if (allFormsRequiring.length > 0) {
               const newAllForms = [...allFormsRequiring];

               allFormsRequiring.forEach((form, index) => {
                    if (form.listenerId === customFormListenerEnabled.id) {
                         if (form.hasChild) {
                              const formsChild = getState().subscriber[`requiring${form.id}`];

                              if (formsChild) {
                                   const newForms = processUpdateTargetForm(formsChild, targetRules);
                                   dispatch(
                                        setSubscriberState({
                                             [`requiring${form.id}`]: newForms,
                                        })
                                   );
                              }
                         }

                         let isCustomForm = isMatchCustomForm(form, targetRules);

                         form.isUnknown = !isCustomForm;
                         newAllForms[index] = form;

                         const formRequiringEdit = getState().subscriber.formRequiringEdit;
                         if (formRequiringEdit) {
                              formRequiringEdit.forEach((data) => {
                                   if (data.parentId && data.parentId === form.id && !data.inheritParent) {
                                        data.isUnknown = form.isUnknown;
                                        dispatch(setFormRequiringEdit(data));
                                   }
                              });
                         }
                    }
               });

               dispatch(setAllFormsRequiring(newAllForms));
          }

          if (formsRequiring.length > 0) {
               const newAllForms = [...formsRequiring];

               formsRequiring.forEach((form, index) => {
                    if (form.listenerId === customFormListenerEnabled.id) {
                         if (form.hasChild) {
                              const formsChild = getState().subscriber[`requiring${form.id}`];

                              if (formsChild) {
                                   const newForms = processUpdateTargetForm(formsChild, targetRules);

                                   dispatch(
                                        setSubscriberState({
                                             [`requiring${form.id}`]: newForms,
                                        })
                                   );
                              }
                         }

                         let isCustomForm = isMatchCustomForm(form, targetRules);

                         form.isUnknown = !isCustomForm;
                         newAllForms[index] = form;

                         const formRequiringEdit = getState().subscriber.formRequiringEdit;
                         if (formRequiringEdit) {
                              formRequiringEdit.forEach((data) => {
                                   if (data.parentId && data.parentId === form.id && !data.inheritParent) {
                                        data.isUnknown = form.isUnknown;
                                        dispatch(setFormRequiringEdit(data));
                                   }
                              });
                         }
                    }
               });

               dispatch(setFormRequiringAction(newAllForms));
          }

          const allFormsIgnored = getState().subscriber.allFormsIgnored || [];
          const formsIgnore = getState().subscriber.formIgnored || [];

          if (allFormsIgnored.length > 0) {
               const newAllForms = [...allFormsIgnored];

               allFormsIgnored.forEach((form, index) => {
                    if (form.listenerId === customFormListenerEnabled.id) {
                         if (form.hasChild) {
                              const formsChild = getState().subscriber[`ignored${form.id}`];

                              if (formsChild) {
                                   const newForms = processUpdateTargetForm(formsChild, targetRules);

                                   dispatch(
                                        setSubscriberState({
                                             [`ignored${form.id}`]: newForms,
                                        })
                                   );
                              }
                         }

                         let isCustomForm = isMatchCustomForm(form, targetRules);

                         form.isUnknown = !isCustomForm;
                         newAllForms[index] = form;

                         const formIgnoreEdit = getState().subscriber.formIgnoreEdit;
                         if (formIgnoreEdit) {
                              formIgnoreEdit.forEach((data) => {
                                   if (data.parentId && data.parentId === form.id && !data.inheritParent) {
                                        data.isUnknown = form.isUnknown;
                                        dispatch(setFormIgnoreEdit(data));
                                   }
                              });
                         }
                    }
               });

               dispatch(setAllFormsIgnore(newAllForms));
          }

          if (formsIgnore.length > 0) {
               const newAllForms = [...formsIgnore];

               formsIgnore.forEach((form, index) => {
                    if (form.listenerId === customFormListenerEnabled.id) {
                         if (form.hasChild) {
                              const formsChild = getState().subscriber[`ignored${form.id}`];

                              if (formsChild) {
                                   const newForms = processUpdateTargetForm(formsChild, targetRules);

                                   dispatch(
                                        setSubscriberState({
                                             [`ignored${form.id}`]: newForms,
                                        })
                                   );
                              }
                         }

                         let isCustomForm = isMatchCustomForm(form, targetRules);

                         form.isUnknown = !isCustomForm;
                         newAllForms[index] = form;

                         const formIgnoreEdit = getState().subscriber.formIgnoreEdit;
                         if (formIgnoreEdit) {
                              formIgnoreEdit.forEach((data) => {
                                   if (data.parentId && data.parentId === form.id && !data.inheritParent) {
                                        data.isUnknown = form.isUnknown;
                                        dispatch(setFormIgnoreEdit(data));
                                   }
                              });
                         }
                    }
               });

               dispatch(setFormIgnored(newAllForms));
          }
     }
};

// update Target Custom No Class And Id 
export const handleUpdateTargetPusher = (data) => (dispatch, getState) => {
     const { formUnknownIds, formCustomIds } = data

     const listenerFormsEnabled = getState().subscriber.listenerFormsEnabled;
     const filterFormCategories = getState().subscriber.filterFormCategories;
     const customFormListenerEnabled = listenerFormsEnabled.find((listener) => listener.code === 'customForms');

     if (customFormListenerEnabled) {
          const allFormsInventory = getState().subscriber.allFormsInventory || [];
          const formsInventory = getState().subscriber.formsInventory || [];

          if (filterFormCategories && filterFormCategories.length > 0) {
               dispatch(handleFetchFormsInventory(20, false, 0, filterFormCategories));
               dispatch(handleFetchCountFormsInventory(null, null, filterFormCategories));
          } else {
               if (allFormsInventory.length > 0) {
                    const newAllForms = [...allFormsInventory];

                    allFormsInventory.forEach((form, index) => {
                         const formNoValue = !form.formValue || form.formValue === '';
                         if (form.listenerId === customFormListenerEnabled.id && formNoValue) {
                              form.isUnknown = formCustomIds.includes(form.id) ? false : formUnknownIds.includes(form.id) ? true : form.isUnknown;
                              newAllForms[index] = form;
                         }
                    });

                    dispatch(setAllFormsInventory(newAllForms));
               }

               if (formsInventory.length > 0) {
                    const newAllForms = [...formsInventory];

                    formsInventory.forEach((form, index) => {
                         const formNoValue = !form.formValue || form.formValue === '';
                         if (form.listenerId === customFormListenerEnabled.id && formNoValue) {
                              form.isUnknown = formCustomIds.includes(form.id) ? false : formUnknownIds.includes(form.id) ? true : form.isUnknown;
                              newAllForms[index] = form;
                         }
                    });

                    dispatch(setFormInventory(newAllForms));
               }
          }

          const allFormsRequiring = getState().subscriber.allFormsRequiring || [];
          const formsRequiring = getState().subscriber.formsRequiring || [];

          if (allFormsRequiring.length > 0) {
               const newAllForms = [...allFormsRequiring];

               allFormsRequiring.forEach((form, index) => {
                    const formNoValue = !form.formValue || form.formValue === '';
                    if (form.listenerId === customFormListenerEnabled.id && formNoValue) {
                         form.isUnknown = formCustomIds.includes(form.id) ? false : formUnknownIds.includes(form.id) ? true : form.isUnknown
                         newAllForms[index] = form;

                         const formRequiringEdit = getState().subscriber.formRequiringEdit;
                         if (formRequiringEdit) {
                              formRequiringEdit.forEach((data) => {
                                   if (data.parentId && data.parentId === form.id && !data.inheritParent) {
                                        data.isUnknown = form.isUnknown;
                                        dispatch(setFormRequiringEdit(data));
                                   }
                              });
                         }
                    }
               });

               dispatch(setAllFormsRequiring(newAllForms));
          }

          if (formsRequiring.length > 0) {
               const newAllForms = [...formsRequiring];

               formsRequiring.forEach((form, index) => {
                    const formNoValue = !form.formValue || form.formValue === '';
                    if (form.listenerId === customFormListenerEnabled.id && formNoValue) {
                         form.isUnknown = formCustomIds.includes(form.id) ? false : formUnknownIds.includes(form.id) ? true : form.isUnknown;
                         newAllForms[index] = form;

                         const formRequiringEdit = getState().subscriber.formRequiringEdit;
                         if (formRequiringEdit) {
                              formRequiringEdit.forEach((data) => {
                                   if (data.parentId && data.parentId === form.id && !data.inheritParent) {
                                        data.isUnknown = form.isUnknown;
                                        dispatch(setFormRequiringEdit(data));
                                   }
                              });
                         }
                    }
               });

               dispatch(setFormRequiringAction(newAllForms));
          }

          const allFormsIgnored = getState().subscriber.allFormsIgnored || [];
          const formsIgnore = getState().subscriber.formIgnored || [];

          if (allFormsIgnored.length > 0) {
               const newAllForms = [...allFormsIgnored];

               allFormsIgnored.forEach((form, index) => {
                    const formNoValue = !form.formValue || form.formValue === '';
                    if (form.listenerId === customFormListenerEnabled.id && formNoValue) {
                         form.isUnknown = formCustomIds.includes(form.id) ? false : formUnknownIds.includes(form.id) ? true : form.isUnknown;
                         newAllForms[index] = form;

                         const formIgnoreEdit = getState().subscriber.formIgnoreEdit;
                         if (formIgnoreEdit) {
                              formIgnoreEdit.forEach((data) => {
                                   if (data.parentId && data.parentId === form.id && !data.inheritParent) {
                                        data.isUnknown = form.isUnknown;
                                        dispatch(setFormIgnoreEdit(data));
                                   }
                              });
                         }
                    }
               });

               dispatch(setAllFormsIgnore(newAllForms));
          }

          if (formsIgnore.length > 0) {
               const newAllForms = [...formsIgnore];

               formsIgnore.forEach((form, index) => {
                    const formNoValue = !form.formValue || form.formValue === '';
                    if (form.listenerId === customFormListenerEnabled.id && formNoValue) {
                         form.isUnknown = formCustomIds.includes(form.id) ? false : formUnknownIds.includes(form.id) ? true : form.isUnknown;
                         newAllForms[index] = form;

                         const formIgnoreEdit = getState().subscriber.formIgnoreEdit;
                         if (formIgnoreEdit) {
                              formIgnoreEdit.forEach((data) => {
                                   if (data.parentId && data.parentId === form.id && !data.inheritParent) {
                                        data.isUnknown = form.isUnknown;
                                        dispatch(setFormIgnoreEdit(data));
                                   }
                              });
                         }
                    }
               });

               dispatch(setFormIgnored(newAllForms));
          }
     }
}

export const handleFetchCountFormsRequiring = (nextDispatch, called, activeAccount) => (dispatch, getState) => {
     let newActiveAccount = activeAccount;

     if (!activeAccount) {
          newActiveAccount = getState().subscriber.activeAccount;
     }
     if (nextDispatch && nextDispatch.setIsLoading) {
          nextDispatch.setIsLoading(true);
     }

     if (called) {
          dispatch(setSubscriberState({ ignoredLoading: true }));
     }

     dispatch(setSubscriberState({ requiringCountLoading: true }));

     callTokenApi(`${API_CLIENT_COUNT_FORM_REQUIRING.replace(':accountId', newActiveAccount.id)}`, 'GET')
          .then((response) => {
               if (response.status === 200) {
                    const countFormRequiring = response.data.countForms;
                    dispatch(setCountFormRequiring(countFormRequiring));
               } else {
                    toastError(response);
               }
          })
          .finally(() => {
               if (nextDispatch && nextDispatch.setIsLoading) {
                    nextDispatch.setIsLoading(false);
               }
               if (called) {
                    dispatch(setSubscriberState({ ignoredLoading: false }));
               }
               dispatch(setSubscriberState({ requiringCountLoading: false }));
          });
};

export const resetFormCategoryPusher = () => (dispatch) => {
     dispatch(handleFetchCountFormsRequiring());
     dispatch(handleFetchCountFormsIgnored());
     dispatch(handleFetchCountFormsInventory());
};

export const handleFetchCountFormsIgnored = (nextDispatch, activeAccount) => (dispatch, getState) => {
     let newActiveAccount = activeAccount;
     if (!activeAccount) {
          newActiveAccount = getState().subscriber.activeAccount;
     }
     const { id: accountId } = newActiveAccount;

     if (nextDispatch && nextDispatch.setIsLoading) {
          nextDispatch.setIsLoading(true);
     }

     dispatch(setSubscriberState({ ignoredCountLoading: true }));

     if (accountId && accountId !== '') {
          callTokenApi(`${API_CLIENT_COUNT_FORM_IGNORED.replace(':accountId', accountId)}`, 'GET')
               .then((response) => {
                    if (response.status === 200) {
                         const countFormIgnored = response.data.countForms;
                         dispatch(setCountFormIgnored(countFormIgnored));
                    } else {
                         toastError(response);
                    }
               })
               .finally(() => {
                    if (nextDispatch && nextDispatch.setIsLoading) {
                         nextDispatch.setIsLoading(false);
                    }
                    dispatch(setSubscriberState({ ignoredCountLoading: false }));
               });
     }
};

export const handleFetchCountFormsInventory =
     (nextDispatch, activeAccount, filters = []) =>
          (dispatch, getState) => {
               let newActiveAccount = activeAccount;
               let filterQuery = '';

               if (!activeAccount) {
                    newActiveAccount = getState().subscriber.activeAccount;
               }
               const { id: accountId } = newActiveAccount;

               if (nextDispatch && nextDispatch.setIsLoading) {
                    nextDispatch.setIsLoading(true);
               }
               dispatch(setSubscriberState({ inventoryCountLoading: true }));

               const newFilters = filters || getState().subscriber.filterFormCategories;

               newFilters.forEach((filter) => {
                    if (filter.type) {
                         const filterValue = filter.value.trim() ? filter.value : '';
                         filterQuery = `${filterQuery}&filter.${filter.type}.${filter.operator}=${filterValue}`;
                    }
               });

               if (accountId && accountId !== '') {
                    callTokenApi(`${API_CLIENT_COUNT_FORM_INVENTORY.replace(':accountId', accountId)}?${filterQuery}`, 'GET')
                         .then((response) => {
                              if (response.status === 200) {
                                   const countFormInventory = response.data.countForms;
                                   dispatch(setCountFormInventory(countFormInventory));
                              } else {
                                   toastError(response);
                              }
                         })
                         .finally(() => {
                              if (nextDispatch && nextDispatch.setIsLoading) {
                                   nextDispatch.setIsLoading(false);
                              }
                              dispatch(setSubscriberState({ inventoryCountLoading: false }));
                         });
               }
          };

export const handleFetchInheritParent = () => (dispatch, getState) => {
     const activeAccount = getState().subscriber.activeAccount;

     dispatch(setFormInherit(activeAccount.isInheritParent));
};

export const handleFetchPushEvents = () => (dispatch, getState) => {
     const activeAccount = getState().subscriber.activeAccount;

     dispatch(setFormRedirect(activeAccount.isPushEvents));
     // const { id: accountId } = activeAccount;
     // if (nextDispatch && nextDispatch.setIsLoading) {
     //      nextDispatch.setIsLoading(true);
     // }
     // callTokenApi(API_CLIENT_GET_PUSH_EVENTS.replace(':accountId', accountId), 'GET')
     //      .then((response) => {
     //           if (response.status === 200) {
     //                const isPushEvents = response.data.newData.isPushEvents;

     //           } else {
     //                toastError(response);
     //           }
     //      })
     //      .finally(() => {
     //           if (nextDispatch && nextDispatch.setIsLoading) {
     //                nextDispatch.setIsLoading(false);
     //           }
     //      });
};

export const handleApplyChildForms = (turnOn, type, setErrors) => (dispatch, getState) => {
     let formsPaging = [],
          allForms = [],
          formsEdit = [];

     switch (type) {
          case 'ignored':
               formsPaging = getState().subscriber.formIgnored;
               allForms = getState().subscriber.allFormsIgnored;
               formsEdit = getState().subscriber.formIgnoreEdit;
               break;
          case 'requiring':
               formsPaging = getState().subscriber.formsRequiring;
               allForms = getState().subscriber.allFormsRequiring;
               formsEdit = getState().subscriber.formRequiringEdit;
               break;
          // case 'inventory':
          //      formsPaging = getState().subscriber.formInventory;
          //      allForms = getState().subscriber.allFormsInventory;
          //      formsEdit = getState().subscriber.formInventoryEdit;
          //      break;
          default:
               break;
     }
     const inheritErrors = [];

     if (formsPaging.length > 0) {
          formsPaging.map((form) => {
               if (form.hasChild) {
                    // if (form.categoryId && form.name) {
                    const listFormsChild = getState().subscriber[type + form.id];
                    const listFormsChildInventory = getState().subscriber[form.id];

                    if (listFormsChild) {
                         const listId = [];
                         const childs = listFormsChild.map((item) => {
                              if (turnOn) {
                                   item.categoryId = form.categoryId;
                                   item.name = form.name;
                                   // item.isRedirect = form.isRedirect;
                                   item.inheritParent = true;
                              } else {
                                   item.categoryId = null;
                                   item.name = '';
                                   // item.isRedirect = false;
                                   item.inheritParent = false;
                              }

                              listId.push(item.id);
                              formsEdit.every((child) => {
                                   if (child.id === item.id) {
                                        if (child.inheritParent !== item.inheritParent) {
                                             child.inheritParent = turnOn;
                                        }
                                   }
                                   return child;
                              });

                              return item;
                         });

                         const newFormsChilds = childs.map((child) => {
                              const existed = formsEdit.find((item) => item.id === child.id);

                              if (existed && child.name && child.categoryId) {
                                   child = { ...existed, ...child };
                              }

                              return child;
                         });

                         const newFormsEdit = [];
                         newFormsChilds.forEach((child) => {
                              const existed = formsEdit.find((item) => item.id === child.id);

                              if (turnOn && existed) {
                                   newFormsEdit.push(child);
                              }
                         });

                         formsEdit = newFormsEdit && newFormsEdit.length > 0 ? newFormsEdit : formsEdit;

                         dispatch(
                              setSubscriberState({
                                   [type + form.id]: newFormsChilds,
                              })
                         );
                    }

                    if (listFormsChildInventory) {
                         dispatch(
                              setSubscriberState({
                                   [form.id]: null,
                              })
                         );
                    }
                    // } else {
                    //      if (!form.isUnknown) {
                    //           inheritErrors.push({ id: form.id, invalidCategory: true, invalidName: true });
                    //      }
                    // }
               }

               return form;
          });
     }

     if (setErrors && inheritErrors.length) {
          setErrors(inheritErrors);
     }

     if (allForms.length > 0) {
          allForms.map((form) => {
               if (form.hasChild) {
                    // if (form.categoryId && form.name) {
                    const listFormsChild = getState().subscriber[type + form.id];
                    const listFormsChildInventory = getState().subscriber[form.id];

                    if (listFormsChild) {
                         const listId = [];
                         const childs = listFormsChild.map((item) => {
                              if (turnOn) {
                                   item.categoryId = form.categoryId;
                                   item.name = form.name;
                                   // item.isRedirect = form.isRedirect;
                                   item.inheritParent = true;
                              } else {
                                   item.categoryId = null;
                                   item.name = '';
                                   // item.isRedirect = false;
                                   item.inheritParent = false;
                              }

                              listId.push(item.id);
                              formsEdit.every((child) => {
                                   if (child.id === item.id) {
                                        if (child.inheritParent !== item.inheritParent) {
                                             child.inheritParent = turnOn;
                                        }
                                   }
                                   return child;
                              });
                              return item;
                         });

                         const newFormsChilds = childs.map((child) => {
                              const existed = formsEdit.find((item) => item.id === child.id);

                              if (existed && child.name && child.categoryId) {
                                   child = { ...existed, ...child };
                              }

                              return child;
                         });

                         const newFormsEdit = [];
                         newFormsChilds.forEach((child) => {
                              const existed = formsEdit.find((item) => item.id === child.id);

                              if (turnOn && existed) {
                                   newFormsEdit.push(child);
                              }
                         });

                         formsEdit = newFormsEdit && newFormsEdit.length > 0 ? newFormsEdit : formsEdit;

                         dispatch(
                              setSubscriberState({
                                   [type + form.id]: newFormsChilds,
                              })
                         );
                    }

                    if (listFormsChildInventory) {
                         dispatch(
                              setSubscriberState({
                                   [form.id]: null,
                              })
                         );
                    }
                    // }
               }
               return form;
          });
     }

     // if (!turnOn) {
     //      formsEdit = [];
     // }

     switch (type) {
          case 'ignored':
               dispatch(
                    setSubscriberState({
                         formIgnoreEdit: formsEdit,
                    })
               );
               break;
          case 'requiring':
               dispatch(
                    setSubscriberState({
                         formRequiringEdit: formsEdit,
                    })
               );
               break;
          // case 'inventory':
          //      dispatch(
          //           setSubscriberState({
          //                formInventoryEdit: formsEdit
          //           })
          //      );
          //      break;
          default:
               break;
     }
};

export const handleIgnoredFormChildRequiring = (formsCategoriesId, formId, isChild, numberFormChildRequiring) => (dispatch, getState) => {
     // const formsRequiring = getState().subscriber.formsRequiring;
     const allFormsRequiring = getState().subscriber.allFormsRequiring;
     const listFormChild = getState().subscriber[`requiring${formsCategoriesId}`];
     const listFormChildInventory = getState().subscriber[formsCategoriesId];
     const requiringNumberPerpage = getState().subscriber.requiringNumberPerpage;

     if (!isChild) {
          // const newListForms = formsRequiring.filter((item) => item.id !== formId);
          const newAllForms = allFormsRequiring.filter((item) => item.id !== formId);

          if (requiringNumberPerpage === 0) {
               dispatch(setFormRequiringAction(newAllForms.slice(0, 12)));
          }
          dispatch(setAllFormsRequiring(newAllForms));
     } else {
          if (numberFormChildRequiring === 0) {
               // const newListForms = formsRequiring.filter((item) => item.id !== formsCategoriesId);
               const newAllForms = allFormsRequiring.filter((item) => item.id !== formsCategoriesId);

               if (requiringNumberPerpage === 0) {
                    dispatch(setFormRequiringAction(newAllForms.slice(0, 12)));
               }
               dispatch(setAllFormsRequiring(newAllForms));
          }

          if (listFormChild) {
               const newListChild = listFormChild.filter((form) => form.id !== formId);
               const newListChildInventory = listFormChildInventory && listFormChildInventory.filter((form) => form.id !== formId);

               dispatch(
                    setSubscriberState({
                         [`requiring${formsCategoriesId}`]: newListChild,
                    })
               );

               dispatch(
                    setSubscriberState({
                         [formsCategoriesId]: newListChildInventory,
                    })
               );
          }
     }
};

export const handleIgnoredFormChildInventory = (formsCategoriesId, formId, isChild, numberFormChildInventory) => (dispatch, getState) => {
     // const formInventory = getState().subscriber.formInventory;
     const allFormInventory = getState().subscriber.allFormsInventory;
     const listFormChild = getState().subscriber[formsCategoriesId];
     const listFormChildInventory = getState().subscriber[formsCategoriesId];
     const inventoryNumberPerpage = getState().subscriber.inventoryNumberPerpage;

     if (!isChild) {
          // const newListForms = formInventory.filter((item) => item.id !== formId);
          const newAllForms = allFormInventory.filter((item) => item.id !== formId);

          if (inventoryNumberPerpage === 0) {
               dispatch(setFormInventory(newAllForms.slice(0, 12)));
          }
          dispatch(setAllFormsInventory(newAllForms));
     } else {
          if (numberFormChildInventory === 0) {
               // const newListForms = formInventory.filter((item) => item.id !== formsCategoriesId);
               const newAllForms = allFormInventory.filter((item) => item.id !== formsCategoriesId);

               if (inventoryNumberPerpage === 0) {
                    dispatch(setFormInventory(newAllForms.slice(0, 12)));
               }
               dispatch(setAllFormsInventory(newAllForms));
          }

          if (listFormChild) {
               const newListChild = listFormChild.filter((form) => form.id !== formId);
               const newListChildInventory = listFormChildInventory && listFormChildInventory.filter((form) => form.id !== formId);
               dispatch(
                    setSubscriberState({
                         [formsCategoriesId]: newListChild,
                    })
               );

               dispatch(
                    setSubscriberState({
                         [formsCategoriesId]: newListChildInventory,
                    })
               );
          }
          dispatch(
               setSubscriberState({
                    [`ignored${formsCategoriesId}`]: null,
               })
          );
     }
};

export const handleUpdateChildForms = () => (dispatch, getState) => {
     const formRequiringEdit = getState().subscriber.formRequiringEdit;
     const requiringNumberPerpage = getState().subscriber.requiringNumberPerpage;

     formRequiringEdit.forEach((parent) => {
          if (parent.hasChild) {
               const childs = getState().subscriber[`requiring${parent.id}`];

               if (childs) {
                    let newChilds = [];

                    if (parent.name && parent.categoryId) {
                         newChilds = childs.filter((item) => (item.issue === 'isDrafted' || !item.name || !item.categoryId) && !item.inheritParent);

                         if (newChilds.length === 0) {
                              formRequiringEdit.forEach((form) => {
                                   if (form.parentId === parent.id && (form.issue === 'isDrafted' || !form.name || !form.categoryId)) {
                                        newChilds.push(form);
                                   }
                              });
                         }
                    } else {
                         newChilds = childs;
                    }

                    dispatch(
                         setSubscriberState({
                              [`requiring${parent.id}`]: newChilds,
                         })
                    );
                    dispatch(
                         setSubscriberState({
                              [parent.id]: null,
                         })
                    );
               }
          } else {
               const childs = getState().subscriber[`requiring${parent.parentId}`];
               let newChilds = [];
               if (childs) {
                    if (parent.name && parent.categoryId && parent.issue !== 'isDrafted') {
                         newChilds = childs.filter((item) => item.id !== parent.id);
                    } else {
                         newChilds = childs.map((form) => {
                              if (form.id === parent.id) {
                                   form.inheritParent = parent.inheritParent;
                                   form.isRedirect = parent.isRedirect;
                              }
                              return form;
                         });
                    }

                    if (childs.length === 1 && parent.name && parent.categoryId && parent.issue !== 'isDrafted') {
                         const allFormsRequiring = getState().subscriber.allFormsRequiring;
                         const newAllForms = allFormsRequiring.filter((item) => item.id !== parent.parentId);
                         if (requiringNumberPerpage === 0) {
                              dispatch(setFormRequiringAction(newAllForms.slice(0, 12)));
                         }
                         dispatch(setAllFormsRequiring(newAllForms));
                    }

                    const formChildExist = newChilds.some((form) => form.id === parent.id);

                    if (!parent.name && !parent.categoryId && !parent.inheritParent && !formChildExist) {
                         newChilds.push(parent);
                    }

                    dispatch(
                         setSubscriberState({
                              [parent.parentId]: null,
                         })
                    );

                    dispatch(
                         setSubscriberState({
                              [`requiring${parent.parentId}`]: newChilds,
                         })
                    );
               }
          }
     });
};

export const handleUpdateChildFormsInventory = () => (dispatch, getState) => {
     const formInventoryEdit = getState().subscriber.formInventoryEdit;
     const listCustomCategories = getState().subscriber.customCategories;

     formInventoryEdit.forEach((parent) => {
          if (parent.hasChild) {
               const childs = getState().subscriber[parent.id];

               if (childs) {
                    const newChilds = childs.map((item) => {
                         let updatedItem = { ...item };

                         const existed = formInventoryEdit.find((child) => child.id === item.id);

                         if (existed) {
                              updatedItem = { ...updatedItem, ...existed };
                         }

                         if (updatedItem.issue !== 'isIgnored') {
                              return updatedItem;
                         }

                         return item;
                    });

                    dispatch(
                         setSubscriberState({
                              [parent.id]: newChilds,
                         }),
                    );

                    dispatch(
                         setSubscriberState({
                              [`requiring${parent.id}`]: null,
                         }),
                    );
               }
          } else {
               const childs = getState().subscriber[parent.parentId];

               if (childs) {
                    const newChilds = childs.map((item) => {
                         let updatedItem = { ...item };

                         const existed = formInventoryEdit.find((child) => child.id === item.id);

                         if (existed) {
                              const initialExitSuggest = existed.exitSuggest;
                              const flagExitSuggest = !existed.name && !existed.categoryId && !existed.inheritParent;

                              const updatedExisted = { ...existed };

                              if (!initialExitSuggest) {
                                   if (!updatedExisted.inheritParent && updatedExisted.suggestInherit && updatedExisted.issue !== 'isIgnored') {
                                        updatedExisted.suggestInherit = false;

                                        if (updatedExisted.proposeName !== null) {
                                             updatedExisted.name = updatedExisted.proposeName;
                                        }

                                        if (updatedExisted.proposeCategory !== null) {
                                             const categoryExists = listCustomCategories.find(
                                                  (category) => category.name === updatedExisted.proposeCategory,
                                             );

                                             if (categoryExists) {
                                                  updatedExisted.categoryId = categoryExists.id;
                                             } else {
                                                  updatedExisted.categoryId = 'fake-' + updatedExisted.proposeCategory;
                                             }
                                        }
                                   }
                              }

                              if (flagExitSuggest) {
                                   if (updatedExisted.issue !== 'isDrafted' && updatedExisted.issue !== 'isIgnored') {
                                        updatedExisted.exitSuggest = false;
                                   }
                              } else {
                                   updatedExisted.exitSuggest = true;
                              }

                              if (existed.inheritParent) {
                                   updatedExisted.exitSuggest = false;
                              }

                              updatedItem = { ...updatedItem, ...updatedExisted };
                         }

                         if (updatedItem.issue !== 'isIgnored') {
                              return updatedItem;
                         }

                         return item;
                    });

                    dispatch(
                         setSubscriberState({
                              [parent.parentId]: newChilds,
                         }),
                    );

                    dispatch(
                         setSubscriberState({
                              [`requiring${parent.parentId}`]: null,
                         }),
                    );
               }
          }
     });
};

export const handleUpdateChildFormsIgnored = () => (dispatch, getState) => {
     const formIgnoreEdit = getState().subscriber.formIgnoreEdit;

     formIgnoreEdit.forEach((parent) => {
          if (parent.hasChild) {
               const childs = getState().subscriber[`ignored${parent.id}`];

               if (childs) {
                    let newChilds = [];
                    if (parent.name && parent.categoryId) {
                         newChilds = childs.filter((item) => item.issue === 'isIgnored' && !item.inheritParent);
                    } else {
                         newChilds = childs;
                    }

                    dispatch(
                         setSubscriberState({
                              [`ignored${parent.id}`]: newChilds,
                         })
                    );
               }
          } else {
               if (parent.parentId) {
                    const childs = getState().subscriber[`ignored${parent.parentId}`];

                    let newChilds = [];
                    if (childs) {
                         if (parent.name && parent.categoryId) {
                              newChilds = childs.filter((item) => item.id !== parent.id);
                         } else {
                              newChilds = childs.map((form) => {
                                   if (form.id === parent.id) {
                                        form.isRedirect = parent.isRedirect;
                                        form.issue = parent.issue;
                                        form.inheritParent = parent.inheritParent;
                                   }
                                   return form;
                              });
                         }

                         dispatch(
                              setSubscriberState({
                                   [`ignored${parent.parentId}`]: newChilds,
                              })
                         );

                         if (childs.length === 1 && parent.name && parent.categoryId && parent.count === 0) {
                              const formIgnored = getState().subscriber.formIgnored;
                              const allFormsIgnored = getState().subscriber.allFormsIgnored;
                              const newForms = formIgnored.filter((item) => item.id !== parent.parentId);
                              dispatch(setFormIgnored(newForms));
                              const newAllForms = allFormsIgnored.filter((item) => item.id !== parent.parentId);
                              dispatch(setAllFormsIgnore(newAllForms));
                         }

                         const formChildExist = newChilds.some((form) => form.id === parent.id);

                         if (!parent.name && !parent.categoryId && !formChildExist) {
                              newChilds.push(parent);
                              dispatch(
                                   setSubscriberState({
                                        [`ignored${parent.parentId}`]: newChilds,
                                   })
                              );
                         }
                    } else {
                         const formChildExist = newChilds.some((form) => form.id === parent.id);

                         if (!parent.name && !parent.categoryId && !formChildExist) {
                              newChilds.push(parent);
                              dispatch(
                                   setSubscriberState({
                                        [`ignored${parent.parentId}`]: newChilds,
                                   })
                              );
                         }
                    }
               }
          }
     });
};

export const handleApplyRedirectForms = (turnOn, type) => (dispatch, getState) => {
     let formsPaging = [],
          allForms = [],
          formsEdit = [];

     switch (type) {
          case 'ignored':
               formsPaging = getState().subscriber.formIgnored;
               allForms = getState().subscriber.allFormsIgnored;
               formsEdit = getState().subscriber.formIgnoreEdit;
               break;
          case 'requiring':
               formsPaging = getState().subscriber.formsRequiring;
               allForms = getState().subscriber.allFormsRequiring;
               formsEdit = getState().subscriber.formRequiringEdit;
               break;
          // case 'inventory':
          //      formsPaging = getState().subscriber.formInventory;
          //      allForms = getState().subscriber.allFormsInventory;
          //      formsEdit = getState().subscriber.formInventoryEdit;
          //      break;
          default:
               break;
     }

     if (formsPaging.length > 0) {
          const parents = formsPaging.map((form) => {
               if (form.hasChild) {
                    const listFormsChild = getState().subscriber[type + form.id];
                    const listFormsChildInventory = getState().subscriber[form.id];

                    if (listFormsChild) {
                         const childs = listFormsChild.map((item) => {
                              if (turnOn) {
                                   item.isRedirect = true;
                              } else {
                                   item.isRedirect = false;
                              }

                              return item;
                         });

                         formsEdit = formsEdit.map((item) => {
                              const existed = childs.find((child) => child.id === item.id);

                              if (existed) {
                                   item = { ...item, isRedirect: existed.isRedirect };
                              }

                              return item;
                         });

                         dispatch(
                              setSubscriberState({
                                   [type + form.id]: childs,
                              })
                         );
                    }

                    if (listFormsChildInventory) {
                         dispatch(
                              setSubscriberState({
                                   [form.id]: null,
                              })
                         );
                    }
               }

               if (turnOn) {
                    form.isRedirect = true;
               } else {
                    form.isRedirect = false;
               }

               return form;
          });

          formsEdit = formsEdit.map((item) => {
               const existed = parents.find((child) => child.id === item.id);

               if (existed) {
                    item = { ...item, isRedirect: existed.isRedirect };
               }

               return item;
          });
     }

     if (allForms.length > 0) {
          const parents = allForms.map((form) => {
               if (form.hasChild) {
                    const listFormsChild = getState().subscriber[type + form.id];
                    const listFormsChildInventory = getState().subscriber[form.id];

                    if (listFormsChild) {
                         const childs = listFormsChild.map((item) => {
                              if (turnOn) {
                                   item.isRedirect = true;
                              } else {
                                   item.isRedirect = false;
                              }

                              return item;
                         });

                         formsEdit = formsEdit.map((item) => {
                              const existed = childs.find((child) => child.id === item.id);

                              if (existed) {
                                   item = { ...item, isRedirect: existed.isRedirect };
                              }

                              return item;
                         });

                         dispatch(
                              setSubscriberState({
                                   [type + form.id]: childs,
                              })
                         );
                    }

                    if (listFormsChildInventory) {
                         dispatch(
                              setSubscriberState({
                                   [form.id]: null,
                              })
                         );
                    }
               }

               if (turnOn) {
                    form.isRedirect = true;
               } else {
                    form.isRedirect = false;
               }

               return form;
          });

          formsEdit = formsEdit.map((item) => {
               const existed = parents.find((child) => child.id === item.id);

               if (existed) {
                    item = { ...item, isRedirect: existed.isRedirect };
               }

               return item;
          });
     }
     switch (type) {
          case 'ignored':
               dispatch(
                    setSubscriberState({
                         formIgnoreEdit: formsEdit,
                    })
               );
               break;
          case 'requiring':
               dispatch(
                    setSubscriberState({
                         formRequiringEdit: formsEdit,
                    })
               );
               break;
          // case 'inventory':
          //      dispatch(
          //           setSubscriberState({
          //                formInventoryEdit: formsEdit
          //           })
          //      );
          //      break;
          default:
               break;
     }
};

export const handleUpdateRevealPeople = (values, parentId, nextDispatch) => (dispatch, getState) => {
     const { data } = values;
     const formInventory = getState().subscriber.formInventory;
     const allFormsInventory = getState().subscriber.allFormsInventory;
     const formInventoryEdit = getState().subscriber.formInventoryEdit || [];
     const listFormChild = getState().subscriber[data.id] || [];
     const listFormChildParent = getState().subscriber[parentId] || [];

     if (nextDispatch.setLoading) {
          nextDispatch.setLoading(true);
     }

     if (data.hasChild) {
          listFormChild.map((item) => {
               if (item.inheritParent) {
                    item.revealPeople = data.revealPeople;
               }
               return item;
          });
     } else {
          if (parentId) {
               listFormChildParent.map((item) => {
                    if (item.id === data.id) {
                         item.revealPeople = data.revealPeople;
                    }
                    return item;
               });
          }
     }

     const newFormInventory = formInventory.map((item) => {
          if (item.id === data.id) {
               item.revealPeople = data.revealPeople;
          }
          return item;
     });

     const newAllFormInventory = allFormsInventory.map((item) => {
          if (item.id === data.id) {
               item.revealPeople = data.revealPeople;
          }
          return item;
     });

     callTokenApi(API_CLIENT_UPDATE_REVEAL_PEOPLE, 'PUT', values)
          .then((response) => {
               if (response.status === 200) {
                    dispatch(
                         setSubscriberState({
                              [data.id]: listFormChild,
                         })
                    );
                    dispatch(
                         setSubscriberState({
                              [parentId]: listFormChildParent,
                         })
                    );
                    dispatch(setFormInventory(newFormInventory));
                    dispatch(setAllFormsInventory(newAllFormInventory));

                    formInventoryEdit.forEach((form) => {
                         if (form.id === data.id || (form.inheritParent && form.parentId === data.id)) {
                              form.revealPeople = data.revealPeople;
                              dispatch(setFormInventoryEdit(form));
                         }
                    });
               } else {
                    toastError(response);
               }
          })
          .finally(() => {
               nextDispatch.setLoading(false);
          });
};

export const handleUpdateIsRedirectInventory = (values, parentId, nextDispatch) => (dispatch, getState) => {
     const { data } = values;
     const formInventory = getState().subscriber.formInventory;
     const allFormsInventory = getState().subscriber.allFormsInventory;
     const formInventoryEdit = getState().subscriber.formInventoryEdit || [];
     const listFormChild = getState().subscriber[data.id] || [];
     const listFormChildParent = getState().subscriber[parentId] || [];
     let newFormInventory = [];
     let newAllFormInventory = [];

     if (nextDispatch.setLoading) {
          nextDispatch.setLoading(true);
     }

     if (data.hasChild) {
          listFormChild.map((item) => {
               if (item.inheritParent) {
                    item.isRedirect = data.isRedirect;
               }
               return item;
          });
     } else {
          if (parentId) {
               listFormChildParent.map((item) => {
                    if (item.id === data.id) {
                         item.isRedirect = data.isRedirect;
                    }
                    return item;
               });
          }
     }

     newFormInventory = formInventory.map((item) => {
          if (item.id === data.id) {
               item.isRedirect = data.isRedirect;
          }
          return item;
     });

     newAllFormInventory = allFormsInventory.map((item) => {
          if (item.id === data.id) {
               item.isRedirect = data.isRedirect;
          }
          return item;
     });

     callTokenApi(API_CLIENT_UPDATE_IS_REDIRECT, 'PUT', values)
          .then((response) => {
               if (response.status === 200) {
                    dispatch(
                         setSubscriberState({
                              [data.id]: listFormChild,
                         })
                    );
                    dispatch(
                         setSubscriberState({
                              [parentId]: listFormChildParent,
                         })
                    );
                    dispatch(setFormInventory(newFormInventory));
                    dispatch(setAllFormsInventory(newAllFormInventory));

                    formInventoryEdit.forEach((form) => {
                         if (form.id === data.id || (form.inheritParent && form.parentId === data.id)) {
                              form.isRedirect = data.isRedirect;
                              dispatch(setFormInventoryEdit(form));
                         }
                    });
               } else {
                    toastError(response);
               }
          })
          .finally(() => {
               nextDispatch.setLoading(false);
          });
};

export const handleUpdateIsRedirectIgnored = (values, parentId, nextDispatch) => (dispatch, getState) => {
     const { data } = values;
     const listFormIgnored = getState().subscriber.formIgnored;
     const allFormsIgnored = getState().subscriber.allFormsIgnored;
     const formIgnoreEdit = getState().subscriber.formIgnoreEdit || [];
     const listChildIgnored = getState().subscriber[`ignored${data.id}`] || [];
     const listFormChildIgnoredParent = getState().subscriber[`ignored${parentId}`] || [];
     let newFormIgnored = [];
     let newAllFormIgnored = [];

     if (nextDispatch.setLoading) {
          nextDispatch.setLoading(true);
     }

     if (data.hasChild) {
          listChildIgnored.map((item) => {
               if (item.inheritParent) {
                    item.isRedirect = data.isRedirect;
               }
               return item;
          });
     } else {
          if (parentId) {
               listFormChildIgnoredParent.map((item) => {
                    if (item.id === data.id) {
                         item.isRedirect = data.isRedirect;
                    }
                    return item;
               });
          }
     }

     newFormIgnored = listFormIgnored.map((item) => {
          if (item.id === data.id) {
               item.isRedirect = data.isRedirect;
          }
          return item;
     });

     newAllFormIgnored = allFormsIgnored.map((item) => {
          if (item.id === data.id) {
               item.isRedirect = data.isRedirect;
          }
          return item;
     });

     callTokenApi(API_CLIENT_UPDATE_IS_REDIRECT, 'PUT', values)
          .then((response) => {
               if (response.status === 200) {
                    dispatch(
                         setSubscriberState({
                              [`ignored${data.id}`]: listChildIgnored,
                         })
                    );
                    dispatch(
                         setSubscriberState({
                              [`ignored${parentId}`]: listFormChildIgnoredParent,
                         })
                    );
                    dispatch(setFormIgnored(newFormIgnored));
                    dispatch(setAllFormsIgnore(newAllFormIgnored));

                    formIgnoreEdit.forEach((form) => {
                         if (form.id === data.id || (form.inheritParent && form.parentId === data.id)) {
                              form.isRedirect = data.isRedirect;
                              dispatch(setFormIgnoreEdit(form));
                         }
                    });
               } else {
                    toastError(response);
               }
          })
          .finally(() => {
               nextDispatch.setLoading(false);
          });
};

export const handleUpdateIsRedirectRequiring = (values, parentId, nextDispatch) => (dispatch, getState) => {
     const { data } = values;
     const formsRequiring = getState().subscriber.formsRequiring;
     const allFormsRequiring = getState().subscriber.allFormsRequiring;
     const formRequiringEdit = getState().subscriber.formRequiringEdit || [];
     const listChildRequiring = getState().subscriber[`requiring${data.id}`] || [];
     const listFormChildRequiringParent = getState().subscriber[`requiring${parentId}`] || [];
     let newFormRequiring = [];
     let newAllFormRequiring = [];

     if (nextDispatch.setLoading) {
          nextDispatch.setLoading(true);
     }

     if (data.hasChild) {
          listChildRequiring.map((item) => {
               if (item.inheritParent) {
                    item.isRedirect = data.isRedirect;
               }
               return item;
          });
     } else {
          if (parentId) {
               listFormChildRequiringParent.map((item) => {
                    if (item.id === data.id) {
                         item.isRedirect = data.isRedirect;
                    }
                    return item;
               });
          }
     }

     newFormRequiring = formsRequiring.map((item) => {
          if (item.id === data.id) {
               item.isRedirect = data.isRedirect;
          }
          return item;
     });

     newAllFormRequiring = allFormsRequiring.map((item) => {
          if (item.id === data.id) {
               item.isRedirect = data.isRedirect;
          }
          return item;
     });

     callTokenApi(API_CLIENT_UPDATE_IS_REDIRECT, 'PUT', values)
          .then((response) => {
               if (response.status === 200) {
                    dispatch(
                         setSubscriberState({
                              [`requiring${data.id}`]: listChildRequiring,
                         })
                    );
                    dispatch(
                         setSubscriberState({
                              [`requiring${parentId}`]: listFormChildRequiringParent,
                         })
                    );
                    dispatch(setFormRequiringAction(newFormRequiring));
                    dispatch(setAllFormsRequiring(newAllFormRequiring));

                    formRequiringEdit.forEach((form) => {
                         if (form.id === data.id && !form.inheritParent) {
                              form.isRedirect = data.isRedirect;
                              dispatch(setFormRequiringEdit(form));
                         }
                    });
               } else {
                    toastError(response);
               }
          })
          .finally(() => {
               nextDispatch.setLoading(false);
          });
};

export const updateFormsChildSaveDraft = (parent) => (dispatch, getState) => {
     if (parent.hasChild) {
          const childs = getState().subscriber[`requiring${parent.id}`];

          if (childs) {
               let newChilds = childs.filter((item) => item.inheritParent);

               newChilds = newChilds.map((child) => {
                    return (child.issue = 'isDrafted');
               });
               dispatch(
                    setSubscriberState({
                         [`requiring${parent.id}`]: newChilds,
                    })
               );
          }
     }
};

export const handleSubmitTicketCategories = (values, nextDispatch) => (dispatch, getState) => {
     if (nextDispatch.setIsLoading) {
          nextDispatch.setIsLoading(true);
     }

     const data = new FormData();

     if (values.file) {
          const file = values.file[0];
          data.append('file', file);
     }

     for (let key in values) {
          if (values[key] && key !== 'file') {
               if (key === 'description') {
                    data.append(key, `<p>${values[key]}</p>`);
               } else {
                    data.append(key, values[key]);
               }
          }
     }

     callTokenApi(`client/tickets`, 'POST', data)
          .then((response) => {
               if (response.status === 200) {
                    toast.success(response.data.message);
                    if (nextDispatch.setShowPopupTicket) {
                         nextDispatch.setShowPopupTicket(false);
                    }
                    if (nextDispatch.setShowPopupThanks) {
                         nextDispatch.setShowPopupThanks(true);
                    }
               } else {
                    toastError(response);
               }
          })
          .finally(() => {
               if (nextDispatch.setIsLoading) {
                    nextDispatch.setIsLoading(false);
               }
          });
};

export const handleSubmitTicketCustomForms = (values, nextDispatch) => (dispatch) => {
     if (nextDispatch.setIsLoading) {
          nextDispatch.setIsLoading(true);
     }

     const data = new FormData();

     for (let key in values) {
          if (values[key] && key !== 'file') {
               if (key === 'description') {
                    data.append(key, `<p>${values[key]}</p>`);
               } else {
                    data.append(key, values[key]);
               }
          }
     }

     callTokenApi(`client/tickets`, 'POST', data)
          .then((response) => {
               if (response.status === 200) {
                    toast.success(response.data.message);
                    const data = {
                         currentStepCustomForms: STEPS_CUSTOM_FORM.congratulations,
                         accountId: values.accountId,
                    };

                    const nextDispatch = {
                         nextStep: STEPS_CUSTOM_FORM.congratulations,
                    };

                    document.querySelector('html').setAttribute('style', 'overflow:initial');
                    dispatch(handleSetCurrentStepCustomForms(data, nextDispatch));
               } else {
                    toastError(response);
               }
          })
          .finally(() => {
               if (nextDispatch.setIsLoading) {
                    nextDispatch.setIsLoading(false);
               }
          });
};

export const handleResetAllFormCategories = () => (dispatch) => {
     // reset form requiring
     dispatch(setFormRequiringAction(null));
     dispatch(setAllFormsRequiring(null));
     dispatch(setFormRequiringEdit(null));
     // reset form inventory
     dispatch(setFormInventory(null));
     dispatch(setAllFormsInventory(null));
     dispatch(setFormInventoryEdit(null));
     // reset form ignored
     dispatch(setFormIgnored(null));
     dispatch(setAllFormsIgnore(null));
     dispatch(setFormIgnoreEdit(null));
};

export const handleSetFormCategoriesAll = () => (dispatch, getState) => {
     dispatch(setFormCategory(null));
     dispatch(setFormRequiringAction(null));
     dispatch(setCustomCategories(null));

     dispatch(setCountFormRequiring());
     dispatch(setCountFormIgnored());
     dispatch(setCountFormInventory());

     dispatch(setFormRequiringAction());
     dispatch(setFormIgnored());
     dispatch(setFormInventory());
};

export const handleCrawlDomain = ({ domain, accountId, account, setAccount }) => (dispatch, getState) => {
     const activeAccount = getState().subscriber.activeAccount;
     const newAccount = account ? account : activeAccount;
     const isRunningV2 = newAccount && (newAccount.name.includes(CRAWLER_PREFIX) ? false : newAccount.isRunningV2);
     const domains = newAccount && typeof newAccount.domains === 'string' ? JSON.parse(newAccount.domains) : newAccount.domains;
     const domainData = Array.isArray(domains) && domains.find((item) => item.name === domain);
     const isInvalidDomain = domainData ? domainData.isInvalid || domainData.status === 400 : false;

     if (!isInvalidDomain) {
          const data = {
               domain: 'https://' + domain + '/',
               accountId: accountId || activeAccount.id,
          };

          callApiCrawlerStart(data, isRunningV2);
          if (setAccount) {
               setAccount({
                    ...account,
                    lastDateCrawl: new Date(),
               });
          }
     }
};

export const fetchAccountDestinationRequest = (accountDestinationId, nextDispatch) => (dispatch) => {
     const { setFetchLoading, setGTMTagInLL, setTagPurchaseDefault } = nextDispatch;

     if (typeof setFetchLoading === 'function') {
          setFetchLoading(true);
     }
     callTokenApi(`${API_CLIENT_ACCOUNT_DESTINATION}${accountDestinationId}`, 'GET')
          .then((response) => {
               if (response.status === 200) {
                    const { accountDestination, gtmTagInLL, allGtmTagInLL, tagPurchaseDefault } = response.data;

                    dispatch(setAccountDestination(accountDestination));
                    dispatch(setSwitchTypeWizard({ usingType: accountDestination.usingType, gtmTagLL: allGtmTagInLL }));

                    if (typeof setGTMTagInLL === 'function' && gtmTagInLL) {
                         setGTMTagInLL(gtmTagInLL);
                    }
                    if (typeof setTagPurchaseDefault === 'function' && tagPurchaseDefault) {
                         setTagPurchaseDefault(tagPurchaseDefault);
                    }
               } else {
                    toastError(response);
               }
          })
          .finally(() => {
               if (typeof setFetchLoading === 'function') {
                    setFetchLoading(false);
               }
          });
};

export const handleCrawlDomains = (data) => (dispatch, getState) => {
     const domains = getState().subscriber.domains;
     data.domains = domains;
     dispatch(crawlFormsAction(data));
};

export const checkRelatedBeforeDisableEvent =
     ({ accountId, type, listenerId, featureCode, containerId, successNext, setLoadingSwitch, setStatusSwitching, index }) =>
          () => {
               setLoadingSwitch && setLoadingSwitch({ index, loading: true });
               setStatusSwitching && setStatusSwitching(true);
               callTokenApi(API_CLIENT_CHECK_RELATED_BEFORE_DISABLE_EVENT, 'POST', { accountId, type, listenerId, containerId, featureCode })
                    .then((response) => {
                         if (response.status === 200) {
                              successNext(response.data.message);
                         }
                    })
                    .finally(() => {
                         setLoadingSwitch && setLoadingSwitch({ index, loading: false });
                         setStatusSwitching && setStatusSwitching(false);
                    });
          };

export const handleGetAccordion =
     ({ pathName }) =>
          (dispatch, getState) => {
               const newPathName = pathName.replace(/\/+$/, '');
               const accountId = getState().subscriber.activeAccount.id;
               const localAccordionShow = tryParseJSON(localStorage.getItem(LOCAL_ACCORDION_SHOW));
               const accountAccordions = localAccordionShow[accountId];

               if (accountAccordions) {
                    const accordionShow = accountAccordions.find((item) => item.path === newPathName);

                    if (accordionShow) {
                         dispatch(setAccordion(accordionShow.accordion));
                    } else {
                         dispatch(setAccordion(''));
                    }
               }
          };

export const handleSetAccordion =
     ({ accordion, pathName }) =>
          (dispatch, getState) => {
               if (!pathName) return;

               const newPathName = pathName.replace(/\/+$/, '');
               const accountId = getState().subscriber.activeAccount.id;
               const localAccordionShow = tryParseJSON(localStorage.getItem(LOCAL_ACCORDION_SHOW));
               const accordionData = {
                    path: newPathName,
                    accordion,
               };

               dispatch(setAccordion(accordion));

               if (localAccordionShow) {
                    const accountAccordions = localAccordionShow[accountId];

                    if (accountAccordions) {
                         let newAccountAccordions = [...accountAccordions];

                         if (newAccountAccordions.some((item) => item.path === newPathName)) {
                              // if existed in local storage
                              if (accordion) {
                                   newAccountAccordions = newAccountAccordions.map((item) => {
                                        if (item.path === newPathName) {
                                             return {
                                                  path: newPathName,
                                                  accordion,
                                             };
                                        }
                                        return item;
                                   });
                              } else {
                                   newAccountAccordions = newAccountAccordions.filter((item) => item.path !== newPathName);
                              }
                         } else {
                              newAccountAccordions.push(accordionData);
                         }

                         localAccordionShow[accountId] = newAccountAccordions;
                    } else {
                         localAccordionShow[accountId] = [{ ...accordionData }];
                    }

                    if (localAccordionShow[accountId].length === 0) {
                         delete localAccordionShow[accountId];
                    }

                    localStorage.setItem(LOCAL_ACCORDION_SHOW, JSON.stringify(localAccordionShow));
               } else {
                    const newLocalAccordionShow = {
                         [accountId]: [{ ...accordionData }],
                    };
                    localStorage.setItem(LOCAL_ACCORDION_SHOW, JSON.stringify(newLocalAccordionShow));
               }
          };

export const fetchCustomReportsRequest = (accountId) => (dispatch) => {
     callTokenApi(API_CLIENT_CUSTOM_REPORTS.replace(':accountId', accountId), 'GET', null).then((response) => {
          if (response.status === 200) {
               const { customReports } = response.data;

               if (customReports && customReports.length > 0) {
                    dispatch(setCustomReport({ reports: customReports }));
               }
          } else {
               toastError(response);
          }
     });
};

export const fetchSavedReportsRequest = (accountId, userId) => (dispatch) => {
     const query = `?accountId=${accountId}&userId=${userId}`;

     callTokenApi(`${CLIENT_GET_SAVED_REPORTS}/${query}`, 'GET').then((response) => {
          if (response.status === 200) {
               const { reportViews } = response.data;

               if (reportViews && reportViews.length > 0) {
                    dispatch(setSavedReport(reportViews));
               }
          } else {
               toastError(response);
          }
     });
};

export const fetchEventNativeListener = (accountId) => (dispatch) => {
     const nativeListener = process.env.REACT_APP_NATIVE_EVENT_LISTENER_ID;
     callTokenApi(`${API_CLIENT_EVENT_ACCOUNT}${accountId}/${nativeListener}`, 'GET').then((response) => {
          if (response.status === 200) {
               let data = response.data.eventAccounts || [];
               let newFeatureSupports = [];
               data.forEach((item) => {
                    if (typeof item.featureSupport !== 'undefined') {
                         newFeatureSupports.push({
                              featureId: item.featureId,
                              featureName: item.friendlyName || item.code,
                              featureCode: item.code,
                              features: JSON.parse(item.featureSupport),
                              config: item.config,
                         });
                    } else {
                         newFeatureSupports.push({
                              featureId: item.featureId,
                              featureName: item.friendlyName || item.code,
                              featureCode: item.code,
                              features: [],
                         });
                    }
               });

               const nativeEvent = newFeatureSupports && newFeatureSupports[0] ? newFeatureSupports[0].features : [];

               dispatch(
                    setListenerEventsNative({
                         listenerId: nativeListener,
                         initialEventsNative: nativeEvent.map((_feature, index) => {
                              return {
                                   id: `addedEvent${index + 1}`,
                                   value: _feature.event,
                                   collectPerson: _feature.eventsCollectPerson ? true : false,
                                   customObject: _feature.customObject ? true : false,
                                   customObjectValue: _feature.customObject || '',
                                   eCommerceData: _feature.eCommerceData ? true : false,
                                   eCommerceDataValue: _feature.eCommerceData || '',
                              };
                         }),
                         listenerEventsNativeChange: nativeEvent.map((_feature, index) => {
                              return {
                                   id: `addedEvent${index + 1}`,
                                   value: _feature.event,
                                   collectPerson: _feature.eventsCollectPerson ? true : false,
                                   customObject: _feature.customObject ? true : false,
                                   customObjectValue: _feature.customObject || '',
                                   eCommerceData: _feature.eCommerceData ? true : false,
                                   eCommerceDataValue: _feature.eCommerceData || '',
                              };
                         }),
                         addedEvents: [],
                         editedEvents: [],
                         eventsStatus: nativeEvent.map((_) => true),
                    })
               );

               dispatch(setNativeECommerce(nativeEvent.some((item) => item.eCommerceData)));
          }
     });
};

export const fetchGoals = (accountId, secondId) => (dispatch, getState) => {
     const routes = getState().subscriber.routes;
     callTokenApi(`${API_CLIENT_GOALS}/${accountId}`, 'GET').then((response) => {
          if (response.status !== 200) return;
          dispatch(setSubscriberState({ accountGoals: response.data.data }));
          const goalRoutes = getAccountGoalsRoute(response.data.data, secondId);
          dispatch(setRoutes([...routes, ...goalRoutes]));
     });
}

export const fetchScopesRequest = (accountId, setIsLoading) => (dispatch) => {
     if (typeof setIsLoading === 'function') {
          setIsLoading(true);
     }
     callTokenApi(API_CLIENT_SCOPES.replace(':accountId', accountId), 'GET')
          .then((response) => {
               if (response.status === 200) {
                    const { scopes } = response.data;

                    if (scopes && scopes.length > 0) {
                         dispatch(setCustomReport({ scopes }));
                    }
               } else {
                    toastError(response);
               }
          })
          .finally(() => {
               if (typeof setIsLoading === 'function') {
                    setIsLoading(false);
               }
          });
};

export const fetchDimensionsRequest = (accountId, setIsLoading) => (dispatch) => {
     if (typeof setIsLoading === 'function') {
          setIsLoading(true);
     }
     callTokenApi(API_CLIENT_DIMENSIONS.replace(':accountId', accountId), 'GET', null)
          .then((response) => {
               if (response.status === 200) {
                    const { dimensions } = response.data;

                    if (dimensions && dimensions.length > 0) {
                         dispatch(setCustomReport({ dimensions }));
                    }
               } else {
                    toastError(response);
               }
          })
          .finally(() => {
               if (typeof setIsLoading === 'function') {
                    setIsLoading(false);
               }
          });
};

export const fetchOptionCheckListRequest = (accountId, setIsLoading, dateRanges) => (dispatch) => {
     if (typeof setIsLoading === 'function') {
          setIsLoading(true);
     }
     const data = {
          reportName: REPORT_NAMES.COMPANY_DETAILS,
          accountId: accountId,
          dateRanges,
     };

     callTokenApi(API_CLIENT_REPORT_FILTER_CHECKLIST, 'POST', data)
          .then((response) => {
               if (response.status === 200) {
                    const { checklistData } = response.data;

                    if (checklistData) {
                         dispatch(setCustomReport({ checkList: checklistData }));
                    }
               } else {
                    toastError(response);
               }
          })
          .finally(() => {
               if (typeof setIsLoading === 'function') {
                    setIsLoading(false);
               }
          });
};

export const fetchMetricsRequest = (accountId, setIsLoading) => (dispatch) => {
     if (typeof setIsLoading === 'function') {
          setIsLoading(true);
     }
     callTokenApi(API_CLIENT_METRICS.replace(':accountId', accountId), 'GET', null)
          .then((response) => {
               if (response.status === 200) {
                    const { metrics } = response.data;

                    if (metrics && metrics.length > 0) {
                         dispatch(setCustomReport({ metrics }));
                    }
               } else {
                    toastError(response);
               }
          })
          .finally(() => {
               if (typeof setIsLoading === 'function') {
                    setIsLoading(false);
               }
          });
};

export const fetchChannelsRequest = (accountId, setIsLoading) => (dispatch) => {
     if (typeof setIsLoading === 'function') {
          setIsLoading(true);
     }
     callTokenApi(API_CLIENT_CHANNELS.replace(':accountId', accountId), 'GET')
          .then((response) => {
               if (response.status === 200) {
                    const { data } = response.data;

                    if (data && data.length > 0) {
                         dispatch(setListChannels(data));
                    }
               } else {
                    toastError(response);
               }
          })
          .finally(() => {
               if (typeof setIsLoading === 'function') {
                    setIsLoading(false);
               }
          });
};

export const fetchCustomUserSources = (accountId, setIsLoading) => (dispatch) => {
     if (typeof setIsLoading === 'function') {
          setIsLoading(true);
     }
     callTokenApi(API_CLIENT_USER_SOURCES.replace(':accountId', accountId), 'GET')
          .then((response) => {
               if (response.status === 200) {
                    const { userSources } = response.data;
                    const listCustomUserSources = userSources && userSources.filter((source) => source.isCustom);

                    dispatch(setCustomUserSources(listCustomUserSources));
                    dispatch(setListUserSources(userSources));
               } else {
                    toastError(response);
               }
          })
          .finally(() => {
               if (typeof setIsLoading === 'function') {
                    setIsLoading(false);
               }
          });
};

export const handleFetchCrawlerStatus =
     ({ accountId, setJobs }) =>
          () => {
               callTokenApi(API_CLIENT_ACCOUNT_CRAWLER_STATUS.replace(':accountId', accountId), 'GET').then((response) => {
                    if (response.status === 200) {
                         if (typeof setJobs === 'function') {
                              setJobs(response.data.jobs);
                         }
                    } else {
                         toastError(response);
                    }
               });
          };

export const fetchSalesforceRulesRequest =
     (accountId, paging, activePage = 1, itemsPerPage = 10) =>
          (dispatch) => {
               let loading;

               if (paging) {
                    loading = { salesforceRulePagingLoading: true };
               } else {
                    loading = { salesforceRuleLoading: true };
               }
               dispatch(setSubscriberState(loading));

               const data = {
                    accountId: accountId,
                    offset: activePage > 1 ? (activePage - 1) * itemsPerPage : 0,
                    limit: itemsPerPage,
               };

               const request = `${API_SALESFORCE_RULE_LIST}?accountId=${accountId}&offset=${data.offset}&limit=${data.limit}`;

               callSalesforceApi(request, 'GET')
                    .then((response) => {
                         if (response && response.status === 200) {
                              const { data, total } = response.data;

                              dispatch(setSalesforceRules(data || []));
                              dispatch(setSalesforceRulesPaging({ salesforceRuleTotal: total || 0 }));
                         } else {
                              toast.error('Get data failed!');
                         }
                    })
                    .finally(() => {
                         if (paging) {
                              loading = { salesforceRulePagingLoading: false };
                         } else {
                              loading = { salesforceRuleLoading: false };
                              dispatch(setSalesforceRulesPaging({ salesforceRuleActivePage: 1 }));
                         }

                         dispatch(setSubscriberState(loading));
                    });
          };

export const setListSegment = (segment) => {
     return {
          type: SET_LIST_SEGMENT,
          payload: segment,
     };
};

export const fetchSegmentRequest = (accountId, setIsLoading) => (dispatch) => {
     if (typeof setIsLoading === 'function') {
          setIsLoading(true);
     }
     callTokenApi(API_CLIENT_GET_LIST_SEGMENT.replace(':accountId', accountId), 'GET', null)
          .then((response) => {
               if (response.status === 200) {
                    const { segments } = response.data;

                    if (segments && segments.length > 0) {
                         dispatch(setListSegment(segments));
                    }
               } else {
                    toastError(response);
               }
          })
          .finally(() => {
               if (typeof setIsLoading === 'function') {
                    setIsLoading(false);
               }
          });
};

export const setListSegmentReport = (segmentReport) => {
     return {
          type: SET_LIST_SEGMENT_REPORT,
          payload: segmentReport,
     };
};

export const setListSegmentApplyReload = (payload) => {
     return {
          type: SET_LIST_SEGMENT_APPLY_RELOAD,
          payload,
     };
};

export const setListSegmentReload = (payload) => {
     return {
          type: SET_LIST_SEGMENT_RELOAD,
          payload,
     };
};

export const setBlockTriggerRules = (payload) => {
     return {
          type: SET_BLOCK_TRIGGER_RULES,
          payload,
     };
};

export const processStatusWizard = (payload) => (dispatch) => {
     const {
          accoundId,
          steps,
          data
     } = payload;
     const newData = {
          accountId: accoundId,
          step: steps,
          data: data
      };
      callTokenApi(API_CLIENT_ACCOUNT_COMPLETED_CHILD_SETUP_GUIDE, 'PUT', newData)
      .then(response => {
          if (response.status === 200) {
              dispatch(setChildSetupGuideDataCompleted({
                  key: steps,
                  data: data
              }));
          } else {
              toastError(response);
          }
      })
}

export const setListSeverSideTrigger = (severSideTrigger) => {
     return {
          type: SET_LIST_SEVER_SIDE_TRIGGER,
          payload: severSideTrigger,
     };
};

export const fetchSeverSideTriggerRequest = (accountId, setIsLoading) => (dispatch) => {
     if (typeof setIsLoading === 'function') {
          setIsLoading(true);
     }
     callTokenApi(API_CLIENT_GET_LIST_SEVER_SIDE_TRIGGER.replace(':accountId', accountId), 'GET', null)
          .then((response) => {
               if (response.status === 200) {
                    const { triggers } = response.data;

                    if (triggers && triggers.length > 0) {
                         const updatedTriggers = triggers.map(trigger => ({
                              ...trigger,
                              group: "sever-side-trigger"
                         }));

                         dispatch(setListSeverSideTrigger(updatedTriggers));
                    }
               } else {
                    toastError(response);
               }
          })
          .finally(() => {
               if (typeof setIsLoading === 'function') {
                    setIsLoading(false);
               }
          });
};

export const setListSalesConversion = (SalesConversion) => {
     return {
          type: SET_LIST_SALES_CONVERSION,
          payload: SalesConversion,
     };
};

export const fetchSalesConversionRequest = (accountId, setIsLoading) => (dispatch) => {
     if (typeof setIsLoading === 'function') {
          setIsLoading(true);
     }
     callTokenApi(API_CLIENT_GET_LIST_SALES_CONVERSION.replace(':accountId', accountId), 'GET', null)
          .then((response) => {
               if (response.status === 200) {
                    const { conversions } = response.data;
                    if (conversions && conversions.length > 0) {
                         dispatch(setListSalesConversion(conversions));
                    }
               } else {
                    toastError(response);
               }
          })
          .finally(() => {
               if (typeof setIsLoading === 'function') {
                    setIsLoading(false);
               }
          });
};
export const setListSegmentDB = (payload) => {
     return {
          type: SET_LIST_SEGMENT_DB,
          payload,
     }
}

export const setDataDashboard = (payload) => {
     return {
          type: SET_DATA_DASHBOARD,
          payload,
     }
}

export const setDBSegmentSelected = (payload) => {
     return {
          type: SET_DB_SEGMENT_SELECTED,
          payload,
     }
}

export const setListTableTempQueryData = (payload) => {
     return {
          type: SET_LIST_TABLE_TEMP_QUERY_DATA,
          payload,
     }
}

export const setPusherListTableTempQueryData = (payload) => {
     return {
          type: PUSHER_SET_LIST_TABLE_TEMP_QUERY_DATA,
          payload,
     }
}
