import React, { useEffect } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { setSubscriberState } from '../../../actions/subscriber.js';
import { getListenerColorIcon } from '../../../helpers/cms/subscriber.js';
import { useLocation } from 'react-router-dom';
import { RULE_GROUP, VIEW_ALL } from '../../../constants/index.js';
import CLabel from '../../migration/CLabel.js';

const formatOptionLabel = ({ iconSrc, label }) => (
	<div className='option-row'>
		{iconSrc && <img src={iconSrc} alt={label} className='icon' />}
		<div>{label}</div>
	</div>
);


const SelectListener = ({ action = '', label = '', isDisabled = false, ruleType = '', listenerType = '',menuIsOpen, setMenuIsOpen }) => {
     const dispatch = useDispatch();
     const search = useLocation().search;
     const browserRules = useSelector((state) => state.subscriber.browserRules);
     const monitoringRules = useSelector((state) => state.subscriber.monitoringRules);
     const populateRules = useSelector((state) => state.subscriber.populateRules) || [];
     const selectedListener = useSelector((state) => state.subscriber.selectedListener);
     const accountListeners = useSelector((state) => state.subscriber.accountListeners);
     const customVariableRules = useSelector((state) => state.subscriber.customVariableRules);

     const accordionParams = new URLSearchParams(search).get('accordion');
     const listActiveListeners = (accountListeners && accountListeners.filter((listener) => listener.configStatus && (!listenerType || (listenerType && listener.type === listenerType))).sort((a, b) => a.name.localeCompare(b.name))) || [];
     const viewAllOption = { value: VIEW_ALL, label: 'View All' };
     const activeListenerOptions = [viewAllOption];
     const newActiveListenerOptions = [viewAllOption];

     listActiveListeners.forEach((listener) => {
          if (listener.configStatus) {
               const iconSrc = getListenerColorIcon(listener);
               let newAllRules = [];

               switch (ruleType) {
                    case RULE_GROUP.DATA_LAYER:
                         newAllRules = customVariableRules ? [...customVariableRules] : [];
                         break;
                    case RULE_GROUP.BROWSER_STORAGE:
                         newAllRules = browserRules ? [...browserRules] : [];
                         break;
                    case RULE_GROUP.MONITORING:
                         newAllRules = monitoringRules ? [...monitoringRules] : [];
                         break;
                    case RULE_GROUP.POPULATE:
                         newAllRules = [...populateRules];
                         break;
                    default:
                         break;
               } 

               const listenerRules = newAllRules && newAllRules.filter((item) => item.listenerId === listener.id);
               const totalRules = listenerRules && action !== 'create' ? ` (${listenerRules.length})` : '';
               if (listener.name !== 'Page Engagement') {
                    activeListenerOptions.push({ value: listener.id, label: listener.name + totalRules, iconSrc });
                    newActiveListenerOptions.push({ value: listener.id, label: listener.name, iconSrc });
               }
          }
     });

     const selectedValue = newActiveListenerOptions.find((option) => option.value === selectedListener);

     const fetchSelectListener = () => {
          if (!selectedListener) {
               dispatch(setSubscriberState({ selectedListener: VIEW_ALL }));
          }
     };
     useEffect(fetchSelectListener, [selectedListener]); // eslint-disable-line react-hooks/exhaustive-deps

     const handleRefreshSelectedListener = () => {
          if (!accordionParams) {
               dispatch(setSubscriberState({ selectedListener: null }));
          }
     };
     useEffect(handleRefreshSelectedListener, []); // eslint-disable-line react-hooks/exhaustive-deps

     const handleSetActiveListener = (value) => {
          dispatch(setSubscriberState({ selectedListener: value }));

          const newSelectedListenerRule = value === VIEW_ALL ? null : value;
          dispatch(setSubscriberState({ selectedListenerRule: newSelectedListenerRule }));
     };

     return (
          <div className={`select-listener-wrapper ${menuIsOpen ? 'menu-open' : ''}`}>
               {label && <CLabel>{label}</CLabel>}
               <Select classNamePrefix='react-select'
                    placeholder={action === 'create' ? 'Select a Listener to create its rules' : 'Select a Listener to view its rules'}
                    options={activeListenerOptions}
                    formatOptionLabel={formatOptionLabel}
                    value={selectedValue}
                    onChange={(option) => handleSetActiveListener(option.value)}
                    className='select-listener'
                    isDisabled={isDisabled}
                    menuIsOpen={menuIsOpen}
                    onMenuOpen={() => setMenuIsOpen && setMenuIsOpen(true)}
                    onMenuClose={() => setMenuIsOpen && setMenuIsOpen(false)}
               />
          </div>
     );
};

export default SelectListener;
