import React, { useState } from "react";
import { CButton, CModal, CModalBody, CModalFooter } from "@coreui/react";

const Step1 = React.lazy(() => import("./Step1"));
const SimpleRuleStep2 = React.lazy(() => import("./simple/CreateStep2"));
const LookupRuleStep2 = React.lazy(() => import("./lookup/CreateStep2"));
const SimpleRuleStep3 = React.lazy(() => import("./simple/CreateStep3"));
const LookupRuleStep3 = React.lazy(() => import("./lookup/CreateStep3"));
const SimpleRuleStep4 = React.lazy(() => import("./simple/CreateStep4"));
const LookupRuleStep4 = React.lazy(() => import("./lookup/CreateStep4"));

//sever side triggers
const StepTrigger = React.lazy(() => import("./StepTrigger"));
const SeverSideStep2 = React.lazy(() => import("./sever-side-trigger/CreateStep2"));
const SeverSideStep3 = React.lazy(() => import("./sever-side-trigger/CreateStep3"));
const PreviewResults = React.lazy(() => import("./sever-side-trigger/PreviewResults"));

const CreateNewSimpleRule = () => {
     const [stepsData, setStepsData] = useState({
          name: "",
          description: "",
          conditions: [
               {
                    conjunction: "",
                    g0: {
                         type: "Variable",
                         key: "",
                         operator: "",
                         value: "",
                         conjunction: "",
                    },
               },
          ],
          consent: [
               {
                    conjunction: "",
                    g0: {
                         key: "",
                         value: "",
                         conjunction: "",
                    },
               },
          ],
          data: { consentBlock: false },
          customVariables: [],
          group: "triggers",
     });

     return (
          <>
               <SimpleRuleStep2 stepsData={stepsData} setStepsData={setStepsData} />
               <SimpleRuleStep3 stepsData={stepsData} setStepsData={setStepsData} />
               <SimpleRuleStep4 stepsData={stepsData} setStepsData={setStepsData} />
          </>
     );
};

const CreateNewLookupRule = () => {
     const [stepsData, setStepsData] = useState({
          name: "",
          description: "",
          conditions: [],
          customVariables: [[{ id: "" }]],
          consent: [
               {
                    conjunction: "",
                    g0: {
                         key: "",
                         value: "",
                         conjunction: "",
                    },
               },
          ],
          data: {
               triggers: [{ id: "", name: "" }],
               consentBlock: false,
          },
          group: "triggers",
     });

     return (
          <>
               <LookupRuleStep2 stepsData={stepsData} setStepsData={setStepsData} />
               <LookupRuleStep3 stepsData={stepsData} setStepsData={setStepsData} />
               <LookupRuleStep4 stepsData={stepsData} setStepsData={setStepsData} />
          </>
     );
};

const CreateNewSeverSideTrigger = () => {
     const [stepsData, setStepsData] = useState({
          name: "",
          description: "",
          targetObject: null,
          triggerType: null,
          triggerCondition: {
               relatedObject: null,
               forTheFirstRelationship: false,
               property: null,
               slug: null,
               fromRule: null,
               fromCondition: null,
               fromValue: null,
               toRule: null,
               toCondition: null,
               toValue: null,
               fromTime: null,
               toTime: null,
               connectionId:[]
          },
          filter: [],
          group: "sever-side-trigger"
     });

     const [records, setRecords] = useState([]);
     const [hasPreviewObject, setHasPreviewObject] = useState(false);

     return (
          <>
               <SeverSideStep2 stepsData={stepsData} setStepsData={setStepsData} />
               <SeverSideStep3
                    stepsData={stepsData}
                    setStepsData={setStepsData}
                    setRecords={setRecords}
                    setHasPreviewObject={setHasPreviewObject}
               />
               <PreviewResults
                    stepsData={stepsData}
                    setStepsData={setStepsData}
                    records={records}
                    setRecords={setRecords}
                    hasPreviewObject={hasPreviewObject}
                    setHasPreviewObject={setHasPreviewObject}
               />
          </>
     );
};

export const RuleContext = React.createContext({});

const CreateNewRule = () => {
     const [modal, setModal] = useState(false);
     const [ruleType, setRuleType] = useState("");
     const [activeStep, setActiveStep] = useState(1);
     const [manageType, setManageType] = useState("");

     return (
          <>
               {
                    <div className='cvr-create-new custom-rule'>
                         <h1>Create {ruleType === "lookup" ? "New Lookup Rule" : "a Trigger Rule"}</h1>
                         <p className='description'>Would you like to base your trigger on client-side website activity, or server side data?</p>

                         <StepTrigger
                              activeStep={activeStep}
                              setActiveStep={setActiveStep}
                              manageType={manageType}
                              setManageType={setManageType}
                              actionType='create'
                         />

                         {/* layout web trigger */}
                         {manageType === "web-trigger" && (
                              <Step1
                                   activeStep={activeStep}
                                   setActiveStep={setActiveStep}
                                   ruleType={ruleType}
                                   setRuleType={setRuleType}
                                   actionType='create'
                              />
                         )}

                         {ruleType && manageType === "web-trigger" && (
                              <RuleContext.Provider value={{ activeStep, setActiveStep }}>
                                   {ruleType === "simple" ? <CreateNewSimpleRule /> : <CreateNewLookupRule />}
                              </RuleContext.Provider>
                         )}
                         {/* end layout web triger */}

                         {/* layout sever side trigger */}
                         {manageType === "sever-side-trigger" && (
                              <RuleContext.Provider value={{ activeStep, setActiveStep }}>
                                   <CreateNewSeverSideTrigger />
                              </RuleContext.Provider>
                         )}

                         <CModal show={modal} centered={true} onClose={() => setModal(false)}>
                              <CModalBody className='text-center'>
                                   <h3 className='mb-4'>Warning! This Will Change Your Rule</h3>
                                   <p>
                                        You are about to change your rule type. Doing this will reset and remove all settings inside this rule other
                                        than the name and description.
                                   </p>
                                   <p>Are you sure you want to do this? You cannot undo this once you publish it!</p>
                              </CModalBody>
                              <CModalFooter className='text-center justify-content-center'>
                                   <CButton color='primary' className='mr-3 text-uppercase' onClick={() => setModal(false)}>
                                        Cancel
                                   </CButton>
                                   <CButton color='secondary' onClick={() => { }}>
                                        Save Change and Reset Rule
                                   </CButton>
                              </CModalFooter>
                         </CModal>
                    </div>
               }
          </>
     );
};

export default CreateNewRule;
