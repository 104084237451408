import { CButton, CCard, CCardBody, CCardHeader, CCol, CForm, CRow } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import Select from 'react-select';
import { SalesforceDetailDetailContext } from '../Popup/SalesforceConnectAccount';
import { setShowHistoricalPopup, setDataHistoricalSalesforce, fetchSalesforceRulesRequest } from '../../../../../../../../actions/subscriber';
import { callSalesforceApi } from '../../../../../../../../apiCaller';
import {
     API_SALESFORCE_CONNECTION,
     API_SALESFORCE_GET_CUSTOM_OBJECT,
     COMPONENT_NAME,
     SF_CONNECTION_STATUS,
     SF_JOB_STATUS,
} from '../../../../../../../../constants';
import { toast } from 'react-toastify';
import { setFlexibleModal, setRuleHaveEditting } from '../../../../../../../../actions/common';
import CFormGroup from '../../../../../../../migration/CFormGroup';
import { CInputCheckbox } from '../../../../../../../migration/CInput';
import CLabel from '../../../../../../../migration/CLabel';

const ObjectsToImportSalesforce = ({ idEdit }) => {
     const dispatch = useDispatch();
     const { accountId } = useSelector((state) => state.subscriber.activeAccount);
     const salesForeRulesPaging = useSelector((state) => state.subscriber.accountExternalData.salesForeRulesPaging);
     const { activeStep, setActiveStep, stepsData, setStepsData, initStepsData } = useContext(SalesforceDetailDetailContext);
     const SFjobs = useSelector((state) => state.subscriber.jobs);
     const [isLoadingWidthOut, setIsLoadingWithOut] = useState(false);
     const [isLoadingGetNew, setIsLoadingGetNew] = useState(false);
     const customObjectSynced = stepsData.customObjectSynced || [];
     const defaultObjects = ['Lead', 'Contact', 'Account', 'Opportunity', 'Quote', 'Order', 'Contract', 'Campaign', 'Event'];
     const listObjectAutoImport = ['AccountContactRelation', 'OpportunityContactRole', 'CampaignMember', 'ContractContactRole', 'EventRelation'];
     const objects = typeof stepsData.objects === 'string' ? JSON.parse(stepsData.objects) : stepsData.objects;

     const customObjects = stepsData.objects ? objects.filter((item) => !defaultObjects.includes(item) && !listObjectAutoImport.includes(item)) : [];
     const initialValues =
          objects && objects.length > 0 && idEdit && stepsData.status !== SF_CONNECTION_STATUS.DRAFT
               ? [
                      ...defaultObjects.map((item) => ({
                           name: item,
                           checked: objects.includes(item),
                           type: 'default',
                      })),
                      ...customObjectSynced.map((item) => ({
                           name: item.name,
                           checked: item.status,
                           type: 'custom',
                      })),
                 ]
               : [
                      { name: 'Lead', checked: true, type: 'default' },
                      { name: 'Contact', checked: true, type: 'default' },
                      { name: 'Account', checked: true, type: 'default' },
                      { name: 'Opportunity', checked: true, type: 'default' },
                      { name: 'Quote', checked: true, type: 'default' },
                      { name: 'Order', checked: false, type: 'default' },
                      { name: 'Contract', checked: false, type: 'default' },
                      { name: 'Campaign', checked: true, type: 'default' },
                      { name: 'Event', checked: true, type: 'default' },
                 ];

     useEffect(() => {
          const customObjectSync = SFjobs.filter((obj) => customObjects.includes(obj.object));
          const newCustomObjectSynced = customObjectSync.filter(
               (item1) => !customObjectSynced.some((item2) => item1.object === item2.object && item2.lastSyncDataAt)
          );

          if (newCustomObjectSynced.length > 0) {
               setStepsData((prev) => ({ ...prev, customObjectSynced: [...prev.customObjectSynced, ...newCustomObjectSynced] }));
          }
     }, [SFjobs]); //eslint-disable-line

     const [optionsCustomObject, setOptionsCustomObject] = useState([]);
     useEffect(() => {
          if (activeStep === 4 && optionsCustomObject.length <= 0 && stepsData.id) {
               callSalesforceApi(`${API_SALESFORCE_GET_CUSTOM_OBJECT}?connectionId=${stepsData.id}`, 'GET').then((response) => {
                    if (response && response.status === 200) {
                         const customObjectSyncedArray = customObjectSynced.map((_custom) => _custom.name);

                         const listCustomObject = response.data.data
                              .filter((i) => !customObjectSyncedArray.includes(i.name))
                              .map((item) => ({ value: item.name, label: item.name }));

                         setOptionsCustomObject((prev) => [...prev, ...listCustomObject]);
                    } else {
                         setOptionsCustomObject((prev) => [...prev]);
                         toast.error('Get custom object failed!');
                    }
               });
          }
     }, [activeStep]); //eslint-disable-line

     const onSubmit = (values) => {
          const objects = values.checkboxes.filter((item) => item.checked).map((i) => i.name);
          const customObjects = values.checkboxes.filter((item) => item.type === 'custom').map((i) => i.name);

          if (objects.length === 0) {
               toast.error('The object must have at least one item');
               return;
          }
          const newValues = {
               data: { ...stepsData, objects, customObjects },
          };

          dispatch(setShowHistoricalPopup(true));
          if (idEdit) {
               dispatch(setDataHistoricalSalesforce({ ...newValues, type: 'edit' }));
          } else {
               dispatch(setDataHistoricalSalesforce({ ...newValues, type: 'create' }));
          }
     };

     const handleRemoveCustomObject = (index, setFieldValue, values) => {
          setOptionsCustomObject((prev) => [...prev, { value: values.checkboxes[index].name, label: values.checkboxes[index].name }]);
          const updatedCheckboxes = [...values.checkboxes];
          updatedCheckboxes.splice(index, 1);
          setFieldValue('checkboxes', updatedCheckboxes);
     };

     const handleChangeOption = (option, setFieldValue, values) => {
          setOptionsCustomObject((prev) => prev.filter((i) => i.value !== option.value));
          const updatedCheckboxes = [...values.checkboxes];
          updatedCheckboxes.push({ name: option.label, checked: true, type: 'custom' });
          setFieldValue('checkboxes', updatedCheckboxes);
     };

     const handleSaveWithoutHistorical = (values) => {
          const objects = values.checkboxes.filter((item) => item.checked).map((i) => i.name);
          if (objects.length === 0) {
               toast.error('The object must have at least one item');
               return;
          }
          const customObjects = values.checkboxes.filter((item) => item.type === 'custom').map((i) => i.name);

          const hasNewObject = () => {
               const syncedNames = customObjectSynced.map((obj) => obj.name);
               const newObjects = customObjects.filter((obj) => !syncedNames.includes(obj));
               return newObjects.length > 0 && syncedNames.every((name) => customObjects.includes(name));
          };

          const newValues = {
               isPauseHistoricalSync: true,
               isPauseDailySync: false,
               objects,
               customObjects,
               status: stepsData.status === SF_CONNECTION_STATUS.DRAFT ? SF_CONNECTION_STATUS.ENABLED : stepsData.status,
          };

          setIsLoadingWithOut(true);
          callSalesforceApi(`${API_SALESFORCE_CONNECTION}/${stepsData.id}`, 'PUT', newValues).then((response) => {
               setIsLoadingWithOut(false);
               if (response && response.status === 200) {
                    dispatch(setFlexibleModal({ show: false, component: '' }));
                    dispatch(setRuleHaveEditting({ show: false, type: '', idConnection: 0 }));
                    dispatch(fetchSalesforceRulesRequest(accountId, false, 1, salesForeRulesPaging.itemsPerPage));
                    const addNewObject = hasNewObject();
                    if (addNewObject) {
                         toast.success('Successfully added Salesforce object');
                    } else {
                         toast.success('Update Connection Successfully!');
                    }
               } else {
                    toast.error('Update Connection Fail!');
               }
          });
     };

     const checkStepPassed = () => {
          let passed = true;
          const { status, maxDailyAPICall, objects } = stepsData;

          if (status === SF_CONNECTION_STATUS.DRAFT || !maxDailyAPICall || (objects && objects.length === 0)) {
               passed = false;
          }

          return passed;
     };

     const handleResyncData = () => {
          callSalesforceApi(`${API_SALESFORCE_CONNECTION}/resync-data/${stepsData.id}`, 'GET').then((response) => {
               if (response && response.status === 200) {
                    toast.success('Resync Data Success.');
                    dispatch(setFlexibleModal({ show: false, component: '' }));
                    dispatch(setRuleHaveEditting({ show: false, type: '', idConnection: 0 }));
               } else {
                    toast.error(response.data.message);
               }
          });
     };

     const handleGetNewField = (values) => {
          const listCheckboxCustom = values.filter((item) => item.type === 'custom').map((item) => item.name);
          setIsLoadingGetNew(true);
          callSalesforceApi(`${API_SALESFORCE_GET_CUSTOM_OBJECT}?connectionId=${stepsData.id}&getNew=true`, 'GET')
               .then((response) => {
                    if (response && response.status === 200) {
                         const listCustomObject = response.data.data
                              .filter((i) => !listCheckboxCustom.includes(i.name))
                              .map((item) => ({ value: item.name, label: item.name }));
                         setOptionsCustomObject(listCustomObject);
                    } else {
                         toast.error('Get custom object failed!');
                    }
               })
               .finally(() => {
                    setIsLoadingGetNew(false);
               });
     };

     const getRelatedObjects = (objects = []) => {
          const relatedObjectData = {
               Account: 'AccountContactRelation',
               Opportunity: 'OpportunityContactRole',
               Contract: 'ContractContactRole',
               Campaign: 'CampaignMember',
               Event: 'EventRelation',
          };

          return objects.map((obj) => relatedObjectData[obj]).filter(Boolean);
     };

     let jobLength = 0;

     if (Array.isArray(objects) && objects.length > 0) {
          const relatedObjects = getRelatedObjects(objects);
          const allObjects = new Set([...objects, ...relatedObjects]);

          jobLength = allObjects.size;
     }

     const jobConnection = SFjobs ? SFjobs.filter((item) => item.connectionId === stepsData.id) : [];
     const hasSyncData = stepsData && stepsData.jobs && stepsData.jobs.length > 0;
     const hasFininedData = jobConnection.some((_job) => _job.status !== SF_JOB_STATUS.FINISHED);
     const syncDataDisabled =
          SFjobs.filter((item) => item.connectionId === stepsData.id).some((_job) => _job.status !== SF_JOB_STATUS.FINISHED) ||
          (hasSyncData && (hasFininedData || jobConnection.length !== jobLength));
     const resyncDataDisabled =
          hasSyncData &&
          (jobConnection.some((_job) => _job.status !== SF_JOB_STATUS.WAITING) || stepsData.numberOfRequest + 5 >= stepsData.maxDailyAPICall);
     const showResyncBtn =
          SFjobs.some((_job) => _job.status === SF_JOB_STATUS.HOLD) ||
          (stepsData && stepsData.jobs && stepsData.jobs.some((_job) => _job.status === SF_JOB_STATUS.HOLD));

     return (
          <CCard className={activeStep === 4 ? 'show' : 'hide'}>
               <CCardHeader>
                    {activeStep !== 4 ? (
                         <div className="rule-step d-inline-flex justify-content-between w-100">
                              <h5 className="mb-0 inactive">Step 4: Objects to Import</h5>
                              {checkStepPassed() && (
                                   <div className="d-flex">
                                        <div className="d-inline-flex align-items-center">
                                             <CButton className="btn-edit" onClick={() => setActiveStep(4)}>
                                                  Edit
                                             </CButton>
                                        </div>
                                   </div>
                              )}
                         </div>
                    ) : (
                         <h5 className="mb-0">Step 4: Objects to Import</h5>
                    )}
               </CCardHeader>
               {activeStep === 4 && (
                    <CCardBody>
                         <Formik initialValues={{ checkboxes: initialValues }} onSubmit={onSubmit} validateOnBlur={false}>
                              {({ handleSubmit, values, handleChange, setFieldValue }) => {
                                   if (idEdit) {
                                        if (
                                             values.checkboxes
                                                  .filter((i) => i.checked)
                                                  .map((i) => i.name)
                                                  .sort()
                                                  .join(',') ===
                                                  initStepsData.objects
                                                       .filter((i) => !listObjectAutoImport.includes(i))
                                                       .sort()
                                                       .join(',') ||
                                             initStepsData.objects.length === 0
                                        ) {
                                             dispatch(setRuleHaveEditting({ show: false, type: COMPONENT_NAME.SALESFORCE_CONNECTED_ACCOUNTS }));
                                        } else {
                                             dispatch(setRuleHaveEditting({ show: true, type: COMPONENT_NAME.SALESFORCE_CONNECTED_ACCOUNTS }));
                                        }
                                   }

                                   return (
                                        <CForm onSubmit={handleSubmit} noValidate>
                                             <CRow style={{ margin: 0 }}>
                                                  <p>
                                                       Select the objects you'd like to import from this Salesforce connection. You can always return
                                                       here to include additional objects in the future. We've pre-selected default objects that we
                                                       require, and you can search for Custom Objects you'd like to include.
                                                  </p>
                                             </CRow>

                                             <div style={{ marginLeft: '50px' }}>
                                                  {values &&
                                                       values.checkboxes.map((item, index) => {
                                                            if (!item.name) return null;

                                                            let jobStatus =
                                                                 stepsData.jobs && stepsData.jobs.reverse().find((_job) => _job.object === item.name);

                                                            if (jobConnection && jobConnection.find((_job) => _job.object === item.name)) {
                                                                 jobStatus = jobConnection && jobConnection.find((_job) => _job.object === item.name);
                                                            }

                                                            return (
                                                                 <div key={index} className="mb-2 d-flex align-items-center" style={{ gap: '10px' }}>
                                                                      <div className="d-flex align-items-center">
                                                                           <CFormGroup variant="custom-checkbox" inline>
                                                                                <CInputCheckbox
                                                                                     custom
                                                                                     id={`checkboxes[${index}].checked`}
                                                                                     name={`checkboxes[${index}].checked`}
                                                                                     //  disabled={values.enableServerSide}
                                                                                     disabled={syncDataDisabled}
                                                                                     checked={item.checked}
                                                                                     onChange={handleChange}
                                                                                />
                                                                                <CLabel
                                                                                     variant="custom-checkbox"
                                                                                     htmlFor={`checkboxes[${index}].checked`}
                                                                                     style={{
                                                                                          paddingLeft: '6px',
                                                                                          marginBottom: '0',
                                                                                          lineHeight: '17px',
                                                                                     }}>
                                                                                     <span style={{ color: 'black', fontSize: '16px' }}>
                                                                                          {item.name}{' '}
                                                                                          {jobStatus && jobStatus.status !== SF_JOB_STATUS.FINISHED
                                                                                               ? `(${jobStatus.status})`
                                                                                               : ``}
                                                                                     </span>
                                                                                </CLabel>
                                                                           </CFormGroup>
                                                                      </div>
                                                                      <span>
                                                                           {item.type === 'custom' &&
                                                                                !customObjectSynced.find(
                                                                                     (customObject) =>
                                                                                          customObject.object === item.name &&
                                                                                          customObject.lastSyncDataAt
                                                                                ) && <span>new!</span>}
                                                                      </span>
                                                                      <div>
                                                                           {item.type === 'custom' && (
                                                                                <CButton
                                                                                     style={{
                                                                                          marginLeft: '30px',
                                                                                          display: 'flex',
                                                                                          alignItems: 'center',
                                                                                          gap: '10px',
                                                                                          padding: '0',
                                                                                     }}
                                                                                     onClick={() =>
                                                                                          handleRemoveCustomObject(index, setFieldValue, values)
                                                                                     }>
                                                                                     <CIcon icon="iconDeleteCircle" style={{ margin: '0' }} />{' '}
                                                                                     <span style={{ textTransform: 'none', lineHeight: '1' }}>
                                                                                          remove custom object
                                                                                     </span>
                                                                                </CButton>
                                                                           )}
                                                                      </div>
                                                                 </div>
                                                            );
                                                       })}
                                                  <CRow className="mb-4 mt-4">
                                                       <CCol lg="3">
                                                            <Select
                                                                 classNamePrefix="react-select"
                                                                 placeholder={'Search for custom objects by name'}
                                                                 options={optionsCustomObject}
                                                                 value={null}
                                                                 onChange={(option) => handleChangeOption(option, setFieldValue, values)}
                                                                 maxMenuHeight={200}
                                                                 isDisabled={isLoadingGetNew}
                                                            />
                                                       </CCol>
                                                  </CRow>
                                                  {/* <CRow>
                                                       <CCol>
                                                            <CButton className="btn-add-row mb-3">
                                                                 <CIcon name="iconAddField" className="icon-add" />
                                                                 <CLabel className="add-row">Add custom object</CLabel>
                                                            </CButton>
                                                       </CCol>
                                                  </CRow> */}
                                                  {isLoadingGetNew ? (
                                                       <div className="mb-2">
                                                            <i className="fas fa-sync-alt"></i> We are are looking for new object. Please be patient.
                                                       </div>
                                                  ) : (
                                                       <div className="mb-2">
                                                            <CButton
                                                                 type="button"
                                                                 className=""
                                                                 style={{ fontSize: '13px' }}
                                                                 color="link"
                                                                 onClick={() => handleGetNewField(values.checkboxes)}>
                                                                 Refresh Objects
                                                            </CButton>
                                                       </div>
                                                  )}

                                                  <div className="d-flex align-items-center">
                                                       {stepsData.status !== SF_CONNECTION_STATUS.PAUSED && !showResyncBtn && (
                                                            <CButton
                                                                 type="submit"
                                                                 className="px-4 mr-4 btn-next-step"
                                                                 color="primary"
                                                                 disabled={
                                                                      isLoadingWidthOut ||
                                                                      stepsData.status === SF_CONNECTION_STATUS.CLOSED ||
                                                                      syncDataDisabled
                                                                 }>
                                                                 SAVE & SYNC
                                                            </CButton>
                                                       )}

                                                       {stepsData.status !== SF_CONNECTION_STATUS.PAUSED && showResyncBtn && (
                                                            <CButton
                                                                 type="button"
                                                                 className="px-4 mr-4 btn-next-step"
                                                                 color="primary"
                                                                 disabled={resyncDataDisabled}
                                                                 onClick={() => handleResyncData()}>
                                                                 RE-SYNC
                                                            </CButton>
                                                       )}

                                                       <CButton
                                                            className={stepsData.status !== SF_CONNECTION_STATUS.PAUSED ? 'mx-4' : ''}
                                                            color="link"
                                                            style={{ fontSize: '13px' }}
                                                            onClick={() => handleSaveWithoutHistorical(values)}
                                                            disabled={isLoadingWidthOut}>
                                                            {isLoadingWidthOut ? (
                                                                 <span className="dots-waiting">Save without running historical sync</span>
                                                            ) : (
                                                                 'Save without running historical sync'
                                                            )}
                                                       </CButton>
                                                  </div>
                                             </div>
                                        </CForm>
                                   );
                              }}
                         </Formik>
                    </CCardBody>
               )}
               {/* <ConfirmSaveChange
                    show={saveModal}
                    onClose={toggleSaveModal}
                    isLoading={saveLoading}
                    onAccept={handleSave}
                    title={id ? 'Are You Sure You Want to Save This Change?' : 'Are you sure you want to save?'}
               >
                    <p>
                         {id
                              ? 'You are about to update this Salesforce Connection. This will not effect any other Salesforce Connection settings.'
                              : 'You are about to save this new Salesforce Connection. After doing this, publish your changes to see your Salesforce Connection working in your data layer.'}
                    </p>
               </ConfirmSaveChange> */}
          </CCard>
     );
};

export default ObjectsToImportSalesforce;
