import React from 'react';
import CenterSpinner from '../../../../general/Loadings/CenterSpinner';

const CreatePopulateRule = React.lazy(() => import('./CreatePopulateRule'));
const EditPopulateRule = React.lazy(() => import('./EditPopulateRule'));

const PopulateField = ({ id }) => {
     return (
          <div className='rule-detail'>
               {/* <React.Suspense fallback={<CenterSpinner />}>{id ? <EditPopulateField id={id} /> : <CreateNewRule />}</React.Suspense> */}
               <React.Suspense fallback={<CenterSpinner />}>{id ? <EditPopulateRule id={id} /> : <CreatePopulateRule />}</React.Suspense>
          </div>
     );
};

export default PopulateField;
